/** @jsx jsx */

import { useContext } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";
import { firestore } from "../../firebase.js";

import UserThumbnail from "../elements/UserThumbnail";

/* styles */
import { setPadding, setMargin } from "../common/spacing";
import { H3, H4, CAPTION, ICON, PARAGRAPH } from "../common/typography";
import { Container } from "../common/layouts";
import UrbidWidget from "../urbids/UrbidWidget";
import LayoutSingle from "../modules/LayoutSingleProposal";
import { LanguageContext } from "../../providers/LanguageProvider";

import Vote from "../elements/Button-Vote";

/* utilities */
import { getImageUrl } from "../../utilities.js";

const Proposal = ({
  id,
  title,
  description,
  urbid,
  urbidId,
  urbidSlug,
  imageUrls,
  user,
  countVotes,
}) => {
  const docRef = id && firestore.collection("proposals").doc(id);
  const urbidImageUrl = urbid
    ? getImageUrl(urbid.imageUrls[0], "urbids", urbidId, "sm")
    : "";
  const imageUrl = imageUrls
    ? getImageUrl(imageUrls[0], "proposals", id, "sm")
    : "";
  const { language } = useContext(LanguageContext);

  return (
    <div>
      <LayoutSingle>
        <LayoutSingle.Header
          color={"#CDCDCD"}
          backToUrl={`/active/${urbidSlug}`}
          backToText={`към всички предложения и детайли`}
          isRealization={false}
          urbidImageUrl={urbidImageUrl}
          title={title[language]}
          urbidTitle={urbid.title[language]}
          topLeft={
            <div
              css={css`
                ${setMargin(["bottom"], "md")}
              `}
            >
              <CAPTION>Author: </CAPTION>
              <UserThumbnail {...user} />
            </div>
          }
          bottomLeft={<Vote docRef={docRef} voteCount={countVotes} />}
          right={
            <div>
              <div
                css={css`
                  height: 100%;
                  background-image: url(${imageUrl});
                  background-size: cover;
                  background-position: center center;
                  min-height: 400px;
                `}
              ></div>
            </div>
          }
        />
        <LayoutSingle.Content description={description[language]} />
        <LayoutSingle.Footer
          imageUrls={imageUrls}
          postType={"proposals"}
          postId={id}
        />
      </LayoutSingle>
    </div>
  );
};
export default Proposal;
