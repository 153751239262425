import React, { Component, createContext } from "react";
import { firestore } from "../firebase.js";
import { collectIdsAndDocs } from "../utilities.js";
import orderBy from "lodash/orderBy";

export const RealizationsContext = createContext();

class RealizationsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { realizations: [] };
  }

  unsubscribeFromFirestore = null;

  componentDidMount = () => {
    this.unsubscribeFromFirestore = firestore
      .collection("proposals")
      .where("status", "==", "completed")
      .onSnapshot((snapshot) => {
        let realizations = snapshot.docs.map(collectIdsAndDocs);
        realizations = orderBy(realizations, "createdAt", "desc");
        this.setState({ realizations });
      });
  };

  componentWillUnmount = () => {
    this.unsubscribeFromFirestore();
  };

  render() {
    const { realizations } = this.state;
    const { children } = this.props;
    return (
      <RealizationsContext.Provider value={realizations}>
        {children}
      </RealizationsContext.Provider>
    );
  }
}

export default RealizationsProvider;
