/** @jsx jsx */
import React from 'react';
import { CAPTION } from '../common/typography';
import { jsx, css } from '@emotion/core';

const UserThumbnail = ({ photoUrl, displayName }) => {
  const imgStyle = {
    backgroundImage: 'url(' + (photoUrl) + ')'
  };
  
  return (
    <div
      css={css`
        display: flex;
        align-items: center; `}>
      <span 
        css={css`
          height: 15px;
          width: 15px;
          border-radius: 50%;
          ${imgStyle};
          background-size: cover;
          background-position: center center;
          margin-right: 5px;
          display: none;
        `}></span>
      <CAPTION
        css={css`
          margin-top: 2px;
        `}>{ displayName }</CAPTION>
    </div>
  )
}

export default UserThumbnail;
