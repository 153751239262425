import approvedNomination from './email_templates/approvedNomination';
import { getImageUrl } from './utilities';
import { firestore } from './firebase';

const api_key = '8db61c1b3fdf23e649a1e68a0576579e-7fba8a4e-2684db34';
const domain = 'mailer.urbid.org';
const mailgun = require('mailgun-js')({apiKey: api_key, domain: domain, host: 'api.eu.mailgun.net' });

export const sendEmail = ({ id, docData }) => {
  const { imageUrls, user, title, address, slug } = docData;
  firestore.collection('users').doc(user.uid).get()
    .then(doc => {
      let _user = doc.data();
      const data = {
        from: 'URBID <admin@mailer.urbid.org>',
        to: `${_user.email}`,
        subject: 'URBID: Твоята номинация беше одобрена!',
        html: approvedNomination({ 
          displayName: _user.displayName,
          imageUrl: getImageUrl(imageUrls[0], 'nominations', id, 'md'),
          url: 'https://urbid.org/nominations/' + slug,
          title: title,
          address: address.formattedAddress
        })
      };
      mailgun.messages().send(data, (error, body) => {
        console.log(error, body);
      });
    });
};
