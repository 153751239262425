/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setPadding, setMargin } from '../common/spacing';
import forEach from 'lodash/forEach';
import { getImageUrl } from '../../utilities.js';
import { NextArrow, PrevArrow } from './_Arrows';

export const defaultSettings = {
  dots: true,
  centerMode: true,
  variableWidth: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
}

const ImageSlider = ({ imageUrls, postType, postId }) => {

  let urls = [];
  
  forEach(imageUrls, imageUrl => {
    urls.push(getImageUrl(imageUrl, postType, postId, 'lg'));
  });

  return (
    <Slider {...defaultSettings}
      css={css`
        .slick-dots {
          bottom: 12px;
        }
      `}>
      { urls && urls.map((imageUrl, index) =>(

        <div 
          key = {`image--${index}`} 
          css={css`
            display: flex!important;
            align-items: center;
            justify-content: center;
            /* background-color: #ebebeb;     */
            height: 30vh;
            ${setMargin('all', 'sm')}

            @media (min-width: 768px) {
              height: 50vh;
            }
          `}>
          <img 
            src={imageUrl} 
            css={css`
              object-fit: contain;
              max-width: 100%;
              max-height: 100%;
            `}/>
        </div>
      ))}
    </Slider>
  )
}

export default ImageSlider;
