

/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { setPadding, setMargin } from '../common/spacing';
import Shape_2 from './shape_2';
import Shape_1 from './shape_1';

const Shape = ({ shape }) => {
  let ShapeEl = Shape_1;

  if (shape === 'shape_1') { ShapeEl = Shape_1; }
  if (shape === 'shape_2') { ShapeEl = Shape_2; }
  return (
    <ShapeEl />
  );
};

export default Shape;
