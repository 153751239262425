/** @jsx jsx */
import { Component } from "react";
import { jsx, css } from "@emotion/core";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import InfoWindow from "../elements/InfoWindow";
import withLanguage from "../withLanguage";
import { auth as authTexts } from "../../language/content";

const _SignInOrSignUp = ({ language, onLogInOrLogOut }) => {
  console.log("on", onLogInOrLogOut);
  return (
    <div
      css={css`
      color: white;

      .react-tabs__tab-list {
        border-bottom: none;
      }

      li.react-tabs__tab {
        opacity: 0.5;
        border: none;
        border-bottom: none;
        color: white!important;
      }

      li.react-tabs__tab.react-tabs__tab--selected {
        background-color: transparent;
        border-bottom: 2px solid white!important;
        opacity: 1;
        color: white;
      }

      li.react-tabs__tab.react-tabs__tab--selected
    `}
    >
      <Tabs>
        <TabList>
          <Tab>{authTexts.signin[language]}</Tab>
          <Tab>{authTexts.signup[language]}</Tab>
        </TabList>
        <TabPanel>
          <SignIn onSignIn={onLogInOrLogOut} />
        </TabPanel>
        <TabPanel>
          <SignUp onSignUp={onLogInOrLogOut} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export const SignInOrSignUp = withLanguage(_SignInOrSignUp);

class SignInAndSignUp extends Component {
  state = {
    isOpen: false,
  };

  _toggleOverlay = (trueOrFalse) => {
    this.setState({ isOpen: trueOrFalse });
  };
  toggleOverlay = this._toggleOverlay.bind(this);

  render() {
    const { isOpen } = this.state;
    const { language } = this.props;
    return (
      <div>
        <button
          onClick={() => {
            this.toggleOverlay(true);
          }}
          css={css`
            display: flex;
            align-items: center;
            margin-left: 20px;
            font-size: 16px;
            font-family: "Intro Regular Alt";
          `}
        >
          <span
            css={css`
              margin-right: 5px;
            `}
          >
            {authTexts.signin[language]}
          </span>
          <MdArrowForward fontSize="21px" />
        </button>
        <InfoWindow
          color="nominations"
          isOpen={isOpen}
          onCloseClick={() => {
            this.toggleOverlay(false);
          }}
        >
          <SignInOrSignUp
            onLogInOrLogOut={() => {
              this.toggleOverlay(false);
            }}
          />
        </InfoWindow>
      </div>
    );
  }
}

export default withLanguage(SignInAndSignUp);
