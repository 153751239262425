/** @jsx jsx */
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import { setPadding, setMargin } from "../common/spacing";
import { H3, H4, H5, PARAGRAPH, CAPTION, ICON } from "../common/typography";
import { Container } from "../common/layouts";
import { ViewMoreLink } from "./Links";
import withLanguage from "../withLanguage";

export const Votes = ({ countVotes, language }) => {
  const votes = language === "bg" ? "гласа" : "votes";
  return (
    <H4
      css={css`
        margin: 0px !important;
      `}
    >
      {countVotes} {votes}
    </H4>
  );
};
export const __Votes = withLanguage(Votes);

export const Proposals = ({ countProposals, language }) => {
  const proposals =
    language === "bg"
      ? countProposals !== 1
        ? "предложения"
        : "предложение"
      : countProposals !== 1
      ? "proposals"
      : "proposal";
  return (
    <H4>
      {countProposals} {proposals}
    </H4>
  );
};
export const __Proposals = withLanguage(Proposals);

export const __Comments = ({ countComments }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <ICON icon="comments" />
    <CAPTION
      css={css`
        margin-left: 5px;
      `}
    >
      {countComments}
    </CAPTION>
  </div>
);

export const __Location = ({ formattedAddress }) => (
  <CAPTION>{formattedAddress}</CAPTION>
);

export const __Details = ({ details }) => (
  <div
    css={css`
      display: flex;
      ${setMargin(["top"], "sm")}
    `}
  >
    {details &&
      details.map((detail, i) => (
        <div
          key={`detail--${i}`}
          css={css`
            flex-basis: 0;
            flex-grow: 1;
          `}
        >
          <CAPTION
            css={css`
              ${setMargin(["bottom"], "xs")}
            `}
          >
            {detail.label}
          </CAPTION>
          <CAPTION>{detail.text}</CAPTION>
        </div>
      ))}
  </div>
);

export const __Excerpt = ({ text }) => <PARAGRAPH>{text}</PARAGRAPH>;

export const CardSectionAll = ({ title, text, color, children }) => (
  <Container>
    <div
      css={css`
        width: 100%;
        margin: 0px;

        @media (min-width: 768px) {
          margin: 0px -15px;
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          @media (min-width: 768px) {
            flex-direction: row;
          }
        `}
      >
        {children}
      </div>
    </div>
  </Container>
);

export const Section = ({ children }) => (
  <section
    css={css`
      ${setPadding(["top", "bottom"], "lg")}
    `}
  >
    {children}
  </section>
);

export const CardContainer = styled("div")`
  display: flex;
  margin: 0px -15px;

  @media (max-width: 767px) {
    margin: 0px;
  }
`;

export const CardSection = ({ viewMoreUrl, color, children }) => (
  <Container>
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (min-width: 768px) {
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-end;
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          @media (min-width: 768px) {
            flex-direction: row;
            margin: 0px -12px;
          }
        `}
      >
        {children}
      </div>
      <ViewMoreLink to={viewMoreUrl} color={color} />
    </div>
  </Container>
);

const cWidths = {
  sm: 210,
  md: 300,
};
const cHeights = {
  sm: 150,
  md: 180,
};
const cW = window.innerWidth >= 768 ? cWidths.md : cWidths.sm;
const cH = window.innerWidth >= 768 ? cHeights.md : cHeights.sm;

export const CardImage = ({ imageUrl, children }) => {
  return (
    <div
      css={css`
        background-image: url(${imageUrl});
        background-size: cover;
        background-position: center center;
        width: ${cW}px;
        height: ${cH}px;
        margin: 0px 5px;
        display: flex;
        align-items: flex-end;
      `}
    >
      {children}
    </div>
  );
};

const Card = ({ imageUrl, url, children }) => {
  return (
    <Link
      to={url}
      css={css`
        width: 100%;
        ${setMargin(["bottom"], "md")}
        transition: 0.15s all;

        @media (min-width: 768px) {
          width: 100%;
          min-width: 33.33%;
          max-width: 33.33%;
          ${setPadding(["left", "right"], "sm")}
          ${setMargin(["bottom"], "md")}
        }

        svg {
          margin-left: 0px;
          transition: 0.15s all;
        }

        &:hover {
          cursor: pointer;
          svg {
            margin-left: 10px;
          }
          .workaround {
            background-color: #adadad;
          }
        }
      `}
    >
      <div
        className="workaround"
        css={css`
          background-color: #cdcdcd;
          width: 100%;
          transition: 0.15s all;
        `}
      >
        <div
          css={css`
            display: block;
            background-image: url(${imageUrl});
            background-size: cover;
            background-position: center center;
            height: 240px;
          `}
        />
        <div
          css={css`
            ${setPadding(["left", "right"], "sm")}
            ${setPadding(["top"], "md")}
            ${setPadding(["bottom"], "lg")}

            position: relative;
          `}
        >
          <div>{children}</div>
          <div
            css={css`
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              display: flex;
              align-items: center;
              height: 50px;
              ${setPadding(["left", "right"], "sm")}
            `}
          >
            <ICON icon="forward" size={30} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
