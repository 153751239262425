/** @jsx jsx */

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import MapPicker from "react-google-map-picker";
import NodeGeocoder from "node-geocoder";
import { addNomination } from "../../language/content";
import { FormGroup, Form } from "./Form";
import { PARAGRAPH, CAPTION } from "../common/typography";

// Set specific http request headers:
const nodeFetch = require("node-fetch");

// init defaults
const DefaultPosition = { lat: 42.69, lng: 23.32 };
const DefaultZoom = 14;

var options = {
  provider: "google",
  apiKey: "AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0", // for Mapquest, OpenCage, Google Premier
  fetch: function fetch(url) {
    console.log("fetch", url);
    return nodeFetch(url, {});
  },
  language: "bg",
  region: "BG",
  formatter: null,
};

var geocoder = NodeGeocoder(options);

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: this.props.getStore().latitude,
      longitude: this.props.getStore().longitude,
      address:
        "<span style='opacity: 0.75'>Избери местоположение на картата<span>",
      zoom: DefaultZoom,
      canSubmit: "remains to be seen :)",
    };

    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.handleChangeZoom = this.handleChangeZoom.bind(this);
  }

  _validateOnDemand = true;

  isValidated() {
    if (this.state.canSubmit === true) {
      this.props.updateStore({
        ...this.state,
        savedToCloud: false, // use this to notify step4 that some changes took place and prompt the user to save again
      });
      return true;
    } else {
      this.setState({ canSubmit: false });
      return false;
    }
  }

  handleChangeLocation = (lat, lng) => {
    // this.setState({ latitude: lat, longitude: lng });

    let newState = {
      latitude: lat,
      longitude: lng,
      address: "",
      neighborhood: "",
      city: "",
      canSubmit: true,
    };
    geocoder
      .reverse({ lat: lat, lon: lng })
      .then((res) => {
        newState.neighborhood = res[0].extra.neighborhood;
        newState.address = res[0].formattedAddress;
        newState.city = res[0].city;
        this.setState({ ...newState });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeZoom = (zoom) => {
    this.setState({ zoom });
  };

  render() {
    const { language } = this.props;
    const { latitude, longitude, address, zoom } = this.state;
    const hasError = this.state.canSubmit === false;

    return (
      <Form>
        <FormGroup
          label={addNomination.step2.stepName[language]}
          hasError={hasError}
          errorMessage={addNomination.step2.location.error[language]}
        >
          <PARAGRAPH
            css={css`
              margin-bottom: 30px;
            `}
            dangerouslySetInnerHTML={{
              __html: `Адрес:<br>${address}`,
            }}
          ></PARAGRAPH>
          <div>
            <div>
              <MapPicker
                defaultLocation={DefaultPosition}
                zoom={zoom}
                style={{ height: "300px" }}
                onChangeLocation={this.handleChangeLocation}
                onChangeZoom={this.handleChangeZoom}
                apiKey="AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0"
              />
            </div>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

export default Step2;
