/** @jsx jsx */
import React, { useContext } from "react";
import { jsx, css } from "@emotion/core";
import Button from "../elements/Button";
import Banner, { BannerSmall } from "../elements/Banner";
import { H3, H4, H5 } from "../common/typography";
import { setPadding, setMargin } from "../common/spacing";
import { banners } from "../../language/content";
import { LanguageContext } from "../../providers/LanguageProvider";
import AddNominationPrompt from "../modules/AddNominationPrompt";

export const BannerDownloadApp = () => {
  const { language } = useContext(LanguageContext);
  return (
    <BannerSmall>
      <H4>{banners.app.heading[language]}</H4>
      <div
        css={css`
          display: flex;
          align-items: center;
          ${setPadding(["top"], "md")}
          a {
            margin-right: 30px;
          }
        `}
      >
        <a
          href="https://play.google.com/store/apps/details?id=org.urbid.app"
          target="_blank"
          css={css`
            border: 2px solid black;
            ${setPadding(["left", "right"], "md")}
            height: 50px;
            font-size: 18px;
            line-height: 1em;
            display: flex;
            align-items: center;

            @media (min-width: 768px) {
              font-size: 22px;
              ${setPadding(["left", "right"], "md")}
            }
          `}
        >
          Android
        </a>
        <a
          href="https://apps.apple.com/at/app/urbid/id1500630413?l=en"
          target="_blank"
          css={css`
            border: 2px solid black;
            ${setPadding(["left", "right"], "md")}
            height: 50px;
            font-size: 18px;
            line-height: 1em;
            display: flex;
            align-items: center;

            @media (min-width: 768px) {
              font-size: 22px;
              ${setPadding(["left", "right"], "md")}
            }
          `}
        >
          iOS
        </a>
      </div>
    </BannerSmall>
  );
};

const BannerNominations = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div>
      <Banner theme="dark" accent="nominations">
        <H3
          css={css`
            ${setMargin(["bottom"], "sm")}
          `}
        >
          {banners.nominations.heading[language]}
        </H3>
        <H4>{banners.nominations.paragraph[language]}</H4>
        <AddNominationPrompt />
      </Banner>
      <BannerDownloadApp />
    </div>
  );
};

export default BannerNominations;
