
/** @jsx jsx */
import React, { Component, createRef } from 'react';
import { jsx, css } from '@emotion/core';
import { Redirect } from 'react-router-dom';
import { firestore, storage } from '../../firebase.js';
import FileUploader from "react-firebase-file-uploader";
import { includes, without, forEach, forOwn, cloneDeep, orderBy } from 'lodash';

import { H2, H3, H4, CAPTION } from '../common/typography';
import { Container, Row, SectionHeader, Section, Half, Full, Third } from '../common/layouts';
import { setMargin, setPadding } from '../common/spacing';

import { Input, Textarea, Form, FormGroup } from '../forms/Form';
import Button from '../elements/Button';
import { transliterate as tr, slugify } from 'transliteration';

class AddPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      heading: {
        bg: '',
        en: ''
      },
      featuredImage: null,
      paragraphs: [
        {
          text: {
            bg: '',
            en: ''
          },
          imageUrl: null
        }
      ],
      isCreatingPost: false
    };
    this.isPostCreated = false;
    this.onBlur = this.onBlur.bind(this);
    this.grabUserInput = this.grabUserInput.bind(this);
    this.addParagraph = this.addParagraph.bind(this);
    this.submit = this.submit.bind(this);
  };

  createPost = () => {
    firestore.collection('posts')
      .add({
        heading: {},
        featuredImage: '',
        paragraphs: [],
        status: 'draft'
      })
      .then((docRef) => {
        this.setState({ id: docRef.id });
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  };

  componentWillUnmount() {
    if (this.state.heading.bg.length <= 0) {
      this.deletePost();
    };
  };

  submit = () => {
    const input = this.grabUserInput();
    let state = cloneDeep(this.state);
    state.heading = input.heading;
    forEach(state.paragraphs, ((par, index) => {
      if (!par.text) {
        par.text = {};
      }
      par.text = input.paragraphs[index].text;
    }));
    this.setState(state, () => {
      firestore.collection('posts').doc(this.state.id).update({
        heading: this.state.heading,
        featuredImage: this.state.featuredImage,
        paragraphs: this.state.paragraphs,
        status: 'active',
        createdAt: new Date(),
        slug: slugify(this.state.heading.bg)
      });
    });
  };

  onBlur = () => {
    const input = this.grabUserInput();
    let state = cloneDeep(this.state);
    state.heading = input.heading;
    forEach(state.paragraphs, ((par, index) => {
      if (!par.text) {
        par.text = {};
      }
      par.text = input.paragraphs[index].text;
    }));
    this.setState(state);
  };

  addParagraph() {
    let paragraphs = cloneDeep(this.state.paragraphs);
    paragraphs.push({
      text: {
        bg: 'добави текст',
        en: 'add text'
      }
    });
    this.setState({ paragraphs });
  };

  grabUserInput() {
    let input = {
      heading: {},
      paragraphs: []
    };

    forOwn(this.refs, (refObj, key) => {
      // input[key] = refObj.value
      let lang;
      if (includes(key, 'heading')) {
        lang = key.slice(-2); 
        input.heading[lang] = refObj.value;
      } else if (includes(key, 'text')) {
        lang = key.slice(5, 7);
        let index = key.slice(9);
        if (!input.paragraphs[index]) {
          input.paragraphs[index] = {
            text: {}
          }
        }
        input.paragraphs[index].text[lang] = refObj.value;
      }
    });

    return input;
  };

  handleUploadSuccess = (filename, index) => {
    if (index === 'featured') {
      this.setState({ featuredImage: filename })
    } else {
      let paragraphs = cloneDeep(this.state.paragraphs);
      paragraphs[index].imageUrl = filename;
      this.setState({ paragraphs });
    }
  };

  render() {
    const { id, heading, paragraphs } = this.state;

    return (
      <Container>
        {(!id) && (
        <Button
          style='accent'
          color='nominations'
          onClick={() => {this.createPost()}}>
          add a post  
        </Button>)}
        {id && (
          <div>
            <Form
              css={css`
                background-color: transparent;
                padding: 0px!important;
                max-width: 800px;
              `}>
                <Row>
                  <Half>
                    <FormGroup
                      label = 'заглавие'>
                      <Input
                        ref="heading_bg"
                        autoComplete="off"
                        type="text"
                        required
                        defaultValue={heading.bg}
                        onBlur={this.onBlur}/>
                    </FormGroup>
                  </Half>
                  <Half>
                    <FormGroup
                      label = 'title'>
                      <Input
                        ref="heading_en"
                        autoComplete="off"
                        type="text"
                        required
                        defaultValue={heading.en}
                        onBlur={this.onBlur}/>
                    </FormGroup>
                  </Half>
                </Row>
                <Row>
                  <Full>
                    <FormGroup
                      label = 'featured image'>
                      <div
                        css={css`
                          display: block;
                          `}>
                        <FileUploader
                          accept="image/*"
                          name="image-uploader"
                          randomizeFilename
                          storageRef={storage.ref().child(`posts/${id}`)}
                          onUploadSuccess={(filename) => { this.handleUploadSuccess(filename, 'featured'); }} />
                      </div>
                    </FormGroup>
                  </Full>
                </Row>
              
                <div>
                { paragraphs && paragraphs.map((paragraph, index) => (
                  <div
                    key={`par--${index}`}>
                    <Row>
                      <Half>
                        <FormGroup
                          label = 'текст'>
                          <Textarea 
                            css={css`
                              min-height: 150px;
                            `}
                            ref={`text_bg--${index}`}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue={paragraph.text.bg}/>
                        </FormGroup>
                      </Half>
                      <Half>
                        <FormGroup
                          label = 'text'>
                          <Textarea 
                            css={css`
                              min-height: 150px;
                            `}
                            ref={`text_en--${index}`}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            defaultValue={paragraph.text.bg}/>
                        </FormGroup>
                      </Half>
                    </Row>
                    <Row>
                      <Full>
                        <FormGroup
                          label = 'image(s)'>
                          <label>image(s)</label>
                          { (!paragraphs.image) && (
                            <div
                              css={css`
                                display: block;
                              `}>
                              <FileUploader
                                accept="image/*"
                                name="image-uploader-multiple"
                                randomizeFilename
                                storageRef={storage.ref().child(`posts/${id}`)}
                                onUploadSuccess={(filename) => { this.handleUploadSuccess(filename, index); }}
                                multiple />
                            </div>
                          )}
                        </FormGroup>
                      </Full>
                    </Row>
                  </div>)
                )}
                <button onClick={(e) => { 
                  e.preventDefault(); 
                  this.addParagraph(); 
                  this.grabUserInput();
                }}>add paragraph</button>
              </div>
            </Form>
            <div
              css={css`
                ${setMargin(['top', 'bottom'], 'md')}
              `}>
              <Button 
                style='regular'
                color='nominations'
                onClick={this.submit}>
                submit to db
              </Button>
            </div>
          </div>
        )}
      </Container>
    )
  }
}

export default AddPost;