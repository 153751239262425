/** @jsx jsx */
import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import moment from 'moment';
import withUser from '../withUser';
import firebase from '../../firebase.js';
import { TextArea, Submit } from '../elements/Inputs';
import { commentsPage, prompts } from '../../language/content';
import withLanguage from '../withLanguage';

class AddComment extends Component {
  state = { text: '' };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { currentUser, docRef } = this.props;
    const comment = this.state;
    const { displayName, photoUrl, uid } = currentUser;
    const createdAt = new Date();

    docRef.collection('comments').add({
      ...comment,
      user: { displayName, photoUrl, uid },
      createdAt
    });

    const increment = firebase.firestore.FieldValue.increment(1);
    docRef.update({ countComments: increment });

    this.setState({ text: '' });
  };

  render() {
    const { currentUser, language } = this.props;
    const { text } = this.state;
    const imgStyle = {
      backgroundImage: 'url(' + ((currentUser && currentUser.photoUrl) || '') + ')'
    };

    return (
      <div className = "urb-add-comment">
        <span className = "urb-user-thumb__img urb-thumb__img" style = {imgStyle}></span>
        <form 
          onSubmit={this.handleSubmit}
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-end;
            flex-direction: column;

          `}>
          <TextArea
            name="text"
            placeholder={commentsPage.placeholder[language]}
            value={text}
            onChange={this.handleChange.bind(this)}
            />
          <Submit value={prompts.submit[language]}/>
        </form>
      </div>
    );
  }
}

export default withLanguage(withUser(AddComment));
