import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';

const configProduction = {
  apiKey: "AIzaSyDfty6qokBH2kugTzYAgUjUUWNPnW3Fvgg",
  authDomain: "urbid-app.firebaseapp.com",
  databaseURL: "https://urbid-app.firebaseio.com",
  projectId: "urbid-app",
  storageBucket: "urbid-app.appspot.com",
  messagingSenderId: "196677896659",
  appId: "1:196677896659:web:c374dd8d4ab798299c8726"
};
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/urbid-app.appspot.com/o/';

firebase.initializeApp(configProduction);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const fbprovider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = () => auth.signInWithPopup(fbprovider);

export const signOut = () => {
  auth.signOut();
}

export const createUserProfileDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const createdAt = new Date();
    const { displayName, email, photoURL } = user;
    try {
      await userRef.set({
        displayName: displayName,
        email: email,
        photoUrl: photoURL,
        createdAt,
        ...additionalData
      })
    } catch (error) {
      console.error('Error creating user! ', error.message);
    }
  }

  return getUserDocument(user.uid);
}

export const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    return firestore.collection('users').doc(uid);
  } catch (error) {
    console.error('Error fetching user!', error.message);
  }
}

window.firebase = firebase;
export default firebase;
