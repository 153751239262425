/** @jsx jsx */

import React  from 'react';
import { jsx, css } from '@emotion/core';
import { NominationCard } from './NominationCard';
import { Row, Container, Full } from '../common/layouts';

const NominationsList = ({ nominations }) => (
  <Container>
    <Row
      css={css`
        @media (max-width: 767px) {
          margin: 0px;
        }
      `}>
      {nominations.map((nomination, index) =>
      <NominationCard
        key={`home-nomination--${index}`}
        {...nomination}
      />)}
    </Row>
  </Container>
);

export default NominationsList;