export const accent = "#01cc66";
export const secondary = "#6889FF";
export const tertiary = "#FF8E75";
export const black = "#212121";
export const darkergrey = "#353535";
export const darkgrey = "#484b4c";
export const grey = "#888888";
export const midgrey = "#bcbcbc";
export const lightgrey = "#ebebeb";
export const lightestgrey = "#f7f7f7";

export const colorUrbids = "#6889FF";
export const colorNominations = "#00cc65";
export const colorRealizations = "#FF8E75";

export const colorUrbidsSecondary = "#b9c2e1";
export const colorNominationsSecondary = "#76d6a4";
export const colorRealizationsSecondary = "#f2ae9f";
