/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import moment from 'moment';
import 'moment/locale/bg';
import UserThumbnail from '../elements/UserThumbnail';
import { TITLE_MEDIUM, CAPTION, PARAGRAPH } from '../common/typography';
import { setPadding, setMargin } from '../common/spacing';

const Comment = ({ text, user, createdAt }) => {

  moment.locale('bg'); 
  // returns the new locale, in this case 'de'
  // <small className="urb-comment__date">{moment(createdAt.toDate()).format('LLLL')}</small>
  return (
    <article css={css`
        ${setPadding(['top', 'bottom'], 'md')}
        border-bottom: 1px solid #d5d5d5;
      `}>
      <div css={css`
        display: flex;
        justify-content: space-between;
        ${setMargin(['bottom'], 'sm')}
      `}>
        <UserThumbnail {...user} />
        <CAPTION color="light">{moment(createdAt.toDate()).fromNow()}</CAPTION>
      </div>
      <div css={css`
        padding-left: 30px;
      `}>
        <CAPTION>{text}</CAPTION>
      </div>
    </article>
  );
};

Comment.defaultProps = {
  title: 'An Incredibly Hot Take',
  content:
    'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ducimus est aut dolorem, dolor voluptatem assumenda possimus officia blanditiis iusto porro eaque non ab autem nihil! Alias repudiandae itaque quo provident.',
  user: {
    displayName: 'Bill Murray',
    email: 'billmurray@mailinator.com',
    photoURL: 'https://www.fillmurray.com/300/300',
  },
  createdAt: new Date()
};

export default Comment;
