export const common = {
  urbids: {
    bg: "активни",
    en: "active",
  },
  activeUrbids: {
    bg: "активни",
    en: "active",
  },
  completedUrbids: {
    bg: "приключили",
    en: "closed",
  },
  proposals: {
    bg: "предложения за намеси",
    en: "intervention proposals",
  },
  nominations: {
    bg: "номинации",
    en: "nominations",
  },
  activeNominations: {
    bg: "активни номинации",
    en: "active nominations",
  },
  pendingNominations: {
    bg: "номинации, чакащи одобрение",
    en: "pending nominations",
  },
  realizations: {
    bg: "завършени",
    en: "completed",
  },
  nominationsSubtext: {
    bg: `Градът ни е пълен обекти, които с малко усилия биха могли да се преобразят в нещо красиво и функционално.
        Номинирай ги и гласувай за тези, които считаш за най-приоритетни за промяна.
    `,
    en: "Vote for pending objects.",
  },
  realizationsSubtext: {
    bg: `Разгледай изпълнените проекти.
    `,
    en: "Vote for pending objects.",
  },
  urbidsSubtext: {
    bg: `Тук са номинациите с достатъчен брой гласове от публиката и одобрение от местните власти.<br>
      Включи се с предложение за трансформацията им! Граждани, дизайнери, архитекти, артисти, инженери - конкурсите са отворени за всеки с креативна визия и функционална идея.
    `,
    en: "Vote for pending objects.",
  },
  blog: {
    bg: "блог",
    en: "blog",
  },
};

export const nav = {
  backToHome: {
    bg: "обратно към начало",
    en: "back to home",
  },
  backToAll: {
    bg: "обратно към всички",
    en: "back to all",
  },
  about: {
    bg: "За Урбид",
    en: "About Urbid",
  },
  supportUs: {
    bg: "Подкрепи Urbid",
    en: "Support Urbid",
  },
  privacyPolicy: {
    bg: "Политика за поверителност",
    en: "Privacy policy",
  },
  team: {
    bg: "Екип",
    en: "Team",
  },
  contacts: {
    bg: "Контакти",
    en: "Contacts",
  },
  blog: {
    bg: "Блог",
    en: "Blog",
  },
  termsOfUse: {
    bg: "Потребителски условия",
    en: "Terms of use",
  },
  viewAll: {
    bg: "виж всички",
    en: "view all",
  },
  close: {
    bg: "затвори",
    en: "close",
  },
  readMore: {
    bg: "прочети",
    en: "read more",
  },
};

/* PROMPTS */
export const prompts = {
  addNomination: {
    bg: `добави номинация`,
    en: `add nomination`,
  },
  promptDownload: {
    bg: `Свали app`,
    en: `Download app`,
  },
  registerAsDesigner: {
    bg: `регистрирай се като дизайнер`,
    en: `register as a designer`,
    heading: {
      bg: `Имаш идеи за трансформации? Регистрирай се като дизайнер.`,
      en: `Full of ideas for transformations? Register as a designer.`,
    },
    paragraph: {
      bg: `Изпрати ни мейл на designers@urbid.org с кратка информация за себе си и ще добавим профила ти към списъка с дизайнери.`,
      en: `Send us an email to designers@urbid.org with a brief information about yourself, and we'll add your profile to the designers' list.`,
    },
  },
  submit: {
    bg: "изпрати",
    en: "submit",
  },
  voteNomination: {
    bg: "гласувай за промяна",
    en: "vote for a change",
  },
};

// AUTHENTICATION
export const auth = {
  signin: {
    bg: "вход",
    en: "sign in",
  },
  signup: {
    bg: "регистрация",
    en: "sign up",
  },
  signout: {
    bg: "Изход",
    en: "Sign out",
  },
  placeholders: {
    email: {
      bg: "email",
      en: "email",
    },
    displayName: {
      bg: "име",
      en: "display name",
    },
    password: {
      bg: "парола",
      en: "password",
    },
  },
  loginGoogle: {
    bg: "вход с google",
    en: "sign in with google",
  },
  loginFacebook: {
    bg: "вход с facebook",
    en: "sign in with facebook",
  },
  errors: {
    signIn: {
      wrongPassword: {
        bg: "Въведената парола е невалидна. Опитай отново?",
        en: "The password you entered is not valid. Try again?",
      },
      userNotFound: {
        bg: "Потребителят не е намерен. Регистрирай се?",
        en: "User not found. Register instead?",
      },
      invalidEmail: {
        bg: "Въведеният имейл адрес е невалиден. Опитай отново?",
        en: "The email you entered is invalid. Try again?",
      },
    },
    signUp: {
      weakPassword: {
        bg: "Въведената парола е твърде слаба - трябва да е поне 6 букви.",
        en: "The password is too weak - should be at least 6 characters.",
      },
      emailAlreadyInUse: {
        bg: "Потребителят с този имейл адрес вече съществува.",
        en: "This email is already in use.",
      },
      invalidEmail: {
        bg: "Въведеният имейл адрес е невалиден. Опитай отново?",
        en: "The email you entered is invalid. Try again?",
      },
    },
  },
};

/* NOMINATIONS PAGE */
export const nominationsPage = {
  tabs: {
    list: {
      bg: `Списък`,
      en: `List`,
    },
    map: {
      bg: `Карта`,
      en: `Map`,
    },
  },
};

/* COMMENTS */
export const commentsPage = {
  title: {
    bg: "коментари",
    en: "comments",
  },
  placeholder: {
    bg: "добави коментар",
    en: "add a comment",
  },
  submit: {
    bg: "изпрати",
    en: "submit",
  },
  register: {
    bg: "Моля, влез в профила си, за да оставиш коментар.",
    en: "Please log in or register to leave a comment.",
  },
};

export const howItWorks = {
  button: {
    en: "how it works",
    bg: "как работи",
  },
  nominations: {
    heading: {
      bg: `Често минаваш покрай порутена автобусна спирка? Или отдавна неработещ
      уличен телефон?`,
      en: `You often pass by a broken bus stop? Or a long dysfunctional phone booth?`,
    },
    paragraph: {
      bg: `Градът ни е пълен с места и обекти, които имат нужда от промяна и които с малко
      усилия могат да се превърнат в нещо хубаво.<br>
      Номинирай ги: изпрати кратко описание, местоположение и няколко снимки
      и гласувай, за да бъдат следващата намеса.`,
      en: `Our city is full of objects and places in need of change, which - with a little effort - 
      can turn into something beautiful.<br>
      Nominate them: send us a short description,
      location and a few pictures, and vote for them to become the next intervention.`,
    },
  },
  urbids: {
    heading: {
      bg: `В града ни има много места, които се нуждаят от промяна. От къде да започнем?`,
      en: `Our city has plenty of objects and places in need of a change. Where do we start?`,
    },
    paragraph: {
      bg: `Когато една номинация получи достатъчно гласове от публиката и одобрение от местните власти,
      се превръща в "Урбид": отворен конкурс за предложения от граждани, дизайнери, архитекти, артисти,
      инженери и всеки, който има идея в какво може да се превърне.`,
      en: `When a nomination receives enough votes from the public and is approved by the local authorities,
      it becomes an "Urbid": an open competition for proposals from citizens, designers, architects,
      artists, engineers and anyone, who has an idea what it can be turned into.`,
    },
  },
  proposals: {
    heading: {
      bg: `Имаш идея за някой от текущите конкурси? Регистрирай се като дизайнер и ни я изпрати.`,
      en: `You have an idea for one of the ongoing open competitions? Register as a designer and send it to us.`,
    },
    paragraph: {
      bg: `За да изпратиш идея, трябва да се регистрираш като дизайнер, изпращайки ни мейл на designers@urbid.org.
      След потвърждение на профила ти, ще ти дадем достъп до форма, в която можеш да качиш всички текстове и материали,
      представящи идеята ти, която ще публикуваме на сайта.`,
      en: `To send an idea, you need to register as a designer by sending us an email to designers@urbid.org.
      After confirming your profile, we'll send you access to a form, in which you can upload all texts and visualizations,
      presenting your idea, which we will then publish on our website.`,
    },
  },
  realizations: {
    heading: {
      bg: `От градинка край кръстовище до автобусна спирка: заедно променяме града ни.`,
      en: `Together we change our city, one corner at a time.`,
    },
    paragraph: {
      bg: `След съгласуване с гражданите и местните власти и отворен конкурс, избраната идея се 
      реализира от нас - и от вас. Може да се включите като дизайнери, доброволци за изпълнението,
      спонсори или участници в някое от нашите събития.`,
      en: `After coordinating with citizens and the local authorities, and an open competition,
      the selected idea is realized by us - and you. You can take part as a designer, volunteer for
      the implementation, sponsor or a participant in one of our events.`,
    },
  },
};

export const footer = {
  about: {
    bg: `URBID е неправителствена организация.<br>
        URBID платформата е изпълнена с финансовата подкрепа на проекта DESIGNSCAPES, който се финансира от рамкова програма "Хоризонти 2020: Дизайн и иновации в градска среда" на Европейския Съюз.<br>
        H2020-SC6-CO-CREATION-2016-3, номер на проекта: 763784.`,
    en: `Urbid is a non-profit organisation.<br>
        It received a grant from the DESIGNSCAPES project 
        funded by the Horizons 2020 framework programme of the European Union Design Enabled Innovation in Urban Environments.<br>
        H2020-SC6-CO-CREATION-2016-3, project number: 763784`,
  },
  slogan: {
    bg: "Промяната в града не идва само с големите ремонти, но и с малките намеси.<br>Сега е твой ред.<br><br>Свържи се с нас на info@urbid.org.",
    en: `Change happens not only with big interventions, but also with small acts.<br>Now it's your turn.<br><br>Say hello at info@urbid.org.`,
  },
};

export const signInOrSignUp = {
  addProposal: {
    bg: "Регистрирай се или влез, за да изпратиш предложение.",
    en: "Sign in or sign up to send a proposal.",
  },
};

export const banners = {
  slogan: {
    bg: "Промяната в града идва<br />не само с големите ремонти,<br />но и с малките намеси.<br />Сега е твой ред.",
    en: "Change happens not only<br />with big interventions,<br />but also small acts.<br />Now its your turn.",
  },
  app: {
    heading: {
      bg: "Свали Урбид на телефона си и ни изпращай номинации, където и да си.",
      en: "Download Urbid and send us nominations, wherever you are.",
    },
  },
  nominations: {
    heading: {
      bg: "Номинирай обект",
      en: "Nominate a place",
    },
    paragraph: {
      bg: "Изпрати ни твоята номинация за обект, който има нужда от промяна.",
      en: "Send us your proposal for a place in need of change",
    },
  },
  designers: {
    heading: {
      bg: "Имаш идеи?",
      en: "Got ideas?",
    },
    paragraph: {
      bg: "Регистрирай се като дизайнер, за да изпращаш своите предложения за намеси.",
      en: "Register as a designer to send us your intervention proposals.",
    },
  },
  process: {
    nominations: {
      heading: {
        bg: `номинирай`,
        en: `nominate`,
      },
      cta: {
        bg: `виж всички номинации`,
        en: `see all nominations`,
      },
      paragraph: {
        bg: `Номинирай малки градски обекти за промяна. Гласувай кои да преобразим заедно.`,
        en: `Nominate small urban objects and spaces for change. Vote to choose the ones we transform together.`,
      },
    },
    urbids: {
      heading: {
        bg: `избери идея`,
        en: `choose an idea`,
      },
      cta: {
        bg: `виж всички активни проекти`,
        en: `see all active projects`,
      },
      paragraph: {
        bg: `Изпрати предложение за промяна на най-гласуваните обекти. Гласувай за най-добрите идеи.`,
        en: `Send a proposal for the most voted for objects and spaces. Vote for the best ideas.`,
      },
    },
    realizations: {
      heading: {
        bg: `реализирай`,
        en: `implement`,
      },
      cta: {
        bg: `виж всички завършени проекти`,
        en: `see all finished projects`,
      },
      paragraph: {
        bg: `Избраната идея се реализира от нас - и от вас. Включи се като доброволец, спонсор, дизайнер, архитект, инженер.`,
        en: `The selected idea is implemented by us - and you. Join as a volunteer, sponsor, designer, architect, engineer.`,
      },
    },
  },
};

export const profile = {
  title: {
    bg: "профил",
    en: "profile",
  },
  exit: {
    bg: "изход",
    en: "exit",
  },
  myNominations: {
    bg: "Моите номинации",
    en: "My nominations",
  },
  pending: {
    bg: "Очакващи одобрение",
    en: "Pending approval",
  },
  approved: {
    bg: "Одобрени",
    en: "Approved",
  },
};

export const labels = {
  completedAt: {
    bg: "приключена",
    en: "completed",
  },
  designer: {
    bg: "дизайнер",
    en: "designer",
  },
  team: {
    bg: "екип",
    en: "team",
  },
  partners: {
    bg: "партньори",
    en: "partners",
  },
  sponsors: {
    bg: "спонсори",
    en: "sponsors",
  },
  deadline: {
    bg: "краен срок",
    en: "deadline",
  },
  docs: {
    bg: "документи",
    en: "docs",
  },
};

/* ADD NOMINATION FORM */
export const addNomination = {
  thankYou: {
    bg: `Благодарим ти!`,
    en: `Thank you!`,
  },
  title: {
    bg: "Добави номинация",
    en: "Add Nomination",
  },
  description: {
    bg: `
      1. Добави заглавие и описание<br>
      2. Избери местоположение<br>
      3. Качи снимки`,
    en: `
      1. Add title and description<br>
      2. Pick location<br>
      3. Upload photos`,
  },
  step1: {
    stepName: {
      bg: `текст`,
      en: `text`,
    },
    title: {
      label: {
        bg: `Заглавие`,
        en: `Title`,
      },
      placeholder: {
        bg: `Заглавие...`,
        en: `Title...`,
      },
      error: {
        bg: `Моля, добави заглавие`,
        en: `Please add a title`,
      },
    },
    description: {
      label: {
        bg: `Описание`,
        en: `Description`,
      },
      placeholder: {
        bg: `Описание...`,
        en: `Description...`,
      },
      error: {
        bg: `Моля, добави описание`,
        en: `Please add a description`,
      },
    },
    next: {
      bg: "избери местоположение",
      en: "pick location",
    },
  },
  step2: {
    stepName: {
      bg: `местоположение`,
      en: `location`,
    },
    location: {
      label: {
        bg: `Местоположение`,
        en: `Location`,
      },
      error: {
        bg: `Моля, добави местоположение.`,
        en: `Please set a location.`,
      },
    },
    next: {
      bg: "добави снимки",
      en: "add photos",
    },
  },
  step3: {
    stepName: {
      bg: `снимки`,
      en: `photos`,
    },
    prompt: {
      bg: "добави снимки",
      en: "upload photos",
    },
    images: {
      error: {
        bg: `Моля, добави поне една снимка.`,
        en: `Please upload at least one image.`,
      },
    },
    next: {
      bg: "изпрати номинация",
      en: "send nomination",
    },
  },
  step4: {
    stepName: {
      bg: `Благодарим ти!`,
      en: `Thank you!`,
    },
    prompt: {
      bg: "Качи снимки",
      en: "Upload photos",
    },
    text: {
      heading: {
        bg: "Твоята номинация беше качена успешно.",
        eng: "Your submission was successfully uploaded.",
      },
      paragraph: {
        bg: "Благодарим ти, че сподели! Ще прегледаме номинацията ти и след одобрение от администратор, ще я качим в списъка.",
        eng: "Thank you for taking part! We will review your nomination and after approval, it will be listed.",
      },
      back: {
        bg: "Към номинации",
        eng: "Back to all nominations",
      },
    },
  },
};
// slow down track
export const pageNotFound = {
  heading: {
    bg: "Страницата не е намерена.",
    en: "Page not found.",
  },
  paragraph: {
    bg: "Страницата, която се опитваш да достигнеш, не съществува!",
    en: "Seems the page you are trying to reach does not exist!",
  },
};

export const toasts = {
  onLogIn: {
    bg: "Влезе успешно. Юху!",
    en: "You are now logged in. Yoohoo!",
  },
  onSignUp: {
    bg: "Регистрацията е успешна. Юху!",
    en: "Now you are registered. Yoohoo!",
  },
  onSignOut: {
    bg: "Излезе от профила си. Юху!",
    en: "Now you are signed out. Yoohoo!",
  },
  onVote: {
    bg: "Гласът ти беше записан. Юху!",
    en: "Your vote is recorded. Yoohoo!",
  },
};

export const descriptions = {
  urbids: {
    bg: `Тук са номинациите с достатъчен брой гласове от публиката и
          одобрение от местните власти. Включи се с предложение за
          промяната им! Граждани, дизайнери, архитекти, артисти, инженери
          - конкурсите са отворени за всеки с креативна визия и функционална
          идея.`,
    en: `Here are the nominations who got enough votes from citizens and an approval
           from the municipality. Join by submitting a proposal for their renovation!
           Citizens, designers, architects, artists, engineers - the competitions
           are open to anyone with a creative vision and a functional idea.`,
  },
  nominations: {
    bg: `Градът ни е пълен с места и обекти, които имат нужда от промяна и които с малко
      усилия могат да се превърнат в нещо хубаво.<br>
      Номинирай ги: изпрати кратко описание, местоположение и няколко снимки
      и гласувай, за да бъдат следващата намеса.`,
    en: `Our city is full of objects and places in need of change, which - with a little effort - 
      can turn into something beautiful.<br>
      Nominate them: send us a short description,
      location and a few pictures, and vote for them to become the next intervention.`,
  },
  realizations: {
    bg: `След съгласуване с гражданите и местните власти и отворен конкурс, избраната идея се 
      реализира от нас - и от вас. Може да се включите като дизайнери, доброволци за изпълнението,
      спонсори или участници в някое от нашите събития.`,
    en: `After coordinating with citizens and the local authorities, and an open competition,
      the selected idea is realized by us - and you. You can take part as a designer, volunteer for
      the implementation, sponsor or a participant in one of our events.`,
  },
};
