/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import { withRouter, Link } from "react-router-dom";

// import ProposalPopup from '../proposals/ProposalPopup';
import orderBy from "lodash/orderBy";
import { firestore } from "../../firebase.js";
import { collectIdsAndDocs, queryBySlug } from "../../utilities.js";
import Proposal from "./Proposal";

import PaypalButtons from "../auth/PaypalButtons";

class ProposalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.unsubscribeFromComments = null;
  }

  get slug() {
    const { match } = this.props;
    return match ? match.params.proposal_slug : null;
  }

  get urbidSlug() {
    const { match } = this.props;
    return match ? match.params.slug : null;
  }

  componentDidMount() {
    queryBySlug("proposals", this.slug, (doc) => {
      queryBySlug("urbids", this.urbidSlug, (urbidDoc) => {
        this.setState({
          id: doc.id,
          ...doc.data(),
          urbid: urbidDoc.data(),
          urbidId: urbidDoc.id,
        });
      });

      this.unsubscribeFromComments = firestore
        .collection(`proposals/${doc.id}/comments`)
        .onSnapshot((snapshot) => {
          let comments = snapshot.docs.map(collectIdsAndDocs);
          comments = orderBy(comments, "createdAt", "desc");
          this.setState({ comments });
        });
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromComments();
  }

  render() {
    return (
      <main>
        {this.state.title && (
          <Proposal
            {...this.state}
            id={this.state.id}
            urbidId={this.state.urbidId}
            urbidSlug={this.urbidSlug}
          />
        )}
      </main>
    );
  }
}

export default withRouter(ProposalPage);
