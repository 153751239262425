/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { setMargin } from "../common/spacing";
import { PARAGRAPH } from "../common/typography";

const Detail = ({ label, children }) => (
  <div
    css={css`
      ${setMargin(["bottom"], "md")}
    `}
  >
    <PARAGRAPH
      css={css`
        opacity: 0.55;
      `}
    >
      {label}
    </PARAGRAPH>
    <PARAGRAPH>{children}</PARAGRAPH>
  </div>
);

export default Detail;
