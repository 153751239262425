/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import { withRouter } from "react-router-dom";
import Realization from "./Realization";
import Comments from "../elements/Comments";
import orderBy from "lodash/orderBy";
import { firestore } from "../../firebase.js";
import { collectIdsAndDocs, queryBySlug } from "../../utilities.js";

class RealizationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.unsubscribeFromComments = null;
  }

  get slug() {
    const { match } = this.props;
    return match ? match.params.slug : null;
  }

  componentDidMount() {
    queryBySlug("proposals", this.slug, (doc) => {
      let proposal = doc.data();
      firestore
        .collection("urbids")
        .doc(proposal.urbidId)
        .get()
        .then((urbidDoc) => {
          let urbid = urbidDoc.data();
          this.setState({
            ...proposal,
            id: doc.id,
            urbidId: urbidDoc.id,
            urbid: urbid,
          });
        });

      this.unsubscribeFromComments = firestore
        .collection(`proposals/${doc.id}/comments`)
        .onSnapshot((snapshot) => {
          let comments = snapshot.docs.map(collectIdsAndDocs);
          comments = orderBy(comments, "createdAt", "desc");
          this.setState({ comments });
        });
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromComments();
  }

  render() {
    const { id, comments } = this.state;
    console.log(this.state);
    return (
      <main>
        {id && <Realization id={id} {...this.state} />}
        {id && (
          <Comments
            docRef={firestore.collection(`proposals`).doc(id)}
            comments={comments}
          />
        )}
      </main>
    );
  }
}

export default withRouter(RealizationPage);
