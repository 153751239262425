/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import {colorNominations, colorUrbids, colorRealizations} from '../common/colors';

const getColor = (color) => {
  if (color === 'urbids') {
    return colorUrbids;
  } else if (color === 'nominations') {
    return colorNominations;
  } else if (color === 'realizations') {
    return colorRealizations;
  } else {
    return color;
  }
}

const createMapOptions = (maps) => (
  {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    clickableIcons: false,
    styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
  }
);

const MapSingleItemMarker = ({ imageUrl, color }) => {

  return (
    <div
      css={css`
        width: 180px;
        height: 100px;
        background-color: #b0b0b0;
        transform: translate3d(-50%, -100%, 0);
        padding: 12px;

        @media (min-width: 1200px) {
          width: 240px;
          height: 150px;
          padding: 12px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0; 
          height: 0; 
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #b0b0b0;
          margin-left: -4px;
        }
      `}>
      <div 
        css={css`
          background-image: url(${imageUrl});
          background-size: cover;
          background-position: center center;
          height: 100%;
          width: 100%;
        `}>
        
      </div>
    </div>
  )
};

const MapSingleItem = ({ lat, lng, imageUrl, color }) => (
  <div css={css`
      height: 300px;

      @media (min-width: 768px) {
        height: 100%;
        min-height: 300px;
      }
    `}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyDLJR14hApMiSCahjE4iiDZkhrHbLZGhm4' }}
      defaultCenter={{ lat, lng }}
      center={{ lat, lng }}
      defaultZoom={15}
      options = {createMapOptions}>
      <MapSingleItemMarker
        imageUrl={imageUrl}
        lat={lat}
        lng={lng}
        color={color}
      />
    </GoogleMapReact>
  </div>
);

export default MapSingleItem;