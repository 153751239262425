import React from 'react';
import { UserContext } from '../providers/UserProvider';

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component!';
}

const withUser = (Component) => {
  // const [lang, setLang] = useState('bg');
  // console.log('my lang', lang);
  const WrappedComponent = props => {
    return (
      <UserContext.Consumer>
        { user => <Component user={user} {...props} /> }
      </UserContext.Consumer>
    );
  }

  WrappedComponent.displayName = `WithUser(${getDisplayName(WrappedComponent)})`
  return WrappedComponent;
}

export default withUser;
