/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { setPadding, setMargin } from "./spacing";
import { TITLE_SMALL } from "./typography";

export const Section = styled("section")`
  ${setPadding(["top", "bottom"], "lg")}

  @media (max-width: 767px) {
    ${setPadding(["top", "bottom"], "md")}
  }
`;

const sizes = {
  sm: 800,
  md: 1000,
  lg: 1400,
  xl: 1920,
};

export const Container = styled("div")`
  width: 100%;
  /* max-width: ${(props) => sizes[props.size || "md"]}px; */
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  ${setPadding(["left", "right"], "sm")}
`;

export const Half = styled("div")`
  width: 100%;
  ${setPadding(["left", "right"], "sm")}

  @media (min-width: 768px) {
    width: 50%;
    min-width: 50%;
  }
`;

export const Full = styled("div")`
  width: 100%;
  ${setPadding(["left", "right"], "sm")}
`;

export const SectionHeader = ({ children }) => (
  <Container>
    <div
      css={css`
        ${setPadding(["top"], "xl")}
        ${setPadding(["bottom"], "lg")}
      `}
    >
      {children}
    </div>
  </Container>
);

export const Third = styled("div")`
  width: 100%;
  ${setPadding(["left", "right"], "sm")}

  @media (min-width: 768px) {
    width: 33.33%;
    min-width: 33.33%;
  }
`;

export const TwoThirds = styled("div")`
  width: 100%;
  ${setPadding(["left", "right"], "sm")}

  @media (min-width: 768px) {
    width: 66.66%;
    min-width: 66.66%;
  }
`;

export const Row = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0px -12px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 0px -15px;
  }
`;
