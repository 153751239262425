import React from 'react';
import { LanguageContext } from '../providers/LanguageProvider';

const withLanguage = (Component) => {

  const WrappedComponent = props => {
    return (
      <LanguageContext.Consumer>
        { value => {
          return <Component {...value} {...props} />;
        } }
      </LanguageContext.Consumer>
    );
  }

  return WrappedComponent;
};


export default withLanguage;