/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { FormGroup, Form, Input, Textarea } from './Form';
import { addNomination } from '../../language/content';
import { PARAGRAPH } from '../common/typography';
import { setMargin, setPadding } from '../common/spacing';
import Button from '../elements/Button';

export default class Step3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };

    this.isValidated = this.isValidated.bind(this);
  }

  componentWillUnmount() {}

  // This review screen had the 'Save' button, on clicking this is called
  isValidated() {
    /*
    typically this method needs to return true or false (to indicate if the local forms are validated, so StepZilla can move to the next step),
    but in this example we simulate an ajax request which is async. In the case of async validation or server saving etc. return a Promise and StepZilla will wait
    ... for the resolve() to work out if we can move to the next step
    So here are the rules:
    ~~~~~~~~~~~~~~~~~~~~~~~~
    SYNC action (e.g. local JS form validation).. if you return:
    true/undefined: validation has passed. Move to next step.
    false: validation failed. Stay on current step
    ~~~~~~~~~~~~~~~~~~~~~~~~
    ASYNC return (server side validation or saving data to server etc).. you need to return a Promise which can resolve like so:
    resolve(): validation/save has passed. Move to next step.
    reject(): validation/save failed. Stay on current step
    */

    this.setState({
      saving: true
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setState({
          saving: true
        });

        this.props.updateStore({savedToCloud: true});  // Update store here (this is just an example, in reality you will do it via redux or flux)
        // call resolve() to indicate that server validation or other aync method was a success.
        // ... only then will it move to the next step. reject() will indicate a fail
        resolve();
        // reject(); // or reject
      }, 5000);
    });
  }

  render() {
    const { language } = this.props;

    return (
      <Form
        css={css`
          background-color: transparent;
          padding: 0px;
        `}>
        <PARAGRAPH
          dangerouslySetInnerHTML={{ __html: addNomination.step4.text.paragraph[language] }} 
          css={css`
            ${setMargin(['bottom'], 'md')}
          `}/>
        <Button 
          style='accent'
          to='/nominations'
          color='nominations'>
          {addNomination.step4.text.back[language]}
        </Button>
      </Form>
    )
  }
}
