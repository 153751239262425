/** @jsx jsx */
import { useContext, useState, useEffect } from "react";
import { jsx, css } from "@emotion/core";
import { firestore } from "../../firebase.js";
import Card, { __Excerpt, __Details } from "../elements/Card";
import { H3, H4, H5, PARAGRAPH } from "../common/typography";
import { setPadding, setMargin } from "../common/spacing";
import { darkergrey, midgrey, colorRealizations } from "../common/colors";
import withLanguage from "../withLanguage";
import { getImageUrl } from "../../utilities";
import Location from "../elements/Location";
import { BoxLink } from "../elements/Links";
import Detail from "../elements/Detail";
import { labels } from "../../language/content";
import moment from "moment";
import "moment/locale/bg";

const seeMore = {
  bg: "галерия и детайли",
  en: "photos and details",
};

const RealizationCardUrbid = ({ id, title, imageUrls, slug }) => {
  const imageUrl = getImageUrl(imageUrls[0], "urbids", id, "md");
  return (
    <div
      css={css`
        background-color: ${darkergrey};
        color: white;
        height: 150px;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        width: 100%;
        margin-bottom: -75px;
        z-index: 2;
        position: relative;
        width: 66.66%;

        @media (max-width: 767px) {
          flex-direction: column;
          width: 100%;
          margin-bottom: 0px;
          background-color: transparent;
          height: auto;
          padding: 0px;
        }
      `}
    >
      <div
        css={css`
          background-image: url(${imageUrl});
          min-width: 220px;
          height: 100%;
          background-size: cover;
          background-position: center center;

          @media (max-width: 767px) {
            width: 150px;
            height: 90px;
            min-width: 150px;
            position: absolute;
            left: 15px;
            bottom: -45px;
          }
        `}
      ></div>
      <div
        css={css`
          ${setPadding(["left", "right"], "md")}

          max-width: 450px;
          @media (max-width: 767px) {
            background-color: ${darkergrey};
            ${setPadding(["top"], "md")}
            ${setPadding(["bottom"], "lg")}
            ${setPadding(["left", "right"], "sm")}
            max-width: none;
            width: 100%;
          }

          h4 {
            margin-bottom: 0px !important;
          }
        `}
      >
        <PARAGRAPH>предложение към</PARAGRAPH>
        <H5>{title}</H5>
      </div>
    </div>
  );
};

const RealizationCard = (props) => {
  const {
    title,
    id,
    slug,
    description,
    imageUrls,
    createdAt,
    language,
    user,
    excerpt,
    urbidId,
    projectInfo,
  } = props;
  const { completedAt, designer } = projectInfo;
  const [urbid, setUrbid] = useState(null);
  const imageUrl = getImageUrl(imageUrls[0], "proposals", id, "lg");
  const formattedCompletedAt = completedAt
    ? moment(completedAt.toDate()).format("LL")
    : "";

  useEffect(() => {
    if (!urbid && urbidId) {
      firestore
        .collection("urbids")
        .doc(urbidId)
        .get()
        .then((docRef) => {
          console.log("doc has data", docRef.data());
          setUrbid({ ...docRef.data() });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        ${setMargin(["bottom"], "xl")}
      `}
    >
      {urbid && (
        <RealizationCardUrbid
          id={urbidId}
          title={urbid.title[language]}
          imageUrls={urbid.imageUrls}
          slug={urbid.slug}
        />
      )}
      <div
        css={css`
          display: flex;
          background-color: ${midgrey};

          @media (max-width: 767px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            ${setPadding(["left", "right"], "sm")}
            flex-basis: 0;
            flex-grow: 1;
            background-image: url(${imageUrl});
            background-size: cover;
            background-position: center center;
            min-height: 400px;

            @media (max-width: 767px) {
              min-height: 300px;
            }
          `}
        />
        <div
          css={css`
            ${setPadding(["left", "right"], "md")}
            ${setPadding(["top", "bottom"], "xl")}
            flex-basis: 0;
            flex-grow: 1;
            position: relative;

            @media (max-width: 767px) {
              ${setPadding(["left", "right"], "sm")}
              ${setPadding(["top"], "md")}
            }
          `}
        >
          <div
            css={css`
              ${setPadding(["bottom"], "md")}
            `}
          >
            <H3
              css={css`
                ${setPadding(["bottom"], "sm")}
              `}
            >
              {title[language]}
            </H3>
            {urbid && (
              <Location
                address={`${urbid.address.neighborhood[language]}, ${urbid.address.city[language]}`}
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              ${setMargin(["bottom"], "md")}

              @media (max-width: 767px) {
                align-items: flex-start;
                ${setMargin(["bottom"], "sm")}
              }
            `}
          >
            <div
              css={css`
                flex-basis: 0;
                flex-grow: 1;
              `}
            >
              <Detail label={labels.completedAt[language]}>
                {formattedCompletedAt}
              </Detail>
            </div>
            <div
              css={css`
                flex-basis: 0;
                flex-grow: 1;
              `}
            >
              <Detail label={labels.designer[language]}>
                {user.displayName}
              </Detail>
            </div>
          </div>
          <PARAGRAPH
            css={css`
              opacity: 0.55;
            `}
            dangerouslySetInnerHTML={{
              __html: description[language].substring(0, 200) + "...",
            }}
          />
          <div
            css={css`
              min-width: 360px;
              width: 360px;
              z-index: 10;
              height: 120px;
              position: absolute;
              bottom: -60px;
              right: 0;

              @media (max-width: 767px) {
                min-width: 50%;
                width: 66.66%;
              }
            `}
          >
            {urbid && (
              <BoxLink
                color={colorRealizations}
                textColor={"white"}
                url={`/completed/${slug}`}
              >
                {seeMore[language]}
              </BoxLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLanguage(RealizationCard);
