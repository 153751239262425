import React, { Component, createContext } from 'react';
import { firestore } from '../firebase.js';
import { collectIdsAndDocs } from '../utilities.js';
import orderBy from 'lodash/orderBy';

export const ProposalsContext = createContext();

class ProposalsProvider extends Component {
  state = {
    proposals: []
  }

  unsubscribeFromFirestore = null;

  componentDidMount = () => {
    this.unsubscribeFromFirestore = firestore.collection('proposals').where('status', '==', 'active').onSnapshot(snapshot => {
      let proposals = snapshot.docs.map(collectIdsAndDocs);
      proposals = orderBy(proposals, "createdAt", "desc");
      this.setState({ proposals });
    });
  }

  componentWillUnmount = () => {
    this.unsubscribeFromFirestore();
  }

  render() {
    const { proposals } = this.state;
    const { children } = this.props;
    return (
      <ProposalsContext.Provider value={ proposals }>{children}</ProposalsContext.Provider>
    )
  }
}

export default ProposalsProvider;
