/** @jsx jsx */

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { storage, storageUrl } from "../../firebase.js";
import FileUploader from "react-firebase-file-uploader";
import { FormGroup, Form } from "./Form";
import { ICON, PARAGRAPH } from "../common/typography";

import { addNomination } from "../../language/content";

const lang = "bg";

const FileUploaderOverlayThumb = ({ imageName, nominationId }) => {
  if (!imageName) {
    console.error("no imageUrls passed to utilities");
    return;
  }
  const imageUrl =
    storageUrl +
    "nominations" +
    "%2F" +
    nominationId +
    "%2F" +
    imageName +
    "?alt=media";
  return (
    <div
      css={css`
        background-image: url('${imageUrl}');
        background-size: cover;
        background-position: center center;
        height: 90px;
        width: 90px;
        margin: 5px;
        border-radius: 4px;
      `}
    ></div>
  );
};

const LoadingThumb = () => {
  return (
    <div
      css={css`
        display: inline-block;
        position: relative;
        height: 90px;
        width: 90px;
        margin: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 4px;

        div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          height: 30px;
          width: 30px;
          border: 4px solid #00cc66;
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: #00cc66 transparent transparent transparent;
        }

        div:nth-child(1) {
          animation-delay: -0.45s;
        }

        div:nth-child(2) {
          animation-delay: -0.3s;
        }

        div:nth-child(3) {
          animation-delay: -0.15s;
        }

        @keyframes lds-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}
      className="lds-ring"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default class Step5 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrls: [],
      isUploading: false,
      canSubmit: "not clear yet",
    };

    this.isValidated = this.isValidated.bind(this);
  }

  uploadingImages = 0;

  createLoaders = () => {
    let loaders = [];
    for (let i = 1; i <= this.uploadingImages; i += 1) {
      loaders.push(<LoadingThumb />);
    }
    return loaders;
  };

  isValidated() {
    let isDataValid = false;

    if (this.state.imageUrls.length > 0) {
      this.props.updateStore({
        ...this.state,
        savedToCloud: false, // use this to notify step4 that some changes took place and prompt the user to save again
      });
      isDataValid = true;
    } else {
      this.setState({ canSubmit: false });
      isDataValid = false;
    }

    return isDataValid;
  }

  handleUploadError = (error) => {
    this.setState({ isUploading: false });
  };

  handleUploadStart = () => {
    this.uploadingImages += 1;
    this.setState({
      isUploading: true,
      uploadProgress: 0,
    });
  };

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress,
    });

  handleUploadError = (error) => {
    this.setState({
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = async (filename) => {
    this.uploadingImages -= 1;
    const { nominationId } = this.props;
    const imageUrl = await storage
      .ref(`nominations/${nominationId}`)
      .child(filename)
      .getDownloadURL();

    this.setState((oldState) => ({
      imageUrls: [...oldState.imageUrls, filename],
      isUploading: false,
      canSubmit: true,
    }));
  };

  render() {
    const { nominationId } = this.props;
    const { imageUrls } = this.state;
    const hasError = this.state.canSubmit === false;
    const language = "bg";

    return (
      <Form>
        <FormGroup
          label={addNomination.step3.stepName[language]}
          hasError={hasError}
          errorMessage={addNomination.step3.images.error[language]}
        >
          <div
            css={css`
              height: 100%;
              width: 100%;
              pointer-events: none;
              position: absolute;
              background-color: #c4c4c4;
              top: 0;
              left: 0;
            `}
          >
            {imageUrls.length === 0 && (
              <div
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate3d(-50%, -50%, 0);
                  display: flex;
                  align-items: center;
                `}
              >
                <ICON icon="plus" />
                <PARAGRAPH
                  css={css`
                    color: black;
                    margin-left: 5px;
                    margin-top: 2px;
                  `}
                >
                  {addNomination.step3.prompt[lang]}
                </PARAGRAPH>
              </div>
            )}

            <div
              css={css`
                display: flex;
                padding: 10px;
              `}
            >
              {imageUrls &&
                imageUrls.map((imageName, index) => (
                  <FileUploaderOverlayThumb
                    imageName={imageName}
                    nominationId={nominationId}
                  />
                ))}
              {this.uploadingImages > 0 && this.createLoaders()}
            </div>
          </div>

          <FileUploader
            accept="image/*"
            name="image-uploader-multiple"
            randomizeFilename
            storageRef={storage.ref().child(`nominations/${nominationId}`)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            multiple
          />
        </FormGroup>
      </Form>
    );
  }
}
