import React, { Component } from 'react';

// TITLE AND DESCRIPTION OF URBID

export default class Step4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };

    this.isValidated = this.isValidated.bind(this);
  }

  // This review screen had the 'Save' button, on clicking this is called
  isValidated() {

    this.setState({
      saving: true
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setState({
          saving: true
        });

        this.props.updateStore({savedToCloud: true});
        resolve();
        // reject(); // or reject
      }, 5000);
    });
  }

  render() {
    return (
      <div className="step step5 review">
        <div className="row">
          <form id="Form" className="form-horizontal">
            <h1> thank you!!! </h1>
          </form>
        </div>
      </div>
    )
  }
}
