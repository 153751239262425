
/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';

import { CAPTION, ICON } from '../common/typography';
import { setMargin } from '../common/spacing.js';
import withLanguage from '../withLanguage';

const content = {
  bg: 'виж на картата',
  en: 'see on map'
}

const GmapsLink = ({ lat, lng, text }) => {
  const href=`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  return (
    <a 
      target="_blank" 
      href={href}
      css={css`
        
        display: inline-block;
        @media (max-width: 767px) {
          ${setMargin(['top'], 'sm')}
        }
      `}>
      <CAPTION
        css={css`
          border-bottom: 1px solid white;
        `}>{ text }</CAPTION>
    </a>
  );
};

const Location = ({ address, position, color, language }) => {
  const seeOnMap = content[language];
  return (
    <div>
      <div css={css`
        display: flex;
        align-items: flex-start;

        svg {
          min-width: 16px;
        }
      `}>
        <ICON icon='pin' color={color || '#7c7c7c'} size={16}/>
        <CAPTION css={css`
            margin-left: 2px;
            line-height: 1em;
            margin-top: 4px;
          `}>
          { address }
        </CAPTION>
      </div>
      { position &&
        <GmapsLink
          text={seeOnMap}
          lat={position.lat}
          lng={position.lng} />
      }
    </div>
  )
}

export default withLanguage(Location);
