/** @jsx jsx */

import React, { Component, memo } from 'react';
import { Link } from 'react-router-dom';

import { CardImage } from '../elements/Card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { jsx, css } from '@emotion/core';
import { setPadding } from '../common/spacing';

import { H5, ICON} from '../common/typography';

import { getFeaturedImageUrl } from '../../utilities.js';


const StripeCard = ({ isActive, id, index, title, slug, imageUrls, user, tags, countVotes, countComments, onCardClick }) => {
  const imageUrl = imageUrls ? imageUrls[0] : '';

  return(
    <div 
      onClick={onCardClick} 
      data-pos={index}>
      <div 
        css={css`
          pointer-events: none;
        `}>
        <CardImage 
          imageUrl={getFeaturedImageUrl(imageUrl, 'nominations', id, 'sm')}>
          <Link
            css={css`
              pointer-events: all;
              ${setPadding('all', 'sm')}
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              background-color: #353535;
              color: white;
              max-width: 75%;
              width: 75%;
              svg {
                fill: white;
              }
            `}
            onClick={(e) => { e.stopPropagation(); }}
            to = {`/nominations/${slug}`}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}>
              <H5>{title}</H5>
              <ICON icon='forward' size='24'/>
            </div>
            
          </Link>
        </CardImage>
      </div>
    </div>
  );
}

class NominationStripe extends Component {

  activeMarker = 1;

  centerPos = () => {
    this.stripe.slickGoTo(this.activeMarker);
  }

  componentDidUpdate = () => {
    if (this.props.activeMarker !== this.activeMarker) {
      this.updateActiveMarker(this.props.activeMarker);
      this.centerPos();
    }
  }

  updateActiveMarker = (newIndex) => {
    this.activeMarker = newIndex;
  }

  onCardClick = (e) => {
    e.preventDefault();
    let index = Number(e.target.dataset.pos);

    if (index !== undefined) {
      this.updateActiveMarker(index);
      this.centerPos();
      this.props.onStripeInteraction(index);
    }
  }


  sliderSettings = {
    className: "urb-slider__imgs",
    dots: true,
    centerMode: true,
    variableWidth: true,
    initialSlide: 1,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    onSwipe: (res) => {
      let { nominations } = this.props;
      let newIndex;

      if (res === 'left') {
        newIndex = (this.activeMarker < nominations.length - 1) ? (Number(this.activeMarker) + 1) : 0;
      } else if (res === 'right') {
        newIndex = (this.activeMarker > 0) ? Number(this.activeMarker) - 1 : (nominations.length - 1);
      }

      let newPos = nominations[newIndex].address.position;
      this.updateActiveMarker(newIndex);
      this.props.onStripeInteraction(newIndex);
    }
  };

  render() {
    const { nominations } = this.props;

    return (
      <div css={css`
          position: absolute;
          bottom: 0;
          width: 100%;
          overflow: hidden;
          padding: 15px 0px;
        `}>
        <Slider ref={div => this.stripe = div} {...this.sliderSettings}>
          { nominations.map((nomination, index) =>
          <StripeCard 
            {...nomination}
            key = {`stripe-card-${nomination.id}`}
            index = {index}
            onCardClick = { this.onCardClick.bind(this) }
            isActive={(this.activeMarker === Number(index)) ? true : false}
          />) }
        </Slider>
      </div>
    )
  }
}

export default memo(NominationStripe);
