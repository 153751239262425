/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { collectIdsAndDocs, queryBySlug } from '../../utilities.js';
import { withRouter } from 'react-router-dom';
import { firestore } from '../../firebase.js';
import Nomination from './Nomination';
import Comments from '../elements/Comments';
import { orderBy } from 'lodash';
import { Helmet } from 'react-helmet';

class NominationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.unsubscribeFromComments = null;
  };

  get slug() {
    const { match } = this.props;
    return match ? match.params.slug: null;
  };

  componentDidMount() {
    queryBySlug('nominations', this.slug, doc => {
      this.setState({
        id: doc.id,
        ...doc.data()
      });

      this.unsubscribeFromComments = firestore
        .collection(`nominations/${doc.id}/comments`)
        .onSnapshot(snapshot => {
          let comments = snapshot.docs.map(collectIdsAndDocs);
          comments = orderBy(comments, "createdAt", "desc");
          this.setState({ comments });
        });
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromComments();
  }
  
  render() {
    const { id, comments } = this.state;

    return (
      <main>
        <section>
          { id && <Nomination {...this.state} id={id} />}
          { id && <Comments docRef={ firestore.collection(`nominations`).doc(id) } comments={comments} /> }
        </section>
      </main>
    )
  }
}

export default withRouter(NominationPage);









