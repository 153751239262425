/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, css } from '@emotion/core';
import Banner from '../elements/Banner';
import { H3, H4, H5 } from '../common/typography';
import { setMargin } from '../common/spacing';
import { banners } from '../../language/content';
import { LanguageContext } from '../../providers/LanguageProvider';
import RegisterAsDesignerPrompt from '../modules/RegisterAsDesignerPrompt';

const BannerUrbids = () => {

  const { language } = useContext(LanguageContext);
  return (
    <Banner 
      theme='dark'
      accent='urbids'>
      <H3
        css={css`
          ${setMargin(['bottom'], 'sm')}
        `}>
        { banners.designers.heading[language] }
      </H3>
      <H4>{ banners.designers.paragraph[language] }</H4>
      <RegisterAsDesignerPrompt 
        color='urbids' />
    </Banner>
  );
};

export default BannerUrbids;