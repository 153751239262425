import React, { useContext } from "react";
import { Global, css } from "@emotion/core";
import GlobalFonts from "../../fonts/fonts";
import Header from "./Header";
import remove from "lodash/remove";
import split from "lodash/split";
import includes from "lodash/includes";
import { black, darkgrey } from "../common/colors";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../providers/LanguageProvider";

const getPageData = () => {
  let url = typeof window !== "undefined" ? window.location.href : "";
  let pageData = { type: "home", level: null, previous: "./" };
  let strings = remove(split(url, "/"), (s) => s.length !== 0);

  if (strings.length) {
    if (includes(strings, "nominations")) {
      pageData.type = "nominations";
      pageData.title = {
        bg: "Номинации",
        en: "Nominations",
      };
    } else if (includes(strings, "urbids")) {
      pageData.type = "urbids";
      pageData.title = {
        bg: "Урбиди",
        en: "Urbids",
      };
    } else if (includes(strings, "realizations")) {
      pageData.type = "realizations";
      pageData.title = {
        bg: "Намеси",
        en: "Interventions",
      };
    } else {
      pageData.title = {
        bg: "URBID",
        en: "URBID",
      };
    }

    pageData.level = strings[3]
      ? strings[3] === "add"
        ? "add"
        : "single"
      : "all";
  }
  return pageData;
};

const Layout = ({ children, props }) => {
  const pageData = getPageData();
  const { language } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageData.title[language]}</title>
      </Helmet>
      <GlobalFonts />
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          html,
          body {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: #d9d9d9;
            color: ${black};
            overflow-x: hidden;
            max-width: 100%;
            font-family: "Intro Regular", sans-serif;

            > div {
              margin-top: 0;
            }
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          button {
            border: none;
            box-shadow: none;
            text-align: inherit;
            font-family: "Intro Regular", sans-serif;
          }

          img {
            max-width: 100%;
            /* width: 100%;
            max-width: 100%;
            height: 100%; */
          }

          button {
            padding: 0px;
            font-size: 100%;
            background-color: transparent;

            &:hover,
            &:focus {
              outline: none;
            }
          }

          .slick-dots {
            top: -12px !important;
            bottom: auto !important;
          }

          .slick-slider {
            padding-bottom: 30px;
          }

          .slick-prev,
          .slick-next {
            z-index: 20;
          }

          .slick-prev {
            left: 15px !important;
          }

          .slick-next {
            right: 15px !important;
          }

          .slick-track {
            display: flex;
          }

          .slick-slide img {
            height: 100%;
            @media (min-width: 1200px) {
              height: 100%;
            }
          }
        `}
      />
      <Header pageTitle={pageData.type} />
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
