import React, { Component } from 'react';
import FileUploader from '../elements/FileUploader';


export default class Step3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectInfo: {
        budget: this.props.getStore().projectInfo.budget,
        docs: [
          {
            docName: null,
            docUrl: null
          }
        ]
      },
      isUploading: false,
      canSubmit: 'not clear yet'
    };

    this.isValidated = this.isValidated.bind(this);
  }

  _validateOnDemand = true;

  isValidated() {
    let isDataValid = false;
    const { budget } = this.state.projectInfo;
    const { docName, docUrl } = this.state.projectInfo.docs[0];
    if (docName && docUrl && budget) {
      this.props.updateStore({
        ...this.state,
        savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
      });
      isDataValid = true;
    } else {
      this.setState({ canSubmit: false });
      isDataValid = false;
    }

    return isDataValid;
  }

  validationCheck() {
    if (!this._validateOnDemand)
      return;

    // only update with actual input
    const userInput = this._grabUserInput();
    let _state = this.state;


    if (userInput.projectInfo.budget) {
      _state.projectInfo.budget = userInput.projectInfo.budget;
    }

    if (userInput.projectInfo.docs[0].docName) {
      _state.projectInfo.docs[0].docName = userInput.projectInfo.docs[0].docName;
    }

    this.setState(_state);
  }
  validationCheck = this.validationCheck.bind(this);

  _grabUserInput() {
    const userInput = {
      projectInfo: {
        budget: this.refs.budget.value,
        docs: [
          {
            docName: this.refs.docname.value
          }
        ]
      }
    }

    return userInput;
  }

  _onUpload(filenames) {
    let _state = this.state;
    _state.projectInfo.docs[0].docUrl = filenames[0];
    this.setState(_state);
  }
  onUpload = this._onUpload.bind(this);

  render() {
    const { proposalId } = this.props;

    let notValidClasses = {};

    // just check budget and docname
    let { budget, docs } = this.state.projectInfo;
    notValidClasses.budget = ((budget === null) || (budget)) ? 'no-error' : 'has-error';
    notValidClasses.docName = ((docs[0].docName === null) || (docs[0].docName)) ? 'no-error' : 'has-error';

    return (
      <div className="urb-add__form__step">
        <form>
          <div className="urb-add__form__group">
            <label className="control-label">
              Бюджет
            </label>
            <div className="urb-add__form__fields">
              <div className={`urb-add__form__field ${notValidClasses.budget}`}>
                <input
                  ref="budget"
                  autoComplete="off"
                  type="number"
                  placeholder='Budget'
                  className="form-control"
                  required
                  defaultValue={this.state.projectInfo && this.state.projectInfo.budget}
                  onBlur={this.validationCheck} />
                <span className="error-message">add budget</span>
              </div>
            </div>
          </div>
          <div className="urb-add__form__group">
            <label className="control-label">
              Документи
            </label>
            <div className="urb-add__form__fields">
              <div className={`urb-add__form__field ${notValidClasses.docName}`}>
                <input
                  ref="docname"
                  autoComplete="off"
                  type="text"
                  placeholder='Document name'
                  className="form-control"
                  required
                  defaultValue={this.state.projectInfo && this.state.projectInfo.docs[0].docName}
                  onBlur={this.validationCheck} />
                <span className="error-message">add document name</span>
              </div>
            </div>
            <div className="urb-add__form__fields">
              <div className="urb-add__form__field">
                { proposalId &&
                  <FileUploader
                    postType='proposals'
                    postId={proposalId}
                    onUpload={this.onUpload}
                /> }
              </div>
            </div>
          </div>
        </form>
        <span className = "error-message">Please upload at least one image.</span>
      </div>
    )
  }
}
