/** @jsx jsx */

import React, { useContext } from "react";
import { jsx, css } from "@emotion/core";
import { LanguageContext } from "../../providers/LanguageProvider";
import Share from "../elements/Share";
import { BackToLink } from "../elements/Links";
import { setPadding, setMargin } from "../common/spacing";
import { nav } from "../../language/content.js";

//<Share color={(color !== 'dark') ? 'white' : '#353535'} />

const SingleItemNavBar = ({ url, backToText, color }) => {
  const { language } = useContext(LanguageContext);

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        justify-content: flex-start;
        border-bottom: 2px solid
          ${color !== "dark"
            ? "rgba(555,555,555,0.15)"
            : "rgba(222,222,222,0.45)"};
        ${setPadding(["top", "bottom"], "sm")}
        ${setMargin(["bottom"], "md")}
      `}
    >
      <BackToLink
        color={color !== "dark" ? "white" : "#353535"}
        backToText={backToText || nav.backToAll[language]}
        to={url}
      />
    </div>
  );
};

export default SingleItemNavBar;
