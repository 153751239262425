/** @jsx jsx */

import React, { useState, useEffect, useRef } from "react";
import { jsx, css } from "@emotion/core";
import { firestore, storage } from "../../firebase.js";
import withUser from "../withUser";
import StepZilla from "react-stepzilla";
import { setMargin } from "../common/spacing";
import { FormGroup, Form, Input, Textarea } from "./Form";
import {
  Container,
  Section,
  Row,
  Half,
  Full,
  Third,
  TwoThirds,
} from "../common/layouts";
import { keys, values } from "lodash";

const EditorPage = () => {
  const [post, setPost] = useState(null);
  const postType = useRef("nominations");
  const postId = useRef("zHbsveOQj7NIVHzQ2wWv");
  const postTypeRef = useRef(null);
  const postIdRef = useRef(null);

  const getPost = () => {
    console.log(
      postTypeRef.current.value,
      postIdRef.current.value,
      `${postType}/${postId}`
    );
    postId.current = postIdRef.current.value;
    postType.current = postTypeRef.current.value;
    firestore
      .doc(`${postType.current}/${postId.current}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          setPost(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  };

  const renderPost = () => {
    let k = keys(post);
    let v = values(post);
    console.log(k, v);

    return <h1>post</h1>;
  };

  console.log(post);

  return (
    <Section>
      <Container
        css={css`
          ${setMargin(["top", "bottom"], "lg")}
        `}
      >
        <h1>editor</h1>
        <Form>
          <FormGroup label={"add post type (proposals, urbids, nominations)"}>
            <Input
              ref={postTypeRef}
              autoComplete="off"
              type="text"
              required
              defaultValue={postType.current}
              onBlur={() => {
                console.log("blur");
              }}
            />
          </FormGroup>
          <FormGroup label={"add post id"}>
            <Input
              ref={postIdRef}
              autoComplete="off"
              type="text"
              required
              defaultValue={postId.current}
              onBlur={() => {
                console.log("blur");
              }}
            />
          </FormGroup>
        </Form>
        <button onClick={getPost}>get post info</button>
        {post !== null && renderPost()}
      </Container>
    </Section>
  );
};

export default withUser(EditorPage);
