import React, { Component, createContext } from 'react';
import { firestore } from '../firebase.js';
import { collectIdsAndDocs } from '../utilities.js';

import _ from 'lodash';

export const PendingNominationsContext = createContext();

class PendingNominationsProvider extends Component {
  state = { nominations: [] }

  unsubscribeFromFirestore = null;

  componentDidMount = () => {
    this.unsubscribeFromFirestore = firestore.collection('nominations').where("status", "==", "pending").onSnapshot(snapshot => {
      let nominations = snapshot.docs.map(collectIdsAndDocs);
      this.setState({ nominations });
    });
  }

  componentWillUnmount = () => {
    this.unsubscribeFromFirestore();
  }

  render() {
    const { nominations } = this.state;
    const { children } = this.props;

    return (
      <PendingNominationsContext.Provider value={ nominations }>{children}</PendingNominationsContext.Provider>
    )
  }
}

export default PendingNominationsProvider;
