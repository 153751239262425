/** @jsx jsx */
import React, { Component, useContext } from "react";
import { jsx, css } from "@emotion/core";
import { Redirect } from "react-router-dom";
import { firestore } from "../../firebase.js";
import withUser from "../withUser";
import UserThumbnail from "../elements/UserThumbnail";
import includes from "lodash/includes";
import forOwn from "lodash/forOwn";

import { PendingNominationsContext } from "../../providers/PendingNominationsProvider";
import { NominationsContext } from "../../providers/NominationsProvider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";
import Card from "./Card";
import InfoWindow from "../elements/InfoWindow";
import Button from "../elements/Button";
import { H3, H4, H5, CAPTION, PARAGRAPH, ICON } from "../common/typography";
import { Container, Row, SectionHeader } from "../common/layouts";
import { setMargin, setPadding } from "../common/spacing";

import { getImageUrl } from "../../utilities.js";

import { sendEmail } from "../../mailgun";

const Nominations = ({ status, onSelect, onStatusChange, onDelete }) => {
  const active = useContext(NominationsContext);
  const pending = useContext(PendingNominationsContext);
  const nominations = status === "active" ? active : pending;
  console.log("NOMS", active, pending);
  return (
    <Container>
      <H4
        css={css`
          ${setMargin(["top", "bottom"], "md")}
        `}
      >
        status: {status}
      </H4>
      <Row>
        {nominations &&
          nominations.map((nomination, index) => {
            const { id, address, title, tags, user, imageUrls } = nomination;
            const imageUrl = getImageUrl(imageUrls[0], "nominations", id, "md");

            return (
              <Card
                imageUrl={imageUrl}
                onClick={(e) => {
                  onSelect(nomination.id);
                }}
              >
                <H5>{title}</H5>
                <div
                  css={css`
                    ${setMargin(["top", "bottom"], "sm")}
                  `}
                >
                  <UserThumbnail {...user} />
                </div>
                <div
                  css={css`
                    display: flex;
                    ${setPadding(["top"], "sm")}
                    border-top: 1px solid grey;
                    justify-content: space-between;
                  `}
                >
                  <button
                    onClick={() => {
                      onStatusChange(nomination.id, status);
                    }}
                  >
                    set to {status === "active" ? "pending" : "active"}
                  </button>
                  <button
                    onClick={() => {
                      onDelete(nomination.id);
                    }}
                  >
                    delete
                  </button>
                </div>
              </Card>
            );
          })}
      </Row>
    </Container>
  );
};

class Admin extends Component {
  state = {
    nominationId: null,
    nominationData: null,
    tags: {
      cultural: false,
      heritage: false,
      dangerous: false,
    },
  };

  get nominationRef() {
    return firestore.doc(`nominations/${this.state.nominationId}`);
  }

  handleCheckboxChange = (name) => async (event) => {
    let tags = { ...this.state.tags };
    tags[name] = event.target.checked;
    await this.setState({ tags });

    let _tags = [];
    forOwn(this.state.tags, (val, key) => {
      if (val === true) {
        _tags.push(key);
      }
    });
    this.nominationRef.update({ tags: _tags });
  };

  onStatusChange = (id, status) => {
    let newStatus = status === "active" ? "pending" : "active";
    firestore.doc(`nominations/${id}`).update({ status: newStatus });

    if (newStatus === "active") {
      firestore
        .doc(`nominations/${id}`)
        .get()
        .then((doc) => {
          sendEmail({
            id: id,
            docData: doc.data(),
          });
        });
    }
  };

  onDelete = (id) => {
    firestore.doc(`nominations/${id}`).update({ status: "deleted" });
  };

  onSelect = (_nominationId) => {
    const { nominationId } = this.state;
    // in this case, deselect
    if (nominationId !== _nominationId) {
      let ref = firestore.doc(`nominations/${_nominationId}`);
      ref
        .get()
        .then((doc) => {
          if (doc.exists) {
            const {
              title,
              description,
              imageUrls,
              user,
              address,
              tags,
            } = doc.data();
            let _tags = { ...this.state.tags }; // store somewhere else

            forOwn(_tags, (tagValue, tagName) => {
              _tags[tagName] = includes(tags, tagName);
            });
            this.setState({
              nominationId: _nominationId,
              nominationData: {
                title,
                description,
                imageUrls,
                user,
                address,
              },
            });
          } else {
            console.log("Doc does not exist.");
          }
        })
        .catch(function (error) {
          console.log("Error getting story:", error);
        });
    } else {
      this.setState({ nominationId: null, nominationData: null });
    }
  };

  render() {
    const { user } = this.props; // to ako si stignal dotuk, moje bi ne ti tr user; t.e. na po-ranno nivo tr da e proverkata
    const { nominationId, nominationData } = this.state;

    if (user && user.createdAt && !user.isAdmin) {
      return <Redirect to="/nominations" />;
    }
    const { title, address, description, createdAt, tags } =
      nominationData || {};

    if (user && user.isAdmin) {
      return (
        <div>
          <SectionHeader>
            <H3>Hello, admin</H3>
          </SectionHeader>

          <Nominations
            status="active"
            onSelect={this.onSelect}
            onStatusChange={this.onStatusChange}
            onDelete={this.onDelete}
          />

          <Nominations
            status="pending"
            onSelect={this.onSelect}
            onStatusChange={this.onStatusChange}
            onDelete={this.onDelete}
          />

          {nominationId && (
            <InfoWindow
              color="nominations"
              isOpen={nominationId}
              onCloseClick={() => {
                this.setState({ nominationId: null });
              }}
            >
              <div>
                <H4>{title}</H4>
                <CAPTION>{address.formattedAddress["bg"]}</CAPTION>
                <PARAGRAPH>{description}</PARAGRAPH>
              </div>

              <form>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.tags.cultural}
                        color="primary"
                        onChange={this.handleCheckboxChange("cultural")}
                        value="cultural"
                      />
                    }
                    label="#cultural"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.tags.heritage}
                        color="primary"
                        onChange={this.handleCheckboxChange("heritage")}
                        value="heritage"
                      />
                    }
                    label="#heritage"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.tags.dangerous}
                        color="primary"
                        onChange={this.handleCheckboxChange("dangerous")}
                        value="dangerous"
                      />
                    }
                    label="#dangerous"
                  />
                </FormGroup>
              </form>
            </InfoWindow>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withUser(Admin);
