/** @jsx jsx */

import React, { useState, useContext } from 'react';
import { jsx, css } from '@emotion/core';
import InfoWindow from '../elements/InfoWindow';
import { SignInOrSignUp } from './SignInAndSignUp';
import { LanguageContext } from '../../providers/LanguageProvider';
import { signInOrSignUp } from '../../language/content';

const SignInOrSignUpPrompt = ({ whySignUp }) => {
  const [ isOpen, toggle ] = useState(false);
  const { language } = useContext(LanguageContext);
  return (
    <div>
      <button
        css={css`
          font-size: 16px;
          line-height: 1.2em;
          color: inherit;
        `}
        onClick={() => { toggle(true); }}>
        { signInOrSignUp[whySignUp][language] }
      </button>
      <InfoWindow
        isOpen={isOpen}
        color='nominations'
        onCloseClick={() => { toggle(false); }}>
        <SignInOrSignUp />
      </InfoWindow>
    </div>
  );
}

export default SignInOrSignUpPrompt;