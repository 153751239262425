/** @jsx jsx */

import { useState, useEffect } from "react";
import { jsx, css } from "@emotion/core";
import { CAPTION, ICON } from "../common/typography";
import { getColor } from "../../utilities";

/* Vote Button */
import withUser from "../withUser";
import withLanguage from "../withLanguage";
import { toast } from "react-toastify";
import { toasts, prompts } from "../../language/content";
import firebase from "../../firebase.js";
import InfoWindow from "../elements/InfoWindow";
import { SignInOrSignUp } from "../auth/SignInAndSignUp";
import Button from "./Button";

const Vote = ({ docRef, voteCount, language, user }) => {
  const [isOpen, toggleOpen] = useState(false);
  const [userHasVoted, setUserHasVoted] = useState(null);
  const [_voteCount, setVoteCount] = useState(voteCount);

  useEffect(() => {
    console.log("use effect");
    if (userHasVoted === null && user) {
      checkIfUserHasVoted();
    }
  });

  const checkIfUserHasVoted = () => {
    if (user) {
      let voteRef = docRef.collection("votes").doc(user.uid);
      voteRef
        .get()
        .then((doc) => {
          setUserHasVoted(!doc.exists);
        })
        .catch((error) => {
          console.log("Error getting vote doc.", error);
        });
    }
  };

  const recordVote = () => {
    let increment;

    if (user && userHasVoted) {
      // delete vote
      increment = firebase.firestore.FieldValue.increment(-1);
      docRef.collection("votes").doc(user.uid).delete();
      docRef.update({ countVotes: increment });
      setUserHasVoted(false);
      setVoteCount(_voteCount - 1);

      toast("vote removed");
    } else if (user && !userHasVoted) {
      // record vote
      increment = firebase.firestore.FieldValue.increment(1);
      docRef.collection("votes").doc(user.uid).delete();
      docRef.update({ countVotes: increment });
      setUserHasVoted(true);
      setVoteCount(_voteCount + 1);
      toast("vote added");
    }
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Button
        onClick={recordVote}
        style="accent"
        fill={userHasVoted}
        color="urbids"
      >
        {userHasVoted
          ? "гласувал си за предложението"
          : "гласувай за предложението"}
      </Button>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-left: 10px;
        `}
      >
        <ICON
          size="24"
          color="#353535"
          icon={!userHasVoted ? "heart-outline" : "heart"}
        />
        <CAPTION
          css={css`
            margin-left: 5px;
          `}
        >
          {_voteCount}
        </CAPTION>
      </div>
      <InfoWindow
        isOpen={isOpen}
        color="nominations"
        onCloseClick={() => {
          this.setState({ isOpen: false });
        }}
      >
        <SignInOrSignUp />
      </InfoWindow>
    </div>
  );
};

export default withUser(withLanguage(Vote));
