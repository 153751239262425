/** @jsx jsx */

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import MapPicker from "react-google-map-picker";
import NodeGeocoder from "node-geocoder";
import { FormGroup, Form } from "./Form";
import { PARAGRAPH, CAPTION } from "../common/typography";

// init defaults
const DefaultPosition = { lat: 42.69, lng: 23.32 };
const DefaultZoom = 14;

var options = {
  provider: "google",
  httpAdapter: "https", // Default
  apiKey: "AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0", // for Mapquest, OpenCage, Google Premier
  language: "bg",
  region: "BG",
  formatter: null,
};

var geocoder = NodeGeocoder(options);

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: this.props.getStore().latitude,
      longitude: this.props.getStore().longitude,
      neighborhood: "null",
      city: "null",
      address:
        "<span style='opacity: 0.75'>Избери местоположение на картата<span>",
      zoom: DefaultZoom,
      canSubmit: "remains to be seen :)",
    };

    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.handleChangeZoom = this.handleChangeZoom.bind(this);
  }

  _validateOnDemand = true;

  isValidated() {
    let address = {
      city: {
        bg: "София",
        en: "Sofia",
      },
      neighborhood: {
        bg: this.state.neighborhood,
        en: this.state.neighborhood,
      },
      formattedAddress: {
        bg: this.state.address,
        en: this.state.address,
      },
      position: {
        lat: this.state.latitude,
        lng: this.state.longitude,
      },
    };
    this.props.updateStore({
      address,
      savedToCloud: false, // use this to notify step4 that some changes took place and prompt the user to save again
    });
    return true;
  }

  handleChangeLocation = (lat, lng) => {
    // this.setState({ latitude: lat, longitude: lng });

    let newState = {
      latitude: lat,
      longitude: lng,
      address: "",
      neighborhood: "",
      city: "",
      canSubmit: true,
    };
    geocoder
      .reverse({ lat: lat, lon: lng })
      .then((res) => {
        newState.neighborhood = res[0].extra.neighborhood;
        newState.address = res[0].formattedAddress;
        newState.city = res[0].city;
        console.log("new state on loc update ", newState);
        this.setState({ ...newState });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeZoom = (zoom) => {
    this.setState({ zoom });
  };

  render() {
    const { language } = this.props;
    const { latitude, longitude, address, zoom } = this.state;
    const hasError = this.state.canSubmit === false;

    return (
      <Form>
        <FormGroup label="add location">
          <PARAGRAPH
            css={css`
              margin-bottom: 30px;
            `}
            dangerouslySetInnerHTML={{
              __html: `Адрес:<br>${address}`,
            }}
          ></PARAGRAPH>
          <div>
            <div>
              <MapPicker
                defaultLocation={DefaultPosition}
                zoom={zoom}
                style={{ height: "300px" }}
                onChangeLocation={this.handleChangeLocation}
                onChangeZoom={this.handleChangeZoom}
                apiKey="AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0"
              />
            </div>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

export default Step2;
