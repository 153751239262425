import React, { Component } from 'react';
import FileUploader from '../elements/FileUploader';

// TITLE AND DESCRIPTION OF URBID

export default class Step2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrls: [],
      isUploading: false,
      canSubmit: 'not clear yet'
    };

    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    // this is called after user presses NEXT
    // so in that case, update store
    let isDataValid = false;

    if (this.state.imageUrls.length > 0) {
      this.props.updateStore({
        ...this.state,
        savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
      });
      isDataValid = true;
    } else {
      this.setState({ canSubmit: false });
      isDataValid = false;
    }

    return isDataValid;
  }

  _onUpload(filenames) {
    this.setState({
      imageUrls: filenames
    });
  }
  onUpload = this._onUpload.bind(this);

  render() {
    const savingCls = this.state.canSubmit ? 'no-error' : 'has-error';
    const { proposalId } = this.props;

    return (
      <div className={`urb-add__form__step step--2 ${savingCls}`}>
        <form>
          { proposalId &&
            <FileUploader
              postType='proposals'
              postId={proposalId}
              onUpload={this.onUpload}
          /> }
        </form>
        <span className = "error-message">Please upload at least one image.</span>
      </div>
    )
  }
}
