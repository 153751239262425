import React, { Component } from 'react';
import { firestore } from '../../firebase.js';
import StepZilla from "react-stepzilla";

import withUser from '../withUser';

import Step1 from '../forms/_AddProposalStep1';
import Step2 from '../forms/_AddProposalStep2';
import Step3 from '../forms/_AddProposalStep3';
import Step4 from '../forms/_AddProposalStep4';

const defVals = {
    title: {
      bg: null,
      en: null
    },
    description: {
      bg: null,
      en: null
    },
    projectInfo: {
      budget: null,
      docs: [
        {
          docName: null,
          docUrl: null
        }
      ]
    },
    imageUrls: [],
    countVotes: 0,
    countComments: 0,
    urbidId: null,
    user: { },
    sponsors: [
      {
        sponsorName: '',
        sponsorLogo: '', // image url
        sponsorPledge: 0 // amount (optional)
      }
    ],
    createdAt: null
  }

class AddProposal extends Component {

  state = {
    canSubmit: false,
    currentStep: 0,
    proposalId: null
  }

  sampleStore = defVals;

  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {

    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    }

    if (this.state.currentStep === 0) {
      firestore.collection('proposals')
        .add({})
        .then((docRef) => {
          this.setState({ proposalId: docRef.id });
          console.log('created a proposal with id', docRef.id);
        })
        .catch(function(error) {
          console.error("Error adding document: ", error);
        });
    }
    if (this.state.currentStep === 2) { this.uploadToFB(); }
  }

  uploadToFB() {
    const { proposalId } = this.state;
    const { uid, displayName, photoUrl } = this.props.user || {};
    const { urbidId } = this.props;
    const createdAt = new Date();

    const proposal = {
      title: this.sampleStore.title,
      description: this.sampleStore.description,
      imageUrls: this.sampleStore.imageUrls,
      urbidId: urbidId,
      projectInfo: {
        budget: Number(this.sampleStore.projectInfo.budget),
        docs: [
          {
            docName: this.sampleStore.projectInfo.docs[0].docName,
            docUrl: this.sampleStore.projectInfo.docs[0].docUrl
          }
        ]
      },
      countVotes: 0,
      countComments: 0,
      user: { uid, displayName, photoUrl },
      createdAt: createdAt,
      sponsors: [
        {
          sponsorName: '',
          sponsorLogo: '', // image url
          sponsorPledge: 0 // amount (optional)
        }
      ]
    }

    // update the proposal we already created when uploading the pics
    firestore.doc(`proposals/${proposalId}`).update(proposal);
    this.sampleStore = defVals;
  }

  render() {
    const steps =
    [
      {name: 'Име и описание', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'Информация и документи за проекта', component: <Step3 proposalId={this.state.proposalId} getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'Визуализации', component: <Step2 proposalId={this.state.proposalId} getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />},
      {name: 'Благодарим!', component: <Step4 getStore={() => (this.getStore())} updateStore={(u) => {this.updateStore(u)}} />}
    ]

    console.log("add proposal props", this.props);

    return(
      <div className="urb-add__form">
        <StepZilla
          steps={steps}
          preventEnterSubmission={true}
          nextTextOnFinalActionStep='YAS'
          hocValidationAppliedTo={[3]}
          prevBtnOnLastStep={false}
          stepsNavigation={false}
          onStepChange={step => {
              this.setState({ currentStep: step });
              window.sessionStorage.setItem("step", step);
            }
          }
        />
      </div>
    )
  }
}
export default withUser(AddProposal);
