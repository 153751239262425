/** @jsx jsx */

import React from "react";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { setMargin, setPadding } from "../common/spacing";

export const Input = styled("input")`
  height: 60px;
  border-radius: 3px;
  border: 1px solid #ebebeb;
  background-color: #ebebeb;
  -webkit-transition: 0.1s all;
  transition: 0.1s all;
  font-size: 1rem;
  padding: 15px;
  margin: 0 0 0.6rem 0;
  font-size: 1em;
  font-family: "Intro Regular", sans-serif;
  width: 100%;

  &:hover,
  &:focus {
    outline: none !important;
  }

  &:focus {
    border: 1px solid grey;
  }
`;

export const Textarea = styled("textarea")`
  min-height: 120px;
  border-radius: 3px;
  border: 2px solid #ebebeb;
  background-color: #ebebeb;
  -webkit-transition: 0.1s all;
  transition: 0.1s all;
  font-size: 1rem;
  padding: 15px;
  margin: 0 0 0.6rem 0;
  font-size: 1em;
  font-family: "Intro Regular", sans-serif;
  width: 100%;
  &:hover,
  &:focus {
    outline: none !important;
  }

  &:focus {
    border: 1px solid grey;
  }
`;

export const Form = styled("form")`
  flex-basis: 0;
  flex-grow: 1;
`;

export const FormStep = styled("div")`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const FormGroup = ({
  label,
  hasError,
  errorMessage,
  children,
  displayLabel,
}) => (
  <div
    css={css`
      ${setMargin(["bottom"], "sm")}
      position: relative;
    `}
  >
    <label
      css={css`
        ${setMargin(["bottom"], "xs")}
        color: #7c7c7c;
        font-family: "Intro Regular", sans-serif;
        font-size: 0.85em;
        display: block;
        display: ${displayLabel ? "block" : "none"};
      `}
    >
      {label}
    </label>
    <div
      css={css`
        position: relative;
      `}
    >
      {children}
      <span
        css={css`
          align-items: center;
          background-color: #b11b1b;
          color: white;
          font-weight: 500;
          padding: 0px 10px;
          position: absolute;
          height: 24px;
          bottom: -20px;
          left: 50%;
          border-radius: 3px;
          -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
          opacity: ${hasError ? 1 : 0};
          pointer-events: none;
          line-height: 24px;
          font-size: 14px;
          z-index: 30;

          &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #b11b1b;
            top: -6px;
            left: 50%;
            -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
          }
        `}
      >
        {errorMessage}
      </span>
    </div>
  </div>
);
