/** @jsx jsx */
import React, { Component }  from 'react';
import { jsx, css } from '@emotion/core';
import Shape from '../shapes/Shape';

import { Container } from '../common/layouts';
import { setPadding } from '../common/spacing';
import { colorUrbids, colorNominations, colorRealizations } from '../common/colors';

export const PageBanner = ({ imageUrl, imageUrlDesktop, children }) => (
  <div>
    <Container size='sm'>
        { children }
    </Container>
  </div>
);

const BannerText = ({ textColor, textBackground, children }) => (
  <div 
    css={css`
      flex-basis: 0;
      flex-grow: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      ${setPadding(['left', 'right'], 'sm')}
      ${setPadding(['top', 'bottom'], 'lg')}
      position: relative;
      color: ${textColor};
      background-color: ${textBackground};
    `}>
    <div css={css`
      max-width: 600px;
      position: relative;
      z-index: 5;
    `}>
      { children }
    </div>
  </div>
)

const BannerShape = ({ shape, shapeColor, shapeBackground }) => (
  <div 
    css={css`
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vw - 30px);
      position: relative;

      @media (min-width: 768px) {
        min-width: 420px;
        min-height: 0px;
      }
    `}>
    <div 
      css={css`
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        ${setPadding('all', 'lg')}

        @media (min-width: 1200px) {
          padding: 90px;
        }

        svg {
          fill: ${shapeColor};
        }
      `}>
      <Shape />
    </div>
    
    <div 
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${shapeBackground};
      `}>
    </div>
  </div>
);

export const BannerSmall = ({ theme, accent, children }) => {
  return (
    <Container
      css={css`
        @media (max-width: 767px) {
          padding: 0px;
        }`}>
      <div css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background: ${colorNominations};
        ${setPadding(['left', 'right'], 'sm')}
        ${setPadding(['top', 'bottom'], 'lg')}
        @media (min-width: 768px) {
          flex-direction: row;
          height: 280px;
          ${setPadding('all', 'lg')}
        }
      `}>
        <div css={css`
          width: 100%;
          max-width: 600px;
        `}>
          { children }
        </div>
      </div>
    </Container>
  )
}

const Banner = ({ theme, accent, children }) => {

  const getAccent = () => {
    if (accent === 'nominations') return colorNominations;
    if (accent === 'urbids') return colorUrbids;
    if (accent === 'realizations') return colorRealizations;
    return;
  };

  const getColors = () => {
    let shapeBackground, textBackground, textColor;
    let accentColor = getAccent();

    switch (theme) {
    case 'dark': 
      shapeBackground = '#000000';
      accentColor = getAccent();
      textBackground = '#353535';
      textColor = 'white';
      break;
    case 'nominations': 
      shapeBackground = colorNominations;
      textBackground = colorNominations;
      textColor = 'black';
      break;
    case 'urbids': 
      shapeBackground = colorUrbids;
      textBackground = colorUrbids;
      textColor = 'black';
      break;
    default:
      break;
    }

    return { shapeBackground, accentColor, textBackground, textColor };
  }

  return (
    <Container
      css={css`
        @media (max-width: 767px) {
          padding: 0px;
        }`}>
      <div 
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow: hidden;

          @media (min-width: 768px) {
            flex-direction: row;
            height: 420px;
          }
        `}>
        <BannerShape 
          shapeColor={getColors().accentColor} 
          shapeBackground={getColors().shapeBackground} />
        <BannerText 
          textColor={getColors().textColor} 
          textBackground={getColors().textBackground}>
          { children }
        </BannerText>
      </div>
    </Container>
  );
}

export default Banner;
