/** @jsx jsx */
import { useContext, useState } from "react";
import { jsx, css } from "@emotion/core";
import Button from "../elements/Button";
import { LanguageContext } from "../../providers/LanguageProvider";
import { UserContext } from "../../providers/UserProvider";
import { prompts } from "../../language/content";
import InfoWindow from "../elements/InfoWindow";
import SignInOrSignUpPrompt from "../auth/SignInOrSignUpPrompt";
import { setPadding } from "../common/spacing";
import { H4, PARAGRAPH } from "../common/typography";

const RegisterAsDesignerPrompt = ({ color }) => {
  const user = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const [isOpen, toggle] = useState(false);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        a {
          margin-right: 30px;
        }
        button {
          max-width: 360px;
          margin-right: 30px;
          font-size: 18px;
        }
      `}
    >
      {!user && <SignInOrSignUpPrompt whySignUp="addProposal" />}
      {user && (
        <Button
          style="accent"
          color={color || "realizations"}
          onClick={() => {
            toggle(true);
          }}
        >
          {prompts.registerAsDesigner[language]}
        </Button>
      )}
      <InfoWindow
        isOpen={isOpen}
        onCloseClick={() => {
          toggle(false);
        }}
        color={color || "realizations"}
      >
        <H4>{prompts.registerAsDesigner.heading[language]}</H4>
        <PARAGRAPH>{prompts.registerAsDesigner.paragraph[language]}</PARAGRAPH>
      </InfoWindow>
    </div>
  );
};

export default RegisterAsDesignerPrompt;
