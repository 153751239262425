/** @jsx jsx */
import { useContext, useState } from "react";
import { jsx, css } from "@emotion/core";
import Button from "../../elements/Button";
import { LanguageContext } from "../../../providers/LanguageProvider";
import { UserContext } from "../../../providers/UserProvider";
import { prompts, labels } from "../../../language/content";
import InfoWindow from "../../elements/InfoWindow";
import SignInOrSignUpPrompt from "../../auth/SignInOrSignUpPrompt";
import { setPadding, setMargin } from "../../common/spacing";
import { H4, H5, PARAGRAPH } from "../../common/typography";
import { colorUrbids } from "../../common/colors";

import moment from "moment";
import "moment/locale/bg";

const sendFirstProposal = {
  bg: "изпрати първото предложение",
  en: "be the first to send a proposal",
};

const sendProposal = {
  bg: "изпрати предложение",
  en: "send a proposal",
};

const expired = {
  bg: "изтекъл",
  en: "completed",
};

// export const Deadline = () => <CAPTION></CAPTION>;

export const SendProposalInline = ({ deadline }) => {
  const user = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const [isOpen, toggle] = useState(false);

  const isPast = deadline
    ? new Date().setHours(0, 0, 0, 0) / 1000 > deadline.seconds
    : true;

  return (
    <div>
      {!isPast ? (
        <button
          onClick={() => {
            toggle(true);
          }}
          css={css`
            border-bottom: 2px solid ${colorUrbids};
            ${setPadding(["bottom"], "xs")}
            font-size: 16px;
            transition: 0.1s all;
            margin-top: 30px;

            &:hover {
              cursor: pointer;
              border-color: black;
            }

            @media (min-width: 768px) {
              font-size: 22px;
            }
          `}
        >
          <H5>{sendFirstProposal[language]}</H5>
        </button>
      ) : null}

      <InfoWindow
        isOpen={isOpen}
        onCloseClick={() => {
          toggle(false);
        }}
        color="urbids"
      >
        {user && (
          <div>
            <H4>{prompts.registerAsDesigner.heading[language]}</H4>
            <PARAGRAPH>
              {prompts.registerAsDesigner.paragraph[language]}
            </PARAGRAPH>
          </div>
        )}
        {!user && <SignInOrSignUpPrompt whySignUp="addProposal" />}
      </InfoWindow>
    </div>
  );
};

const SendProposalPrompt = ({ deadline }) => {
  const user = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const [isOpen, toggle] = useState(false);

  const isPast = deadline
    ? new Date().setHours(0, 0, 0, 0) / 1000 > deadline.seconds
    : true;

  moment.locale(language);
  const deadlineCopy = deadline;

  const formattedDeadline = deadline
    ? moment(deadlineCopy.toDate()).format("LL")
    : "";

  //console.log(deadline, formattedDeadline, isPast);
  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        ${setMargin(["top"], "lg")}

        @media (max-width: 767px) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;

          button {
            max-width: 360px;
            margin-right: 30px;
            margin-bottom: 0px;
          }
          @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            button {
              margin-right: 0px;
              margin-bottom: 15px;
              width: 100%;
              font-size: 16px;
            }
          }
        `}
      >
        {!isPast && (
          <Button
            style="accent"
            color="urbids"
            onClick={() => {
              toggle(true);
            }}
          >
            {sendProposal[language]}
          </Button>
        )}
        <div>
          <PARAGRAPH>{labels.deadline[language]}</PARAGRAPH>
          <PARAGRAPH>
            {formattedDeadline} {isPast && "(" + expired[language] + ")"}
          </PARAGRAPH>
        </div>
      </div>
      <InfoWindow
        isOpen={isOpen}
        onCloseClick={() => {
          toggle(false);
        }}
        color="urbids"
      >
        {user && (
          <div>
            <H4>{prompts.registerAsDesigner.heading[language]}</H4>
            <PARAGRAPH>
              {prompts.registerAsDesigner.paragraph[language]}
            </PARAGRAPH>
          </div>
        )}
        {!user && <SignInOrSignUpPrompt whySignUp="addProposal" />}
      </InfoWindow>
    </div>
  );
};

export default SendProposalPrompt;
