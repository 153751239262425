/** @jsx jsx */
import React, { useContext } from "react";
import { jsx, css } from "@emotion/core";
import Button from "../elements/Button";
import { Input, Form, FormGroup, Textarea } from "../forms/Form";
import LogoEuro from "react-ionicons/lib/LogoEuro";
import { firestore } from "../../firebase.js"; // record transaction
import { TITLE_SMALL, TITLE_MEDIUM } from "../common/typography";
import { nav } from "../../language/content";
import { LanguageContext } from "../../providers/LanguageProvider";
import { setPadding, setMargin } from "../common/spacing";
import Scrollbars from "react-custom-scrollbars";

const SupportModal = ({
  color,
  children,
  paid,
  paymentValue,
  handlePaymentValueChange,
  paying,
  handlePaymentNameChange,
  handlePaymentNoteChange,
  onCloseClick,
}) => {
  const { language } = useContext(LanguageContext);

  const close = {
    bg: "затвори",
    en: "close",
  };
  const thankyou = {
    bg:
      "Благодарим ти за подкрепата! Моля, въведи сумата, която искаш да дариш, име и бележка по желание.",
    en:
      "Thank you for your support! Please, enter the sum you would like to donate, and optionally, your name and your note.",
  };
  const thankyoupaid = {
    bg: "Благодарим ти! Дарението ти ще бъде отразено в списъка в страницата.",
    en: "Thank you! Your donation will be added to the list below.",
  };

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.45);
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          background-color: #d9d9d9;
          width: 100%;
          max-width: 450px;
          color: #212121;

          @media (max-width: 767px) {
            max-width: calc(100vw - 40px);
          }
        `}
      >
        <div
          css={css`
            ${setPadding(["left", "right", "top"], "sm")}
          `}
        >
          <Button
            onClick={onCloseClick}
            color={color}
            style="regular"
            icon="close"
          >
            {close[language]}
          </Button>
        </div>
        {!paid && (
          <Scrollbars
            autoHide={true}
            style={{ height: window.innerHeight - 120 }}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                ${setPadding(["left", "right"], "sm")}
                ${setPadding(["top"], "md")}
              `}
            >
              <TITLE_SMALL>{thankyou[language]}</TITLE_SMALL>
            </div>
            <div
              css={css`
                ${setPadding("all", "sm")}
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;

                  input {
                    font-size: 24px;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    ${setMargin(["bottom"], "sm")}
                  `}
                >
                  <Input
                    type="number"
                    name="paymentValue"
                    placeholder={50}
                    value={paymentValue}
                    onChange={handlePaymentValueChange}
                  />

                  <div
                    css={css`
                      display: flex;
                      align-items: center;

                      svg {
                        min-width: 30px;
                        margin-left: 60px;
                      }
                    `}
                  >
                    <LogoEuro fontSize="30px" color="#212121" />
                    <TITLE_SMALL>EUR</TITLE_SMALL>
                  </div>
                </div>
              </div>
              <Form>
                <FormGroup displayLabel={true} label="име (по желание)">
                  <Input
                    type="text"
                    name="paymentName"
                    onChange={handlePaymentNameChange}
                  />
                </FormGroup>
                <FormGroup
                  displayLabel={true}
                  label="бележка към дарението (по желание)"
                >
                  <Textarea
                    type="text"
                    name="paymentNote"
                    onChange={handlePaymentNoteChange}
                  />
                </FormGroup>
              </Form>
            </div>
            <div
              css={css`
                ${setPadding("all", "sm")}
                border-top: 1px solid rgba(255,255,255,0.45);
              `}
            >
              {children}
            </div>
          </Scrollbars>
        )}
        {paid && (
          <div
            css={css`
              height: calc(100vh - 120px);
            `}
          >
            <div
              css={css`
                ${setPadding(["left", "right"], "sm")}
                ${setPadding(
                  ["top", "bottom"],
                  "md"
                )}
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
              `}
            >
              <TITLE_MEDIUM
                css={css`
                  margin-bottom: 30px;
                `}
              >
                {thankyoupaid[language]}
              </TITLE_MEDIUM>
              <Button style="accent" color={color} onClick={onCloseClick}>
                обратно към проекта
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportModal;
