import React, { Component } from 'react';
import _ from 'lodash';

// TITLE AND DESCRIPTION OF URBID

class AddProposalStep1 extends Component {

  state = {
    title: {
      bg: this.props.getStore().title.bg,
      en: this.props.getStore().title.en
    },
    description: {
      bg: this.props.getStore().description.bg,
      en: this.props.getStore().description.en
    }
  };

  _validateOnDemand = true; // this flag enables onBlur validation as user fills forms

  isValidated() {
    const userInput = this._grabUserInput(); // grab user entered vals
    let isDataValid = false;

    // if full validation passes then save to store and pass as valid
    if (this._validateAllData(userInput)) {
      console.log('is validated - update store');
      this.props.updateStore({
        ...userInput,
        savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
      });

      isDataValid = true;
    }
    else {
      console.log('update state');
      this.setState(userInput);
    }

    return isDataValid;
  }

  isValidated = this.isValidated.bind(this);

  validationCheck() {
    if (!this._validateOnDemand)
      return;

    // only update with actual input
    const userInput = this._grabUserInput();
    let _state = this.state;

    _.forOwn(userInput, (parentObj, parentKey) => {
      _.forOwn(parentObj, (val, key) => {
        if (val) {
          _state[parentKey][key] = val;
        }
      })
    });

    this.setState(_state);
  }
  validationCheck = this.validationCheck.bind(this);

  _validateAllData(data) {
    let isValid = true;

    _.forOwn(data, (parentObj, parentKey) => {
      _.forOwn(parentObj, (val, key) => {
        if ((val === null) || (val === '')) {
          isValid = false;
        }
      })
    });

    return isValid;
  }

  _validateData(data) {
    return  {
      title: {
        bg: (data.title.bg === null) || (data.title.bg !== ""),
        en: (data.title.en === null) || (data.title.en !== "")
      },
      description: {
        bg: (data.description.bg === null) || (data.description.bg !== ""),
        en: (data.description.en === null) || (data.description.en !== "")
      }
    }
  }

  _grabUserInput() {
    const userInput = {
      title: {
        bg: this.refs.title_bg.value,
        en: this.refs.title_en.value
      },
      description: {
        bg: this.refs.description_bg.value,
        en: this.refs.description_en.value
      }
    }

    return userInput;
  }

  render() {

    // explicit class assigning based on validation
    let notValidClasses = {};
    let _currentVals = this.state;

    _.forOwn(_currentVals, (parentObj, parentKey) => {
      notValidClasses[parentKey] = {};
      _.forOwn(parentObj, (val, key) => {
        notValidClasses[parentKey][key] = ((val === null) || (val)) ? 'no-error' : 'has-error';
      })
    });


    return (
      <div className="urb-add__form__step step--0">
        <form>
          <div className="urb-add__form__group">
            <label className="control-label">
              Име (BG & EN)
            </label>
            <div className="urb-add__form__fields">
              <div className={`urb-add__form__field ${notValidClasses.title.bg}`}>
                <input
                  ref="title_bg"
                  autoComplete="off"
                  type="text"
                  placeholder='Име'
                  className="form-control"
                  required
                  defaultValue={this.state.title && this.state.title.bg}
                  onBlur={this.validationCheck} />
                <span className="error-message">add title</span>
              </div>
              <div className={`urb-add__form__field ${notValidClasses.title.en}`}>
                <input
                  ref="title_en"
                  autoComplete="off"
                  type="text"
                  placeholder='Title'
                  className="form-control"
                  required
                  defaultValue={this.state.title && this.state.title.en}
                  onBlur={this.validationCheck} />
                <span className="error-message">add title</span>
              </div>
            </div>
          </div>
          <div className="urb-add__form__group">
            <label className="control-label">
              Описание (BG & EN)
            </label>
            <div className="urb-add__form__fields">
              <div className={`urb-add__form__field ${notValidClasses.description.bg}`}>
                <input
                  ref="description_bg"
                  autoComplete="off"
                  type="text"
                  placeholder='Описание'
                  className="form-control"
                  required
                  defaultValue={this.state.description && this.state.description.bg}
                  onBlur={this.validationCheck} />
                <span className="error-message">add description</span>
              </div>
              <div className={`urb-add__form__field ${notValidClasses.description.en}`}>
                <input
                  ref="description_en"
                  autoComplete="off"
                  type="text"
                  placeholder='Description'
                  className="form-control"
                  required
                  defaultValue={this.state.description && this.state.description.en}
                  onBlur={this.validationCheck} />
                <span className="error-message">add description</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default AddProposalStep1;
