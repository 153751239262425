/** @jsx jsx */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { LanguageContext } from "../../providers/LanguageProvider";
import Logo from "../elements/Logo";
import { Container } from "../common/layouts";
import { CAPTION, H4, H5, ICON } from "../common/typography";
import { setPadding, setMargin } from "../common/spacing";
import withLanguage from "../withLanguage";
import { footer, nav } from "../../language/content";

const LanguageToggle = ({ language, onClick }) => (
  <div
    css={css`
      ${setPadding(["top", "bottom"], "md")}
    `}
  >
    <button
      onClick={onClick}
      css={css`
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
          margin-top: -2px;
        }
      `}
    >
      <ICON icon="globe" size="18" />
      {language === "bg" ? "English" : "Български"}
    </button>
  </div>
);
// <LanguageToggle language={language} onClick={toggleLanguage}/>

const FooterLink = styled(Link)`
  font-family: "Intro Regular";
  font-size: 14px;
  line-height: 1.2em;
  color: #555555;
  margin-right: 30px;
  border-bottom: 1px solid #555555;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const Footer = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);

  return (
    <footer
      css={css`
        ${setPadding(["top", "bottom"], "lg")}
      `}
    >
      <Container>
        <Logo color="#555555" />
        <CAPTION
          css={css`
            max-width: 600px;
            ${setMargin(["top", "bottom"], "sm")}
          `}
          dangerouslySetInnerHTML={{ __html: footer.about[language] }}
        />
        <nav
          css={css`
            max-width: 600px;
            display: flex;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              min-width: 25%;
              width: 25%;
              ${setPadding(["top", "bottom"], "md")}

              @media (max-width: 767px) {
                width: 100%;
                min-width: 100%;
                ${setPadding(["top", "bottom"], "sm")}
              }
            `}
          >
            <FooterLink to="/pages/about-us">{nav.about[language]}</FooterLink>
          </div>
          <div
            css={css`
              min-width: 25%;
              width: 25%;
              ${setPadding(["top", "bottom"], "md")}

              @media (max-width: 767px) {
                width: 100%;
                min-width: 100%;
                ${setPadding(["top", "bottom"], "sm")}
              }
            `}
          >
            <FooterLink to="/pages/terms-of-use">
              {nav.termsOfUse[language]}
            </FooterLink>
          </div>
          <div
            css={css`
              min-width: 25%;
              width: 25%;
              ${setPadding(["top", "bottom"], "md")}

              @media (max-width: 767px) {
                width: 100%;
                min-width: 100%;
                ${setPadding(["top"], "sm")}
                ${setPadding(["bottom"], "md")}
              }
            `}
          >
            <FooterLink to="/pages/privacy-policy">
              {nav.privacyPolicy[language]}
            </FooterLink>
          </div>
          <div
            css={css`
              min-width: 25%;
              width: 25%;
              ${setPadding(["top", "bottom"], "md")}

              @media (max-width: 767px) {
                width: 100%;
                min-width: 100%;
                ${setPadding(["top", "bottom"], "sm")}
              }
            `}
          >
            <FooterLink to="/pages/support-us">
              {nav.supportUs[language]}
            </FooterLink>
          </div>
        </nav>
        <H4
          css={css`
            max-width: 600px;
            opacity: 0.55;
            ${setMargin(["bottom"], "md")}
          `}
          dangerouslySetInnerHTML={{ __html: footer.slogan[language] }}
        />
        <LanguageToggle language={language} onClick={toggleLanguage} />
      </Container>
    </footer>
  );
};

export default withLanguage(Footer);
