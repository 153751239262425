/** @jsx jsx */

import { useContext, useState } from "react";
import Authentication from "../auth/Authentication";
import { Link, withRouter } from "react-router-dom";
import { HamburgerSlider } from "react-animated-burgers";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { LanguageContext } from "../../providers/LanguageProvider";
import { ToastContext } from "../../providers/ToastProvider";
import { setPadding, setMargin } from "../common/spacing";
import { common, nav, toasts } from "../../language/content";
import Logo from "../elements/Logo";
import { Container } from "../common/layouts";
import { ICON, H5 } from "../common/typography";
import { colorNominations } from "../common/colors";

import split from "lodash/split";
import pull from "lodash/pull";
import includes from "lodash/includes";
import InfoWindow from "../elements/InfoWindow";

const NavLink = styled(Link)`
  ${setPadding(["top", "bottom"], "sm")}
  font-family: 'Intro Regular Alt';
  font-size: 16px;
  line-height: 1em;
  font-weight: 400;
  display: block;

  @media (min-width: 768px) {
    ${setPadding(["left", "right"], "xs")}
  }
`;

const Header = ({ pageTitle, previousPage, history, location, match }) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const toast = useContext(ToastContext);
  const { language } = useContext(LanguageContext);

  const getPageData = () => {
    let l = pull(split(location.pathname, "/"), "");
    let single = false;
    if (
      l.length >= 2 &&
      !includes(l, "add") &&
      !includes(l, "blog") &&
      !includes(l, "proposals") &&
      !includes(l, "realizations") &&
      !includes(l, "completed")
    ) {
      single = true;
    }
    return { single };
  };

  const support = {
    bg: "Подкрепи ни!",
    en: "Support us!",
  };

  getPageData();
  return (
    <header
      css={css`
        color: ${getPageData().single ? "white" : "black"};
        background-color: ${getPageData().single ? "#353535" : "transparent"};
        z-index: 20;
        position: relative;
      `}
    >
      <Link to="/support-us">
        <div
          css={css`
            position: absolute;
            top: 60px;
            right: -10px;
            transform: rotate(-15deg);
            transition: 0.5s all cubic-bezier(0.36, 0, 0.66, -0.56);
            height: 120px;
            width: 120px;
            border-radius: 60px;
            background: ${colorNominations};
            padding: 30px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media (min-width: 992px) {
              position: fixed;
              bottom: 30px;
              right: 30px;
              top: auto;
              height: 150px;
              width: 150px;
              border-radius: 75px;
            }

            &:hover {
              background-color: black;
              transform: rotate(-24deg);
            }
          `}
        >
          <H5
            css={css`
              margin-top: 10px;
            `}
            dangerouslySetInnerHTML={{ __html: support[language] }}
          />
        </div>
      </Link>
      <Container>
        <div
          css={css`
            height: 75px;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Link
              to="/"
              css={css`
                @media (min-width: 768px) {
                  margin-right: 24px;
                }
              `}
            >
              <Logo color={getPageData().single ? "white" : "black"} />
            </Link>
            <div
              css={css`
                @media (min-width: 768px) {
                  display: none;
                }
              `}
            >
              <HamburgerSlider
                barColor={getPageData().single ? "white" : "black"}
                buttonWidth={22}
                isActive={isMenuOpen}
                toggleButton={() => {
                  toggleMenu(true);
                }}
              />
            </div>
            <nav
              css={css`
                display: flex;
                @media (max-width: 767px) {
                  display: none;
                }
              `}
            >
              <NavLink to="/nominations">
                {common.nominations[language]}
              </NavLink>
              <NavLink to="/active">{common.urbids[language]}</NavLink>
              <NavLink to="/completed">{common.realizations[language]}</NavLink>
            </nav>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <NavLink
              css={css`
                margin-right: 24px;

                @media (max-width: 767px) {
                  display: none;
                }
              `}
              to="/blog"
            >
              {common.blog[language]}
            </NavLink>
            <a
              href="https://www.facebook.com/urbid.official/"
              target="_blank"
              css={css`
                margin-right: 10px;
                display: flex;
                align-items: center;

                @media (max-width: 767px) {
                  display: none;
                }
              `}
            >
              <ICON
                icon="facebook"
                color={`${getPageData().single ? "white" : "inherit"}`}
                size="22"
              />
            </a>
            <a
              href="https://www.instagram.com/urbid.official/"
              target="_blank"
              css={css`
                margin-right: 10px;
                display: flex;
                align-items: center;

                @media (max-width: 767px) {
                  display: none;
                }
              `}
            >
              <ICON
                icon="instagram"
                color={`${getPageData().single ? "white" : "inherit"}`}
                size="22"
              />
            </a>
            <a
              href="https://twitter.com/UrBid2"
              target="_blank"
              css={css`
                margin-right: 10px;
                display: flex;
                align-items: center;

                @media (max-width: 767px) {
                  display: none;
                }
              `}
            >
              <ICON
                icon="twitter"
                color={`${getPageData().single ? "white" : "inherit"}`}
                size="22"
              />
            </a>
            <Authentication />
          </div>
        </div>
        <InfoWindow
          isOpen={isMenuOpen}
          onCloseClick={() => {
            toggleMenu(false);
          }}
          color="nominations"
        >
          <div>
            <NavLink
              to="/nominations"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {common.nominations[language]}
            </NavLink>
            <NavLink
              to="/active"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {common.urbids[language]}
            </NavLink>
            <NavLink
              to="/completed"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {common.realizations[language]}
            </NavLink>
            <NavLink
              to="/blog"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {common.blog[language]}
            </NavLink>
          </div>
          <div
            css={css`
              ${setPadding(["top", "bottom"], "md")}
              border-top: 1px solid grey;
              ${setMargin(["top"], "md")}
              opacity: 0.65;
            `}
          >
            <NavLink
              to="/pages/about-us"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {nav.about[language]}
            </NavLink>
            <NavLink
              to="/pages/terms-of-use"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {nav.termsOfUse[language]}
            </NavLink>
            <NavLink
              to="/pages/privacy-policy"
              onClick={() => {
                toggleMenu(false);
              }}
            >
              {nav.privacyPolicy[language]}
            </NavLink>
          </div>
        </InfoWindow>
      </Container>
    </header>
  );
};

export default withRouter(Header);

/*

<div 
              css={css`
                @media (max-width: 767px) {
                  display: none;
                }
              `}>
              <Button 
                icon='plus'
                style='regular'
                color='nominations' 
                to={'/nominations/add'}>
                { prompts.addNomination[language] }
              </Button>
            </div>
*/
