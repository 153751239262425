
import React from 'react';

const emailTemplate = ({ displayName, imageUrl, url, title, address }) => (
  `<!doctype html>
  <html>
  <head>
  <meta name="viewport" content="width=device-width">
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <title>Urbid Approved Nomination</title>
  <style media="all" type="text/css">
  .header {
    display: block;
    text-align: center;
  }
  p, h4, h2 {
    color: black;
  }

  .nomination-text {
    display: block;
  }
  @media only screen and (max-width: 620px) {
    table[class=body] h1,
    table[class=body] h2,
    table[class=body] h3,
    table[class=body] h4 {
      font-weight: 600 !important;
    }
    table[class=body] h1 {
      font-size: 22px !important;
    }
    table[class=body] h2 {
      font-size: 18px !important;
    }
    table[class=body] h3 {
      font-size: 16px !important;
    }
    table[class=body] .content,
    table[class=body] .wrapper {
      padding: 10px !important;
    }
    table[class=body] .container {
      padding: 0 !important;
      width: 100% !important;
    }
    table[class=body] .btn table,
    table[class=body] .btn a {
      width: 100% !important;
    }
  }
  </style>
  </head>
  
  <body style="margin: 0; font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; font-size: 14px; height: 100% !important; line-height: 1.6em; -webkit-font-smoothing: antialiased; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; width: 100% !important; background-color: #f6f6f6;">
  
  <table class="body" style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #f6f6f6;" width="100%" bgcolor="#f6f6f6">
    <tr>
      <td style="box-sizing: border-box; font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;" valign="top"></td>
      <td class="container" style="box-sizing: border-box; font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top; display: block; Margin: 0 auto !important; max-width: 580px; padding: 10px; width: 580px;" width="580" valign="top">
        <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
  <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">Твоята номинация е одобрена!</span>
  <div class="header" style="box-sizing: border-box; margin-top: 20px; width: 100%; background-color: #00cc65; height: 90px; position: relative;">
    <img src="https://zeroattentionspan.net/urbid-logo-white.png" alt="Urbid Logo" style="-ms-interpolation-mode: bicubic; width: 90px; height: 60px; margin-top: 15px; ">
  </div>
  <div class="main" style="box-sizing: border-box; background: #ffffff; border: 1px solid #e9e9e9; border-radius: 3px; width: 100%; padding: 30px 30px; font-size: 16px;">
    <h2 style="color: #111111 !important; font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: 500; line-height: 1.4em; margin: 0; font-size: 18px; text-align: center; margin-bottom: 30px;">Твоята номинация е одобрена!</h2>
    <p style="font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">
      ${displayName}, благодарим ти, че ни изпрати номинация. Вече можеш да я видиш в 
      платформата, да гласуваш за нея и да я изпратиш на приятели.
    </p>
    <div class="nomination" style="box-sizing: border-box; display: flex;">
      <div class="nomination-img" style="box-sizing: border-box; min-width: 50%; background-color: pink; min-height: 160px; background-image: url('${imageUrl}');">
  
      </div>
      <div class="nomination-text" style="box-sizing: border-box; padding-left: 15px;">
        <div style="box-sizing: border-box;">
          <h4 style="font-size: 16px; text-align: left; color: #111111 !important; font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: 500; line-height: 1.4em; margin: 0; margin-bottom: 15px;">${title}</h4>
          <small style="font-size: 12px; opacity: 0.65; display: block; margin-bottom: 30px;">${address}</small>
        </div>
        <a class="urbid-link" href="${url}" style="box-sizing: border-box; color: black; text-decoration: none; font-weight: 500; border-bottom: 2px solid #00cc65; padding: 3px; font-size: 14px; text-align: center; width: 100px;">Виж в Urbid</a>
      </div>
    </div>
  </div>
  <div class="footer" style="box-sizing: border-box; clear: both; width: 100%;">
    <table style="box-sizing: border-box; border-collapse: separate !important; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; color: #999999; font-size: 12px;" width="100%">
      <tr style="color: #999999; font-size: 12px;">
        <td class="align-center" style="box-sizing: border-box; font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; vertical-align: top; font-size: 12px; color: #999999; text-align: center; padding: 20px 0;" valign="top" align="center">
          <p style="font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-weight: normal; margin: 0; margin-bottom: 15px; color: #999999; font-size: 12px;"><div class="urbid-follow" style="box-sizing: border-box; color: #999999; font-size: 12px;">
    <small style="opacity: 0.65; display: block; color: #999999; font-size: 12px;">Последвай ни:</small>
    <div class="urbid-follow__links" style="box-sizing: border-box; color: #999999; font-size: 12px; display: flex; align-items: center; justify-content: center;">
      <a href="https://www.facebook.com/urbid.official/" style="box-sizing: border-box; text-decoration: none; font-weight: 500; color: #999999; font-size: 12px; margin: 0px 5px;">Facebook</a>
      <a href="https://www.instagram.com/urbid.official/" style="box-sizing: border-box; text-decoration: none; font-weight: 500; color: #999999; font-size: 12px; margin: 0px 5px;">Instagram</a>
      <a href="https://twitter.com/UrBid2" style="box-sizing: border-box; text-decoration: none; font-weight: 500; color: #999999; font-size: 12px; margin: 0px 5px;">Twitter</a>
    </div>
  </div></p>
        </td>
      </tr>
    </table>
  </div></div>
      </td>
      <td style="box-sizing: border-box; font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px; vertical-align: top;" valign="top"></td>
    </tr>
  </table>
  
  </body>
  </html>  
  `
);

export default emailTemplate;