/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import StepZilla from "react-stepzilla";
import { firestore } from "../../firebase.js";
import withUser from "../withUser";
import withLanguage from "../withLanguage";
import {
  Container,
  Section,
  Row,
  Half,
  Full,
  Third,
  TwoThirds,
} from "../common/layouts";
import { H3, PARAGRAPH, ICON, H5 } from "../common/typography";
import { colorNominations } from "../common/colors";
import { setMargin } from "../common/spacing";
import Step1 from "../forms/_AddNominationStep1";
import Step2 from "../forms/_AddNominationStep2";
import Step3 from "../forms/_AddNominationStep3";
import Step4 from "../forms/_AddNominationStep4";
import Button from "../elements/Button";
import { NextButton } from "../elements/Links";

import { addNomination } from "../../language/content";
import { transliterate as tr, slugify } from "transliteration";

class UrbidStepZilla extends StepZilla {
  next = this.next.bind(this);
  render() {
    const { props } = this;
    const isLastStep = props.nextButtonText === "Next";
    const { nextStepText } = this.getPrevNextBtnLayout(this.state.compState);

    // clone the step component dynamically and tag it as activeComponent so we can validate it on next. also bind the jumpToStep piping method
    const cloneExtensions = {
      jumpToStep: (t) => {
        this.jumpToStep(t);
      },
    };

    const componentPointer = this.props.steps[this.state.compState].component;

    // can only update refs if its a regular React component (not a pure component), so lets check that
    if (
      componentPointer instanceof Component ||
      (componentPointer.type &&
        componentPointer.type.prototype instanceof Component)
    ) {
      // unit test deteceted that instanceof Component can be in either of these locations so test both (not sure why this is the case)
      cloneExtensions.ref = "activeComponent";
    }

    const compToRender = React.cloneElement(componentPointer, cloneExtensions);
    return (
      <Row>
        <TwoThirds>
          <div
            className="multi-step"
            onKeyDown={(evt) => {
              this.handleKeyDown(evt);
            }}
          >
            {compToRender}
          </div>
        </TwoThirds>
        <Third
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
          `}
        >
          {!isLastStep && (
            <NextButton color={colorNominations} onClick={this.next.bind(this)}>
              {nextStepText}
            </NextButton>
          )}
        </Third>
      </Row>
    );
  }
}

const defVals = {
  title: "",
  description: "",
  latitude: 42.7,
  longitude: 22.23,
  address: "",
  neighborhood: "",
  city: "",
  imageUrls: [],
  nominationId: null,
  slug: "",
};

class AddNominationPage extends Component {
  state = {
    currentStep: 0,
  };

  sampleStore = defVals;

  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    };

    if (this.state.currentStep === 0) {
      firestore
        .collection("nominations")
        .add({})
        .then((docRef) => {
          this.setState({ nominationId: docRef.id });
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }

    if (this.state.currentStep === 2) {
      this.uploadToFB();
    }
  }

  uploadToFB() {
    const { nominationId } = this.state;
    const { uid, displayName, photoUrl, email } = this.props.user || {};

    let nomination = {
      title: this.sampleStore.title,
      description: this.sampleStore.description, // not sure
      address: {
        formattedAddress: this.sampleStore.address,
        neighborhood: this.sampleStore.neighborhood,
        city: this.sampleStore.city,
        position: {
          lat: this.sampleStore.latitude,
          lng: this.sampleStore.longitude,
        },
      },
      tags: [],
      imageUrls: this.sampleStore.imageUrls,
      countVotes: 0,
      countComments: 0,
      user: { uid, displayName, photoUrl, email },
      createdAt: new Date(),
      status: "pending",
    };

    nomination.slug = slugify(nomination.title);
    firestore
      .collection("nominations")
      .where("slug", "==", nomination.slug)
      .get()
      .then((qs) => {
        if (!qs.empty) {
          nomination.slug += "-2";
        }
        firestore.doc(`nominations/${nominationId}`).update(nomination);
        this.sampleStore = defVals;
      });
  }

  render() {
    const { language } = this.props;
    const { currentStep } = this.state;

    const steps = [
      {
        name: addNomination.step1.stepName[language],
        component: (
          <Step1
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
            language={language}
          />
        ),
      },
      {
        name: addNomination.step2.stepName[language],
        component: (
          <Step2
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
            language={language}
          />
        ),
      },
      {
        name: addNomination.step3.stepName[language],
        component: (
          <Step3
            //nominationId="7Wy43lmuTkIgQ7Q6L5qg"
            nominationId={this.state.nominationId}
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
            language={language}
          />
        ),
      },
      {
        name: addNomination.step4.stepName[language],
        showNavigation: false,
        component: (
          <Step4
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
            language={language}
          />
        ),
      },
    ];

    // fix this
    const nextText = [
      addNomination.step1.next[language],
      addNomination.step2.next[language],
      addNomination.step3.next[language],
    ];
    const prevText = language === "bg" ? "обратно" : "back";

    return (
      <div
        css={css`
          ol.progtrckr {
            display: none;
          }

          input[type="file"] {
            height: 200px;
            width: 100%;

            &:hover,
            &:focus {
              outline: none !important;
            }
          }
        `}
      >
        <Section>
          <Container
            css={css`
              ${setMargin(["top", "bottom"], "lg")}
            `}
          >
            <Row>
              <Full>
                <H3
                  css={css`
                    ${setMargin(["bottom"], "md")}
                  `}
                >
                  {currentStep !== steps.length - 1
                    ? addNomination.title[language]
                    : addNomination.thankYou[language]}
                </H3>
              </Full>
            </Row>

            <Row>
              <Full>
                {currentStep !== steps.length - 1 && (
                  <ul
                    css={css`
                      display: flex;
                      align-items: center;
                      list-style: none;
                      padding: 0px;
                      margin: 0px;
                      ${setMargin(["bottom"], "lg")}
                    `}
                  >
                    {steps.map((step, index) =>
                      index !== steps.length - 1 ? (
                        <li
                          css={css`
                            opacity: ${currentStep === index ? 1 : 0.75};
                            padding: 9px 0px;
                            margin-right: 24px;
                            border-bottom: 2px solid
                              ${currentStep === index
                                ? colorNominations
                                : "transparent"};
                          `}
                        >
                          {steps[index].name}
                        </li>
                      ) : null
                    )}
                  </ul>
                )}
              </Full>
            </Row>
            <UrbidStepZilla
              steps={steps}
              preventEnterSubmission={true}
              nextButtonText={nextText[this.state.currentStep]}
              prevButtonText={prevText}
              prevBtnOnLastStep={false}
              stepsNavigation={false}
              showNavOnLastStep={false}
              onStepChange={(step) => {
                this.setState({ currentStep: step });
                window.sessionStorage.setItem("step", step);
              }}
            />
          </Container>
        </Section>
      </div>
    );
  }
}

export default withUser(withLanguage(AddNominationPage));
