const s = {
  xs: {
    mobile: "6",
    desktop: "6",
  },
  sm: {
    mobile: "15",
    desktop: "15",
  },
  md: {
    mobile: "30",
    desktop: "30",
  },
  lg: {
    mobile: "60",
    desktop: "60",
  },
  xl: {
    mobile: "90",
    desktop: "120",
  },
};

export const setPadding = (dir, size) => {
  // dir is array ['top', 'bottom' ... ] or string 'all'
  let style = "";
  if (typeof dir === "object") {
    dir.forEach((pos) => {
      style += `padding-${pos}: ${
        s[size || "md"].mobile
      }px; @media (min-width: 768px) {padding-${pos}: ${
        s[size || "md"].desktop
      }px;}`;
    });
  } else if (dir === "all") {
    style = `padding: ${
      s[size || "md"].mobile
    }px; @media (min-width: 768px) {padding: ${s[size || "md"].desktop}px;}`;
  }

  return style;
};

export const setMargin = (dir, size) => {
  let style = "";
  if (typeof dir === "object") {
    dir.forEach((pos) => {
      style += `margin-${pos}: ${
        s[size || "md"].mobile
      }px; @media (min-width: 768px) {margin-${pos}: ${
        s[size || "md"].desktop
      }px;}`;
    });
  } else if (dir === "all") {
    style = `margin: ${
      s[size || "md"].mobile
    }px; @media (min-width: 768px) {margin: ${s[size || "md"].desktop}px;}`;
  }

  return style;
};
