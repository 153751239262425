import React from "react";
import { render } from "react-dom";

import "./index.scss";

import Application from "./components/Application";
import LanguageProvider from "./providers/LanguageProvider";
import UserProvider from "./providers/UserProvider";
import NominationsProvider from "./providers/NominationsProvider";
import RealizationsProvider from "./providers/RealizationsProvider";
import PendingNominationsProvider from "./providers/PendingNominationsProvider";
import UrbidsProvider from "./providers/UrbidsProvider";
import ProposalsProvider from "./providers/ProposalsProvider";
import PostsProvider from "./providers/PostsProvider";
import ToastProvider from "./providers/ToastProvider";
import { BrowserRouter as Router } from "react-router-dom";

render(
  <Router>
    <LanguageProvider>
      <UserProvider>
        <ToastProvider>
          <PendingNominationsProvider>
            <NominationsProvider>
              <RealizationsProvider>
                <ProposalsProvider>
                  <UrbidsProvider>
                    <PostsProvider>
                      <Application />
                    </PostsProvider>
                  </UrbidsProvider>
                </ProposalsProvider>
              </RealizationsProvider>
            </NominationsProvider>
          </PendingNominationsProvider>
        </ToastProvider>
      </UserProvider>
    </LanguageProvider>
  </Router>,
  document.getElementById("root")
);
