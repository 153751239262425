import React, { Component, createContext } from 'react';
import { firestore } from '../firebase.js';
import { collectIdsAndDocs } from '../utilities.js';
import orderBy from 'lodash/orderBy';

export const PostsContext = createContext();

class PostsProvider extends Component {
  state = {
    posts: []
  }

  componentDidMount = () => {
    firestore
      .collection('posts')
      .where('status', '==', 'active')
      .get()
      .then(snapshot => {
        const posts = orderBy(snapshot.docs.map(collectIdsAndDocs), "createdAt", "desc");
        this.setState({ posts });
      })
      .catch(function(error) {
        console.log("Error getting posts: ", error);
      });
  }

  render() {
    const { posts } = this.state;
    const { children } = this.props;
    return (
      <PostsContext.Provider value={ posts }>{children}</PostsContext.Provider>
    )
  }
}

export default PostsProvider;