
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

import { H2, H3, H4, CAPTION } from '../common/typography';
import { Container, Row, Third } from '../common/layouts';
import { setMargin, setPadding } from '../common/spacing';
import { getImageUrl } from '../../utilities.js';

const Posts = ({ posts, onToggleClick, onDeleteClick }) => (
  <Container>
    <Row>
      { posts && posts.map((post, index) => {
        console.log('post ', post.featuredImage);
        const imageUrl = getImageUrl(post.featuredImage, 'posts', post.id, 'md');
        console.log(imageUrl);
        return (
          <Third
            key={post.id}>
            <div css={css`
              background-color: #c8c8c8;
              ${setPadding('all', 'sm')}
              ${setMargin(['bottom'], 'md')}
            `}>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  ${setPadding(['top', 'bottom'], 'sm')}
                  border-bottom: 1px solid grey;
                `}>
                <button 
                  onClick={() => { onDeleteClick(post.id)}}>
                  delete
                </button>
                <button 
                  onClick={() => {onToggleClick(post.id, post.status)}}>
                  set to {(post.status === 'active') ? 'draft' : 'active'}
                </button>
              </div>
              <div>
                <div 
                  css={css`
                    background-image: url(${imageUrl});
                    background-size: cover;
                    background-position: center center;
                    height: 200px;
                    display: block;
                    ${setMargin(['bottom'], 'md')}
                  `} />
                <CAPTION>Заглавие: </CAPTION>
                <H4 
                  css={css`
                    ${setMargin(['bottom'], 'sm')}
                  `}>{ post.heading && (post.heading.bg || '(empty)') }</H4>

                <CAPTION>Title: </CAPTION>
                <H4>{ post.heading && (post.heading.en || '(empty)') }</H4>
              </div>
            </div>
          </Third>
        )
      }) }
    </Row>
  </Container>
);

export default Posts;