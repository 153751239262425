/** @jsx jsx */

import React, { useContext } from 'react';
import { jsx, css } from '@emotion/core';
import { withRouter, Link } from 'react-router-dom';
import { getImageUrl } from '../../utilities.js';
import Button from '../elements/Button';
import { PostsContext } from '../../providers/PostsProvider';
import { setPadding, setMargin } from '../common/spacing';
import { H2, H3, H4, CAPTION, PARAGRAPH } from '../common/typography';
import { Section, SectionHeader, Container, Half, Row } from '../common/layouts';
import { LanguageContext } from '../../providers/LanguageProvider.jsx';
import moment from 'moment';
import 'moment/locale/bg';
import { nav, common } from '../../language/content';

const PostPreview = ({ id, title, featuredImage, url, excerpt, createdAt }) => {
  const featuredImageUrl = getImageUrl(featuredImage, 'posts', id, 'md');
  const { language } = useContext(LanguageContext); 
  moment.locale(language);

  return (
    <div
      css={css`
        display: flex;
        ${setMargin(['bottom'], 'md')}
        ${setPadding(['bottom'], 'md')}
        border-bottom: 1px solid #c4c4c4;
        @media (max-width: 767px) {
          flex-direction: column;
        }
      `}>
      <Link 
        to={url}
        css={css`
          background-image: url(${featuredImageUrl});
          background-size: cover;
          background-position: center center;
          min-width: 50%;
          height: 360px;
          display: block;

          @media (max-width: 767px) {
            ${setMargin(['bottom'], 'sm')}
          }
        `} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (min-width: 768px) {
            ${setPadding(['left'], 'md')}
          }
        `}>
        <div
          css={css`
            @media (max-width: 767px) {
              ${setMargin(['bottom'], 'md')}
            }
          `}>
          <H4
            css={css`
              ${setMargin(['bottom'], 'sm')}
            `}>{title}</H4>
          <CAPTION
            css={css`
              ${setMargin(['bottom'], 'md')}
            `}>
            { moment(createdAt.toDate()).format('L') }
          </CAPTION>
          <PARAGRAPH>
            { excerpt.substring(0, 120) + '...' }
          </PARAGRAPH>
        </div>
        <div>
          <Button 
            to={url}
            style='regular'
            color='nominations'>
            { nav.readMore[language] }
          </Button>
        </div>
      </div>
    </div>
  );
}

const PostsPage = () => {
  const posts = useContext(PostsContext);
  const { language } = useContext(LanguageContext);
  moment.locale(language);

  return (
    <main>
      <Section>
        <SectionHeader>
          <H2>{ common.blog[language] }</H2>
        </SectionHeader>
        <Container>
          { posts && posts.map(post => (
            <PostPreview
              key={post.id}
              id={post.id}
              title={post.heading[language]}
              url={`/blog/${post.slug}`}
              featuredImage={post.featuredImage} 
              createdAt={post.createdAt}
              excerpt={post.paragraphs[0].text[language]} />
          ))}
        </Container>
      </Section>
    </main>
  )
};

export default PostsPage;