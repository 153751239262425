import React, { Component, createContext } from 'react';
import { firestore } from '../firebase.js';
import { collectIdsAndDocs } from '../utilities.js';
import orderBy from 'lodash/orderBy';

export const NominationsContext = createContext();

class NominationsProvider extends Component {
  state = {
    nominations: []
  }

  unsubscribeFromFirestore = null;

  componentDidMount = () => {
    this.unsubscribeFromFirestore = firestore.collection('nominations').where("status", "==", "active").onSnapshot(snapshot => {
      let nominations = snapshot.docs.map(collectIdsAndDocs);
      nominations = orderBy(nominations, "createdAt", "desc");
      this.setState({ nominations });
    });
  }

  componentWillUnmount = () => {
    this.unsubscribeFromFirestore();
  }

  render() {
    const { nominations } = this.state;
    const { children } = this.props;
    return (
      <NominationsContext.Provider value={ nominations }>{children}</NominationsContext.Provider>
    )
  }
}

export default NominationsProvider;
