/** @jsx jsx */
import React from 'react';
import moment from 'moment';
import 'moment/locale/bg';
import { firestore } from '../../firebase.js';
import { jsx, css } from '@emotion/core';

/* elements */
import UserThumbnail from '../elements/UserThumbnail';
import TagList from '../elements/TagList';
import Location from '../elements/Location';

import VoteButton from '../elements/VoteButton';
import MapSingleItem from '../elements/MapSingleItem';
import Detail from '../elements/Detail';
import LayoutSingle from '../modules/LayoutSingle';

/* utilities */
import { getImageUrl } from '../../utilities.js';
import withLanguage from '../withLanguage';

const Nomination = ({ id, title, description, address, countVotes, imageUrls, createdAt, tags, user, language }) => {

  // moment.locale('bg');
  // const dateOfSubmission = moment(createdAt.toDate()).format('LLLL');
  const docRef = firestore.collection('nominations').doc(id);
  const imageUrl = getImageUrl(imageUrls[0], 'nominations', id , 'sm');
  const { lat, lng } = address.position;

  return (
    <LayoutSingle>
      <LayoutSingle.Header  
        backToUrl={'/nominations'}
        title={title}
        topLeft={(
          <Location 
            color='#d9d9d9'
            address={address.formattedAddress}
            position={address.position} />
        )}
        bottomLeft={(
          <VoteButton 
            postType='nominations'
            docRef={docRef} 
            voteCount={countVotes} />
        )}
        right={(
          <MapSingleItem 
            lat={lat}
            lng={lng}
            imageUrl={imageUrl}
          />
        )}>
      </LayoutSingle.Header>
      <LayoutSingle.Content
        description={description}
        details={(
          <div>
            <Detail 
              label='Uploaded by: '>
              <UserThumbnail {...user} />
            </Detail>
            { (tags && (tags.length > 0)) && (
              <Detail 
                label='Tagged as: '>
                <TagList tags={tags} />
              </Detail>
            )}
          </div>
        )} />
      <LayoutSingle.Footer
        imageUrls={imageUrls}
        postType={'nominations'}
        postId={id} />
    </LayoutSingle>
  )
}

export default withLanguage(Nomination);
