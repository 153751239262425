/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import { firestore } from '../../firebase.js';
import { collectIdsAndDocs } from '../../utilities.js';
import withLanguage from '../withLanguage';

import { Container, Section, Row, Third, Half, Full, TwoThirds, SectionHeader } from '../common/layouts';
import { H3, H4, H5, PARAGRAPH } from '../common/typography';
import { setPadding, setMargin } from '../common/spacing';
import { lightgrey, darkgrey } from '../common/colors';
import BannerMobile from '../../assets/banner-mobile.jpg';
import SingleItemNavBar from '../modules/SingleItemNavBar';
import { nav } from '../../language/content';

class StaticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: '',
      paragraphs: ''
    }
  }

  componentDidMount() {
    firestore
      .collection('pages')
      .where("slug", "==", this.pageSlug)
      .get()
      .then(snapshot => {
        let page = snapshot.docs.map(collectIdsAndDocs)[0];
        this.setState({ ...page });
      })
      .catch(error => {
        console.log("Error getting documents: ", error);
      });
  }

  componentDidUpdate(prevProps) {
    const { match } = prevProps;
    if (this.pageSlug && (match.params.slug !== this.pageSlug)) {
      firestore
        .collection('pages')
        .where("slug", "==", this.pageSlug)
        .get()
        .then(snapshot => {
          let page = snapshot.docs.map(collectIdsAndDocs)[0];
          this.setState({ ...page });
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });
    };
  }

  get pageSlug() {
    const { match } = this.props;
    return match ? match.params.slug : null;
  }

  renderParagraph = (par, key) => {
    const { image, text, title } = par;
    const { language } = this.props;
    return (
      <div 
        key={key}
        css={css`
          ${setPadding(['top', 'bottom'], 'md')}
          border-bottom: 1px solid rgba(20,20,20,0.06);
          color: ${darkgrey};
        `}>
        {title && (
          <H4
            css={css`
              ${setMargin(['bottom'], 'sm')}
            `}>
            { title[language] }
          </H4>)}
        {text && <PARAGRAPH dangerouslySetInnerHTML={{ __html: text[language] }} />}
        <div 
          css={css`
            ${setPadding(['top', 'bottom'], 'md')}
            display: none;
            justify-content: center;

            img {
              width: 100%;
            }
          `}>
          <img src={BannerMobile} />
        </div>
      </div>
    )
  }

  render() {
    const { heading, paragraphs } = this.state;
    const { language } = this.props;
    return (
      <main>
        <Section
          css={css`
            ${setPadding(['top'], 'lg')}
            background-color: #353535;
            color: white;
          `}>
            <Container>
              <Row>
                <Full>
                  <SingleItemNavBar
                    backToText={nav.backToHome[language]}
                    url={`/`} />
                </Full>
              </Row>
            </Container>
            <SectionHeader>
              <H3>{ heading[language] }</H3>
            </SectionHeader>
          </Section>
          <Section>
            <Container>
              <div
                css={css`
                  max-width: 750px;
                `}>
                { paragraphs && paragraphs.map((par, i) => this.renderParagraph(par, `par--${i}`))}
              </div>
            </Container>
        </Section>
      </main>
    );
  }
};

export default withRouter(withLanguage(StaticPage));