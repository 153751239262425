/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import IosArrowRoundForward from 'react-ionicons/lib/IosArrowRoundForward';
import IosArrowRoundBack from 'react-ionicons/lib/IosArrowRoundBack';

export const NextArrow = ({ onClick }) => (
  <IosArrowRoundForward
    fontSize='48px'
    onClick={onClick}
    css={css`
      position: absolute;
      top: -30px;
      right: 0px;
      z-index: 20;
    `}
  />
);

export const PrevArrow = ({ onClick }) => (
  <IosArrowRoundBack
    fontSize='48px'
    onClick={onClick}
    css={css`
      position: absolute;
      top: -30px;
      left: 0px;
      z-index: 20;
    `}
  />
);