/** @jsx jsx */

import { jsx, css } from "@emotion/core";
import { Link } from "react-router-dom";
import { setPadding } from "../common/spacing";
import { CAPTION, ICON } from "../common/typography";
import { getColor } from "../../utilities";

const Button = ({ style, fill, color, onClick, to, icon, children }) => {
  const El = !to ? "button" : Link;
  const stylesRegular = `
      border-bottom: 2px solid ${getColor(color)};
      ${setPadding(["bottom"], "xs")}
      font-size: 14px;
      transition: 0.1s all;

      &:hover {
        cursor: pointer;
        border-color: black;
      }

      @media (min-width: 768px) {
        font-size: 16px;
      }
    `;

  const stylesAccent = `
      border: 2px solid ${getColor(color)};
      background-color: ${fill ? getColor(color) : "transparent"};
      ${setPadding(["left", "right"], "md")}
      height: 50px;
      font-size: 16px;
      line-height: 1em;
      transition: 0.2s all;

      &:hover {
        cursor: pointer;
        background-color: #212121;
        border-color: #212121;
        color: white;
      }

      @media (min-width: 768px) {
        font-size: 22px;
        ${setPadding(["left", "right"], "md")}
      }
    `;

  return (
    <El
      onClick={onClick}
      to={to}
      css={css`
        display: inline-flex;
        align-items: center;
        font-family: "Intro Regular Alt";
        color: inherit;
        ${style === "accent" ? stylesAccent : stylesRegular}
      `}
    >
      {icon && (
        <div
          css={css`
            margin-right: 10px;
          `}
        >
          <ICON icon={icon} color={getColor(color)} size="20" />
        </div>
      )}
      {children}
    </El>
  );
};

export default Button;
