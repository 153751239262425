/** @jsx jsx */
import React, { useContext } from "react";
import { jsx, css } from "@emotion/core";
import { LanguageContext } from "../../providers/LanguageProvider";
import {
  Container,
  Section,
  Row,
  Third,
  Half,
  SectionHeader,
} from "../common/layouts";
import { H2, H3, H4, H5, PARAGRAPH } from "../common/typography";
import { pageNotFound } from "../../language/content";
import { BannerDownloadApp } from "../banners/BannerNominations";

const DownloadAppPage = () => {
  const { language } = useContext(LanguageContext);
  return (
    <main>
      <Section>
        <BannerDownloadApp />
      </Section>
    </main>
  );
};

export default DownloadAppPage;
