/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { firestore, auth, storage } from '../firebase.js';
import { Redirect } from 'react-router-dom';
import withUser from './withUser';
import withLanguage from './withLanguage';
import { collectIdsAndDocs } from '../utilities.js';
import _ from 'lodash';
import { getImageUrl } from '../utilities.js';

import { Section, Container } from './common/layouts';
import { H3, H4, H5, PARAGRAPH } from './common/typography';
import { setPadding, setMargin } from './common/spacing';
import { common } from '../language/content';
import Card, { __Location, __Votes, __Comments, CardSectionAll } from './elements/Card';

const NominationCard = ({ title, id, address, imageUrls, countVotes, countComments }) => {
  const imageUrl = getImageUrl(imageUrls[0], 'nominations', id, 'md');

  return (
    <Card 
      url={`/nominations/${id}`}
      imageUrl={imageUrl}>
      <div
        css={css`
          @media (min-width: 768px) {
            min-height: 60px;
          }
        `}>
        <H5 css={css`
          ${setMargin(['bottom'], 'xs')}
        `}>
          { title }
        </H5>
        <__Location formattedAddress={`${address.neighborhood}, ${address.city}`} />
      </div>
      
      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${setPadding(['top'], 'sm')}
      `}>
        <__Votes countVotes={countVotes} />
        <__Comments countComments={countComments} />
      </div>
    </Card>
  )
}

class UserProfile extends Component {

  state = {
    displayName: '',
    nominationsPending: [],
    nominationsActive: []
  };

  imageInput = null;
  unsubscribeFromFirestore = null;
  nominationsActive = null;
  nominationsPending = null;

  get uid() {
    return auth.currentUser && auth.currentUser.uid;
  }

  get userRef() {
    var uId = this.uid;
    return firestore.doc('users/' + uId);
  }

  get file() {
    return this.imageInput && this.imageInput.files[0];
  }

  componentDidMount = () => {
    if (this.state.nominationsPending.length === 0) {
      this.getNominations();
    }
  }

  componentDidUpdate = () => {
    if (this.state.nominationsPending.length === 0) {
      this.getNominations();
    }
  }

  _deleteNomination = (id) => {
    firestore.collection("nomination").doc(id).delete().then(function() {
      let pendingNominations = this.state.pendingNominations;
      pendingNominations = _.remove(pendingNominations, nomination => (nomination.id !== id) );
      this.setState({ pendingNominations });
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  }
  deleteNomination = this._deleteNomination.bind(this);

  getNominations = () => {
    const { user } = this.props;
    user && firestore.collection('nominations').where("user.uid", "==", user.uid)
      .get()
      .then(snapshot => {
        let nominations = snapshot.docs.map(collectIdsAndDocs);

        let nominationsPending = _.remove(nominations, nomination => (nomination.status === 'pending'));
        let nominationsActive = nominations;
        this.setState({ nominationsPending, nominationsActive });
      })
      .catch(error => { console.error(error); });
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
    const { displayName } = this.state;

    if (displayName) {
      this.userRef.update({ displayName });
    }

    if (this.file) {
      storage
        .ref()
        .child('users')
        .child(this.uid)
        .child(this.file.name)
        .put(this.file)
        .then(response => response.ref.getDownloadURL() )
        .then(photoURL => this.userRef.update({ photoURL }))
    }
  }

  render() {
    const { user, language } = this.props || {};
    const { nominationsPending, nominationsActive } = this.state;

    if (!user) {
      return <Redirect to='/'  />
    }

    return(
      <main>
        <Section>
          <Container>
            <div>
              <div 
                css={css`
                  height: 90px;
                  width: 90px;
                  background-image: url(${user && user.photoUrl});
                  background-size: cover;
                  background-position: center center;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-bottom: 30px;
                `}>
              </div>
              <H3
                css={css`
                  margin-bottom: 15px;
                `}>{ user && user.displayName }</H3>
              <PARAGRAPH>{ user && user.email }</PARAGRAPH>
            </div>
          </Container>
        </Section>
        {( nominationsPending.length > 0 ) && (
          <Section>
            <Container>
              <H4
                css={css`
                  ${setMargin(['bottom'], 'sm')}`}>
                { common.pendingNominations[language] }
              </H4>
            </Container>
            <CardSectionAll>
              { nominationsPending.map(nom => (
                <NominationCard 
                  key={nom.id}
                  {...nom} />
              ))}
            </CardSectionAll>
          </Section>
        )}
        {( nominationsActive.length > 0 ) && (
          <Section>
            <Container>
              <H4
                css={css`
                  ${setMargin(['bottom'], 'sm')}`}>
                { common.activeNominations[language] }
              </H4>
            </Container>
            <CardSectionAll>
              { nominationsActive.map(nom => (
                <NominationCard 
                  key={nom.id}
                  {...nom} />
              ))}
            </CardSectionAll>
          </Section>
        )}
      </main>
    )
  }
}

export default withUser(withLanguage(UserProfile));

/*

<section className = "urb-user__profile">
        { user &&
        <div className = "urb-user__header">
          <div className="urb-user__header__img" style={imgStyle}>
          </div>
          <h3 className="urb-user__header__name">{ user && user.displayName }</h3>
          <small className="urb-user__header__email">{ user && user.email }</small>
        </div> }
        { user && <div className="urb-user__posts">
          <div className="urb-container-md">
            <h6 className="urb-user__posts__heading">{profile.myNominations[lang]}</h6>
          </div>
          <ul className="urb-user__nominations urb-user__nominations--pending">
            <div className="urb-container-md">
            <h5 className="urb-user__nominations__heading">{profile.pending[lang]}</h5>
            { nominationsPending && nominationsPending.map(nomination => {

              const imageName = nomination.imageUrls ? nomination.imageUrls[0] : null;
              const imageUrl = getFeaturedImageUrl(imageName, 'nominations', nomination.id, 'sm');
              const imageStyle = { backgroundImage: 'url(' + imageUrl + ')' };

              return (
                <div className="urb-user__nomination nomination--pending" key={`user--${user.uid}--${nomination.id}`}>
                  <div className="urb-user__nomination__img" style={imageStyle}>
                  </div>
                  <div className="urb-user__nomination__info">
                    <h5 className="urb-user__nomination__title">{nomination.title}</h5>

                  </div>

                </div>
              )
            })}
            </div>
          </ul>
          <ul className="urb-user__nominations urb-user__nominations--active">
          </ul>
        </div>}
      </section>

*/






