/** @jsx jsx */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { ICON, H5, CAPTION } from "../common/typography";
import { setPadding } from "../common/spacing";
import { getColor } from "../../utilities";
import { LanguageContext } from "../../providers/LanguageProvider";
import { nav } from "../../language/content";

const Button = styled("button")`
  color: inherit;
`;

export const BackToLink = ({ to, onClick, backToText, color }) => {
  const El = onClick ? Button : Link;
  const elProps = onClick ? { onClick: onClick } : { to: to };

  return (
    <El
      {...elProps}
      css={css`
        display: flex;
        align-items: center;
        transition: 0.2s all;

        svg {
          margin-right: 10px;
        }

        &:hover {
          cursor: pointer;
        }
      `}
    >
      <ICON color={color} icon="back" size="18"></ICON>
      <CAPTION
        css={css`
          text-align: left;
          margin: 8px 0px 0px;
          padding-bottom: 8px;
          color: inherit;
        `}
        dangerouslySetInnerHTML={{ __html: backToText || "to all" }}
      />
    </El>
  );
};

export const ColorLink = ({ color, icon, url, children }) => (
  <Link
    to={url}
    css={css`
      background-color: ${getColor(color)};
      padding: 10px 15px;
      color: white;
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    `}
  >
    <ICON color={"white"} icon="back" size="18"></ICON>
    {children}
  </Link>
);

export const NextButton = ({ onClick, color, children }) => (
  <button
    onClick={() => {
      onClick();
    }}
    css={css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 15px;
      border-bottom: 2px solid ${color};
      transition: 0.2s all;

      @media (min-width: 768px) {
        min-width: 135px;
        max-width: 135px;
      }

      svg {
        transition: 0.2s all;
        margin-right: -8px;
      }

      &:hover {
        cursor: pointer;
        color: black;
        border-color: black;

        svg path {
          fill: black !important;
        }
      }
    `}
  >
    <ICON color={color} icon="forward" size="36"></ICON>
    <H5
      css={css`
        text-align: right;
        margin: 8px 0px;
        padding-bottom: 8px;
      `}
    >
      {children}
    </H5>
  </button>
);

export const ViewMoreLink = ({ to, color, children, underline }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Link
      to={to}
      css={css`
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        border-bottom: 2px solid ${getColor(color)};
        @media (min-width: 768px) {
          min-width: 120px;
        }

        svg {
          transition: 0.1s all;
          margin-right: -4px;
        }

        &:hover {
          cursor: pointer;
          svg {
            margin-right: 4px;
          }
        }
      `}
    >
      <ICON color={getColor(color)} icon="forward" size="30"></ICON>
      <H5
        css={css`
          text-align: right;
          margin: 8px 0px;
          padding-bottom: ${underline === true ? `8px;` : "0px"};
        `}
      >
        {children || nav.viewAll[language]}
      </H5>
    </Link>
  );
};

export const BoxLink = ({ url, color, textColor, children }) => (
  <Link
    to={url}
    css={css`
      background-color: ${color};
      ${setPadding("all", "sm")}
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      height: 100%;
      width: 100%;
      transition: 0.1s all;
      color: ${textColor};

      svg {
        transition: 0.1s all;
        margin-right: -4px;
      }

      &:hover {
        cursor: pointer;
        background-color: #353535;
        color: white;
        svg {
          margin-right: 4px;
          path {
            fill: white;
          }
        }
      }
    `}
  >
    <ICON color={textColor} icon="forward" size="30"></ICON>
    <H5
      css={css`
        text-align: right;
        margin: 8px 0px;
      `}
    >
      {children}
    </H5>
  </Link>
);
