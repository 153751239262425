/** @jsx jsx */
import { useContext } from "react";
import { jsx, css } from "@emotion/core";

/* data */
import { UrbidsContext } from "../../providers/UrbidsProvider";
import withLanguage from "../withLanguage";

import ActiveCard from "./ActiveCard";

/* styles */
import { SectionHeader, Container } from "../common/layouts";
import { H2, PARAGRAPH } from "../common/typography";
import { setMargin } from "../common/spacing";
import { common, descriptions } from "../../language/content.js";

import BannerUrbids from "../banners/BannerUrbids.jsx";

const Urbids = ({ language }) => {
  const urbids = useContext(UrbidsContext);
  //const completedUrbids = filter(urbids, { status: 'completed' });
  //const activeUrbids = filter(urbids, { status: "active" });

  return (
    <section>
      <SectionHeader>
        <H2
          css={css`
            max-width: 600px;
          `}
        >
          {common.urbids[language]}
        </H2>
        <PARAGRAPH
          css={css`
            max-width: 800px;
            ${setMargin(["top", "bottom"], "md")}
          `}
          dangerouslySetInnerHTML={{
            __html: descriptions.urbids[language],
          }}
        />
      </SectionHeader>
      <Container>
        {urbids.map((urbid) => (
          <ActiveCard key={urbid.id} {...urbid} />
        ))}
      </Container>
      <BannerUrbids />
    </section>
  );
};

export default withLanguage(Urbids);

/*
          <div
            css={css`
              margin-left: 45px;
              margin-top: 6px;
            `}
          >
            <HowItWorks what="urbids" />
          </div> */
