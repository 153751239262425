/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, css } from '@emotion/core';
import { LanguageContext } from '../../providers/LanguageProvider';
import { Container, Section, Row, Third, Half, SectionHeader } from '../common/layouts';
import { H2, H3, H4, H5, PARAGRAPH } from '../common/typography';
import { pageNotFound } from '../../language/content';

const NotFoundPage = () => {
  const { language } = useContext(LanguageContext);
  return (
    <main>
      <Section>
        <SectionHeader>
          <H2>404</H2>
          <H3 css={css`
            max-width: 600px;
          `}>{ pageNotFound.heading[language] }</H3>
        </SectionHeader>
        <Container>
          <PARAGRAPH>{ pageNotFound.paragraph[language] }</PARAGRAPH>
        </Container>
      </Section>
    </main>
  )
};

export default NotFoundPage;