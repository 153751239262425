
/** @jsx jsx */
import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { Redirect } from 'react-router-dom';
import { firestore } from '../../firebase.js';
import withUser from '../withUser';
import { orderBy } from 'lodash';
import { collectIdsAndDocs } from '../../utilities';

import Posts from './Posts';
import AddPost from './AddPost';

import { H3 } from '../common/typography';
import { SectionHeader, Section } from '../common/layouts';

class PostManager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
    this.deletePost = this.deletePost.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.unsubscribeFromFirestore = null;
  };

  get postsRef() {
    return firestore.collection("posts");
  };

  componentDidMount() {
    this.unsubscribeFromFirestore = firestore.collection('posts').onSnapshot(snapshot => {
      let posts = snapshot.docs.map(collectIdsAndDocs);
      posts = orderBy(posts, "createdAt", "desc");
      this.setState({ posts });
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromFirestore();
  };

  deletePost = (id) => {
    let postId = id || this.state.id;
    firestore.collection('posts').doc(postId).delete();
  };

  toggleStatus = (id, status) => {
    firestore.collection('posts').doc(id).update({
      status: ((status === 'active') ? 'draft' : 'active')
    });
  };

  render() {
    const { user } = this.props; // to ako si stignal dotuk, moje bi ne ti tr user; t.e. na po-ranno nivo tr da e proverkata
    const { posts } = this.state;
    // if ((!user) || (user && (!user.isAdmin))) {
    //   return <Redirect to='/'  />
    // }

    return (
      <main
        css={css`
          label {
            margin-bottom: 15px;
            display: block;
          }
        `}>
        <Section>
          <SectionHeader>
            <H3>Manage posts</H3>
          </SectionHeader>
          <Posts 
            posts={posts}
            onDeleteClick={this.deletePost}
            onToggleClick={this.toggleStatus}
            />
        </Section>
        <Section>
          <AddPost />
        </Section>
        
      </main>
    );
  }
}

export default withUser(PostManager);