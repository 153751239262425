/** @jsx jsx */
import React, { useContext } from "react";
import { jsx, css } from "@emotion/core";
import { LanguageContext } from "../../providers/LanguageProvider";
import {
  Container,
  Section,
  Row,
  Third,
  Half,
  SectionHeader,
} from "../common/layouts";
import { H2, H3, H4, H5, PARAGRAPH } from "../common/typography";
import { setMargin, setPadding } from "../common/spacing";
import { pageNotFound } from "../../language/content";
import { BannerDownloadApp } from "../banners/BannerNominations";

const support = {
  header: {
    bg: "Подкрепи ни!",
    en: "Support us!",
  },
  body: {
    bg: `Градските намеси на Урбид разчитат изцяло на подкрепа отдолу-нагоре - от граждани
    или компании, искащи да направят града си по-хубав, чист и приветлив. Подкрепи някой от
    текущите ни проекти или цялата инициатива. Благодарим ти!`,
    en: `The urban interventions rely entirely on funding from the ground up - from citizens or
    businesses willing to make their city nicer, cleaner, and friendlier. Support us by either
    backing up a project of your choice, or the entire initiative. Thank you!`,
  },
  bank: { bg: "Дарение по сметка:", en: "Donate through bank:" },
  paypal: { bg: "Дарение през PayPal:", en: "Donate with PayPal:" },
  urbid: {
    bg: "Сдружение Урбид",
    en: "Sdruzhenie Urbid",
  },
};

const DownloadAppPage = () => {
  const { language } = useContext(LanguageContext);
  return (
    <main>
      <Section>
        <SectionHeader>
          <H2>{support.header[language]}</H2>
        </SectionHeader>
        <Container>
          <div
            css={css`
              ${setMargin(["bottom"], "md")}
            `}
          >
            <PARAGRAPH>{support.body[language]}</PARAGRAPH>
          </div>
          <div
            css={css`
              ${setMargin(["top", "bottom"], "md")}
            `}
          >
            <H4>{support.bank[language]}</H4>
            <PARAGRAPH>
              {support.urbid[language]}
              <br></br>IBAN: BG24RZBB91551010826455
            </PARAGRAPH>
          </div>
          <div
            css={css`
              ${setMargin(["top", "bottom"], "md")}
            `}
          >
            <H4>{support.paypal[language]}</H4>
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              css={css``}
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="35B4V3BKEQHQW"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/en_BG/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
        </Container>
      </Section>
    </main>
  );
};

export default DownloadAppPage;
