/** @jsx jsx */

import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import { setMargin } from "./spacing";
import MdPin from "react-ionicons/lib/MdPin";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdList from "react-ionicons/lib/MdList";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import MdHeart from "react-ionicons/lib/MdHeart";
import MdHeartOutline from "react-ionicons/lib/MdHeartOutline";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdClose from "react-ionicons/lib/MdClose";
import IosChatboxesOutline from "react-ionicons/lib/IosChatboxesOutline";
import LogoFacebook from "react-ionicons/lib/LogoFacebook";
import LogoInstagram from "react-ionicons/lib/LogoInstagram";
import LogoWhatsapp from "react-ionicons/lib/LogoWhatsapp";
import LogoTwitter from "react-ionicons/lib/LogoTwitter";
import IosDocument from "react-ionicons/lib/IosDocument";
import IosGlobe from "react-ionicons/lib/IosGlobe";

import {
  colorNominations,
  colorUrbids,
  colorRealizations,
} from "../common/colors";

export const PARAGRAPH = styled("p")`
  font-family: "Intro Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
  color: inherit;
  opacity: 0.75;
  max-width: 800px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const CAPTION = styled("p")`
  font-family: "Intro Regular";
  font-size: 14px;
  line-height: 1.2em;
  color: inherit;
  opacity: 0.55;
  max-width: 800px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const getIconComponent = (icon) => {
  let MyIcon = null;

  switch (icon) {
    case "pin":
      MyIcon = MdPin;
      break;
    case "add":
      MyIcon = MdAdd;
      break;
    case "list":
      MyIcon = MdList;
      break;
    case "back":
      MyIcon = MdArrowBack;
      break;
    case "forward":
      MyIcon = MdArrowForward;
      break;
    case "heart":
      MyIcon = MdHeart;
      break;
    case "heart-outline":
      MyIcon = MdHeartOutline;
      break;
    case "comments":
      MyIcon = IosChatboxesOutline;
      break;
    case "doc":
      MyIcon = IosDocument;
      break;
    case "facebook":
      MyIcon = LogoFacebook;
      break;
    case "instagram":
      MyIcon = LogoInstagram;
      break;
    case "whatsapp":
      MyIcon = LogoWhatsapp;
      break;
    case "twitter":
      MyIcon = LogoTwitter;
      break;
    case "globe":
      MyIcon = IosGlobe;
      break;
    case "plus":
      MyIcon = MdAdd;
      break;
    case "close":
      MyIcon = MdClose;
      break;
    default:
      break;
  }

  return MyIcon;
};

const getColor = (color) => {
  if (color === "urbids") {
    return colorUrbids;
  } else if (color === "nominations") {
    return colorNominations;
  } else if (color === "realizations") {
    return colorRealizations;
  } else {
    return color;
  }
};

export const ICON = ({ icon, size, color }) => {
  const IconComponent = getIconComponent(icon);
  const iconSize = size ? `${size}px` : "18px";
  const iconColor = color ? getColor(color) : "black";
  return <IconComponent fontSize={iconSize} color={iconColor} />;
};

export const TITLE_LARGE = styled("h3")`
  font-family: "Intro Regular Alt";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2em;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`;

export const TITLE_MEDIUM = styled("h4")`
  font-family: "Intro Medium Alt";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2em;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const TITLE_SMALL = styled("h5")`
  font-family: "Intro Medium Alt";
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 400;

  /* @media (min-width: 768px) {
    font-size: 24px;
  } */
`;

export const PAGETITLE = styled("h6")`
  font-family: "Intro Regular Alt";
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
`;

export const H1 = styled("h2")`
  font-family: "Intro SemiBold Alt";
  font-size: 10vw;
  line-height: 1em;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

export const H2 = styled("h2")`
  font-family: "Intro Bold Alt";
  font-size: 7vw;
  line-height: 1em;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

export const H3 = styled("h3")`
  font-family: "Intro SemiBold Alt";
  font-size: 30px;
  line-height: 1em;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 44px;
  }
`;

export const H4 = styled("h4")`
  font-family: "Intro SemiBold Alt";
  font-size: 21px;
  line-height: 1.2em;
  font-weight: 400;
  ${setMargin(["bottom"], "md")}

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const H6 = styled("h6")`
  font-family: "Intro Regular Alt";
  font-size: 16px;
  line-height: 1em;
  font-weight: 400;
`;

export const H5 = styled("h5")`
  font-family: "Intro Regular Alt";
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
