/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

import { setPadding, setMargin } from "../common/spacing";
import {
  Container,
  Half,
  Row,
  Third,
  TwoThirds,
  Full,
  Section,
} from "../common/layouts";
import {
  H3,
  PARAGRAPH,
  TITLE_SMALL,
  TITLE_MEDIUM,
  CAPTION,
} from "../common/typography";
import SingleItemNavBar from "../modules/SingleItemNavBar";
import ImageSlider from "../elements/Slider";

class LayoutSingle extends Component {
  static Header = ({
    backToUrl,
    backToText,
    title,
    topLeft,
    bottomLeft,
    right,
    color,
  }) => {
    return (
      <Section
        css={css`
          ${setPadding(["top", "bottom"], "lg")}
          background-color: ${color || "#353535"};
          color: ${color ? "#353535" : "white"};
        `}
      >
        <Container>
          <Row>
            <Full>
              <SingleItemNavBar backToText={backToText} url={backToUrl} />
            </Full>
          </Row>
          <Row>
            <Half>
              <div
                css={css`
                  height: 100%;
                  min-height: 200px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;

                  @media (max-width: 767px) {
                    ${setMargin(["bottom"], "md")};
                    min-height: 0px;
                  }
                `}
              >
                <div>
                  <H3
                    css={css`
                      ${setMargin(["bottom"], "md")}
                    `}
                  >
                    {title}
                  </H3>
                  <div>{topLeft}</div>
                </div>
                <div>{bottomLeft}</div>
              </div>
            </Half>
            <Half
              css={css`
                position: relative;
              `}
            >
              {right}
            </Half>
          </Row>
        </Container>
      </Section>
    );
  };

  static Content = ({ support, supportersLabel, description, details }) => (
    <Section>
      <Container>
        <Row>
          <Third>{details}</Third>
          <TwoThirds>
            <PARAGRAPH dangerouslySetInnerHTML={{ __html: description }} />
            {support && (
              <ul
                id="supporters"
                css={css`
                  list-style: none;
                  margin: 0px;
                  padding-left: 0px;
                  ${setPadding(["top", "bottom"], "lg")}
                `}
              >
                <TITLE_MEDIUM
                  css={css`
                    margin-bottom: 30px;
                  `}
                >
                  {supportersLabel}
                </TITLE_MEDIUM>
                {support.map((transaction) => {
                  return (
                    <li
                      css={css`
                        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                        ${setPadding(["top", "bottom"], "sm")}
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                          ${setMargin(["bottom"], "xs")}
                        `}
                      >
                        <TITLE_SMALL>
                          {transaction.payerName || "Anonymous"}
                        </TITLE_SMALL>
                        <TITLE_SMALL>{transaction.value} EUR</TITLE_SMALL>
                      </div>
                      {transaction.note && (
                        <div>
                          <CAPTION>{transaction.note}</CAPTION>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </TwoThirds>
        </Row>
      </Container>
    </Section>
  );

  static ImageSlider = ({ children }) => <Section>{children}</Section>;

  static Footer = ({ support, imageUrls, postType, postId }) => (
    <div>
      <div>
        {imageUrls && imageUrls.length > 1 && (
          <Section>
            <ImageSlider
              imageUrls={imageUrls}
              postType={postType}
              postId={postId}
            />
          </Section>
        )}
      </div>
      <Container>
        <ul>
          {support &&
            support.map((transaction) => {
              return (
                <li>
                  <TITLE_SMALL>
                    {transaction.payerName || "Anonymous"}
                  </TITLE_SMALL>
                  <PARAGRAPH>{transaction.value}</PARAGRAPH>
                </li>
              );
            })}
        </ul>
      </Container>
    </div>
  );

  // static Footer = ({ imageUrls, postType, postId }) => (
  // <div>
  //   {imageUrls && imageUrls.length > 1 && (
  //     <Section>
  //       <ImageSlider
  //         imageUrls={imageUrls}
  //         postType={postType}
  //         postId={postId}
  //       />
  //     </Section>
  //   )}
  // </div>
  // );

  render() {
    return (
      <article>
        {React.Children.map(this.props.children, (child) => {
          return React.cloneElement(child, { ...child.props });
        })}
      </article>
    );
  }
}

export default LayoutSingle;
