/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { setMargin, setPadding } from '../common/spacing';
import { ICON } from '../common/typography';

const Card = ({ imageUrl, url, children, onClick }) => {

  return (
    <div 
      css={css`
        width: 100%;
        ${setMargin(['bottom'], 'md')} 
        
        @media (min-width: 768px) {
          width: 100%;
          min-width: 25%;
          max-width: 25%;
          ${setPadding(['left', 'right'], 'sm')}
        }
      `}>
      <div
        css={css`
          background-color: #cdcdcd;
          width: 100%;
          position: relative;
        `}>
        
        <div 
          css={css`
            background-color: grey;
            background-image: url(${imageUrl});
            background-size: cover;
            background-position: center center;
            height: 150px;
          `} />
        <div 
        css={css`
          ${setPadding(['left', 'right'], 'sm')}
          ${setPadding(['top', 'bottom'], 'md')}
          position: relative;
        `}>
          {children}
        </div>
        { onClick && <button 
          onClick={onClick}
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            height: 50px;
            ${setPadding(['left', 'right'], 'sm')}
            background-color: transparent;
          `}>
          Edit
          <ICON icon='forward' size={30} />
        </button>}
      </div>
    </div>
  )
}

export default Card;