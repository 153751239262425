
/** @jsx jsx */
import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { Redirect } from 'react-router-dom';
import { firestore, storage } from '../../firebase.js';
import withUser from '../withUser';
import { orderBy } from 'lodash';
import FileUploader from "react-firebase-file-uploader";
import { getImageUrl, getFullImageUrl } from '../../utilities';
import { findIndex, indexOf } from 'lodash';
import Posts from './Posts';
import AddPost from './AddPost';
import { Input, Textarea, Form, FormGroup } from '../forms/Form';
import { H3, PARAGRAPH } from '../common/typography';
import { Container, Row, SectionHeader, Section, Half, Full, Third } from '../common/layouts';

const ImageThumbnail = ({ imageUrl, onDeleteClick }) => {
  
  return (
    <div
      css={css`
        height: 90px;
        width: 90px;
        min-width: 90px;
        background-image: url('${imageUrl}');
        background-size: cover;
        background-position: center center;
        margin: 5px;
      `}>
      <button onClick={onDeleteClick}>delete</button>
    </div>
  )
};

class AddPhotosToPost extends Component {

  constructor(props) {
    super(props);
    this.state = {
      postType: null,
      postId: null,
      imageUrls: []
    };
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
    this.onImageDelete = this.onImageDelete.bind(this);
    this.onBlur = this.onBlur.bind(this);
  };

  handleUploadSuccess = (filename) => {
    let { postId, postType, imageUrls } = this.state;
    imageUrls.push(filename);

    // update post in firebase
    this.updatePostImages(imageUrls);
  };

  onBlur = () => {
    const input = this.grabUserInput();
    this.getPostImages({...input});
  };

  onImageDelete = (url) => {
    let { imageUrls } = this.state;
    const imageIndex = indexOf(imageUrls, url);
    imageUrls.splice(imageIndex, 1);
    this.updatePostImages(imageUrls);
  };

  updatePostImages = (_imageUrls) => {
    let { postId, postType } = this.state;

    // update post in firebase
    if (postType && postId) {
      firestore.collection(`${postType}`).doc(`${postId}`)
        .update({
          imageUrls: _imageUrls
        })
        .then(() => {
          this.setState({ _imageUrls });
        });
    };
  }

  getPostImages = ({ postType, postId }) => {
    let state = { postType, postId };
    if (postType && postId) {
      firestore.collection(`${postType}`).doc(`${postId}`).get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            if (data.imageUrls) {
              state.imageUrls = data.imageUrls;
            };
            this.setState(state);
          } else {
            console.log('no such doc');
          }
          
        });
    } else {
      console.log('nexttt');
    }
  };

  grabUserInput() {
    let input = {
      postType: this.refs.post_type.value,
      postId: this.refs.post_id.value
    };
    console.log('input ', input, this.refs);
    return input;
  };

  render() {
    const { user } = this.props; // to ako si stignal dotuk, moje bi ne ti tr user; t.e. na po-ranno nivo tr da e proverkata
    const { postType, postId, imageUrls } = this.state;
    // if ((!user) || (user && (!user.isAdmin))) {
    //   return <Redirect to='/'  />
    // }


    return (
      <main
        css={css`
          label {
            margin-bottom: 15px;
            display: block;
          }
        `}>
        <Section>
          
          <Container>
            <Form>
              <Row>
                <Full>
                  <PARAGRAPH>Post type: {postType || 'undefined'}</PARAGRAPH>
                  <PARAGRAPH>Post id: {postId || 'undefined'}</PARAGRAPH>
                </Full>
                <Half>
                  <FormGroup
                    label = 'post type'>
                    <Input
                      ref='post_type'
                      placeholder='post_type'
                      defaultValue={postType}
                      onBlur={this.onBlur}/>
                  </FormGroup>
                </Half>
                <Half>
                  <FormGroup
                    label = 'post id'>
                    <Input
                      ref='post_id'
                      placeholder='post_id'
                      defaultValue={postId}
                      onBlur={this.onBlur}/>
                  </FormGroup>
                </Half>
                
                <Full>
                  <FormGroup
                    label = 'images'>
                    <div
                      css={css`
                        display: block;
                        `}>
                      <FileUploader
                        accept="image/*"
                        name="image-uploader"
                        randomizeFilename
                        multiple
                        storageRef={storage.ref().child(`${this.state.postType}/${this.state.postId}`)}
                        onUploadSuccess={filename => { this.handleUploadSuccess(filename); }} />
                    </div>
                  </FormGroup>
                </Full>
              </Row>
            </Form>
          </Container>
          <Container>
            <div
              css={css`
                display: flex;
                padding: 15px;
              `}>
              { imageUrls && imageUrls.map(filename => {
                  const imageUrl = getFullImageUrl(filename, postType, postId);
                  return <ImageThumbnail 
                            imageUrl={imageUrl}
                            onDeleteClick={() => {this.onImageDelete(filename);}} /> 
                })
              }
            </div>
          </Container>
        </Section>
      </main>
    );
  }
}

export default withUser(AddPhotosToPost);