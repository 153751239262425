/** @jsx jsx */

import React, { useContext } from 'react';
import { jsx, css } from '@emotion/core';
import { setPadding } from '../common/spacing';
import Button from './Button';
import { LanguageContext } from '../../providers/LanguageProvider';
import { nav } from '../../language/content';

const InfoWindow = ({ children, color, isOpen, onCloseClick }) => {

  const { language } = useContext(LanguageContext);

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.35);
        pointer-events: ${isOpen ? 'all' : 'none'};
        opacity: ${isOpen ? 1 : 0};
        z-index: 50;
      `}>
      <div 
        css={css`
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 90vw;
        transform: translate3d(${isOpen ? '0' : '100%'}, 0, 0);
        transition: 0.2s all;
        z-index: 20;
        background-color: #353535;
        color: white;

        @media (min-width: 768px) {
          width: 40vw;
          max-width: 600px;
        }
      `}>
        <div
          css={css`
            opacity: ${isOpen ? 1 : 0};
            transition: 0.1s opacity;
            transition-delay: ${isOpen ? 0.1 : 0}s;
          `}>
          <div
            css={css`
              ${setPadding('all', 'sm')}
            `}>
            <Button
              onClick={onCloseClick}
              color={color}
              textColor='white'
              style='regular'
              icon='close'>
              { nav.close[language] } 
            </Button>

          </div>
          <div css={css`
            ${setPadding(['top', 'bottom'], 'md')}
            ${setPadding(['left', 'right'], 'sm')}
          `}>
            {children}  
          </div>
        </div>
      </div>
    </div>
  )
};

export default InfoWindow;