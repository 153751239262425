/** @jsx jsx */
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";
import { firestore } from "../../firebase.js";
import { H4, PARAGRAPH } from "../common/typography";
import { setMargin, setPadding } from "../common/spacing";
import { __Location, __Proposals, CardContainer } from "../elements/Card";
import { getImageUrl, collectIdsAndDocs } from "../../utilities";
import { LanguageContext } from "../../providers/LanguageProvider";
import { colorUrbids } from "../common/colors";
import Location from "../elements/Location";
import { common } from "../../language/content";
import ProposalCard from "../proposals/ProposalCard";
import SendProposal, { SendProposalInline } from "./modules/SendProposal";
import { BoxLink } from "../elements/Links";

const photosAndDetails = {
  bg: "галерия и детайли",
  en: "photos and details",
};

const ActiveCard = ({
  title,
  description,
  slug,
  id,
  imageUrls,
  address,
  urbidInfo,
}) => {
  const [proposals, setProposals] = useState(null);
  const imageUrl = getImageUrl(imageUrls[0], "urbids", id, "md");
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (!proposals) {
      firestore
        .collection("proposals")
        .where("urbidId", "==", id)
        .get()
        .then((snapshot) => {
          const proposals = snapshot.docs.map(collectIdsAndDocs);
          setProposals(proposals);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  });

  let hasProposals = proposals && proposals.length > 0 ? true : false;

  return (
    <article
      css={css`
        ${setMargin(["bottom"], "lg")}
      `}
    >
      <header
        css={css`
          background-color: #353535;
          color: white;
          display: flex;
          @media (max-width: 767px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            flex-basis: 0;
            flex-grow: 1;
            ${setPadding(["top", "bottom"], "lg")}
            ${setPadding(["left", "right"], "lg")};

            @media (max-width: 767px) {
              ${setPadding(["left", "right"], "sm")};
              ${setPadding(["top", "bottom"], "md")}
            }
          `}
        >
          <Link to={`/active/${slug}`}>
            <H4
              css={css`
                ${setMargin(["bottom"], "sm")}
                transition: 0.1s all;

                &:hover {
                  color: ${colorUrbids};
                }
              `}
            >
              {title[language]}
            </H4>
          </Link>
          <Location
            address={`${address.neighborhood[language]}, ${address.city[language]}`}
          />
          <SendProposal deadline={urbidInfo ? urbidInfo.deadline : null} />
        </div>
        <div
          css={css`
            flex-basis: 0;
            flex-grow: 1;
            display: flex;
            min-height: 200px;
          `}
        >
          <div
            css={css`
              background-image: url(${imageUrl});
              background-size: cover;
              background-position: center center;
              width: 100%;
            `}
          />
          <div
            css={css`
              min-width: 190px;
              margin-bottom: -60px;
              width: 190px;
              z-index: 10;

              @media (max-width: 767px) {
                min-width: 50%;
                margin-bottom: 0px;
              }
            `}
          >
            <BoxLink
              color={colorUrbids}
              textColor={"white"}
              url={`/active/${slug}`}
            >
              {photosAndDetails[language]}
            </BoxLink>
          </div>
        </div>
      </header>
      <div
        css={css`
          background-color: #bcbcbc;
          ${setPadding("all", "lg")}
          @media (max-width: 767px) {
            ${setPadding(["left", "right"], "sm")};
          }
        `}
      >
        <PARAGRAPH
          dangerouslySetInnerHTML={{
            __html: description[language].substring(0, 300) + "...",
          }}
        />
        {!hasProposals && urbidInfo && urbidInfo.deadline && (
          <div>
            <SendProposalInline />
          </div>
        )}
      </div>

      {hasProposals && (
        <div
          css={css`
            position: relative;
            ${setPadding(["left", "right"], "lg")}
            @media (max-width: 767px) {
              ${setPadding(["left", "right"], "sm")};
            }
          `}
        >
          {hasProposals && (
            <div>
              <div
                css={css`
                  position: absolute;
                  background-color: #bcbcbc;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 200px;
                `}
              />
              <div
                css={css`
                  position: relative;
                  z-index: 5;
                `}
              >
                <H4>{proposals.length + " " + common.proposals[language]}</H4>
                <CardContainer>
                  {proposals.map((proposal) => (
                    <ProposalCard key={proposal.id} {...proposal} />
                  ))}
                </CardContainer>
              </div>
            </div>
          )}
        </div>
      )}
    </article>
  );
};

export default ActiveCard;
