/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import { getImageUrl } from '../../utilities.js';
import SingleItemNavBar from '../modules/SingleItemNavBar';
import { Container, Section, Row, Third, Half, Full, TwoThirds, SectionHeader } from '../common/layouts';
import { H2, H3, H4, H5, PARAGRAPH } from '../common/typography';
import { setMargin } from '../common/spacing';
import withLanguage from '../withLanguage';
import Detail from '../elements/Detail';
import moment from 'moment';
import 'moment/locale/bg';
import { queryBySlug } from '../../utilities.js';

class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: {},
      featuredImage: '',
      paragraphs: []
    };
  };

  get slug() {
    const { match } = this.props;
    return match ? match.params.slug: null;
  };

  componentDidMount() {
    console.log('slug', this.slug);
    queryBySlug('posts', this.slug, doc => {
      this.setState({
        ...doc.data(),
        id: doc.id
      });
    });
  };

  render() {
    const { id, heading, featuredImage, paragraphs, createdAt } = this.state;
    const { language } = this.props;
    const featuredImageUrl = getImageUrl(featuredImage, 'posts', id, 'lg');
    moment.locale(language);

    return (
      <main>
        <Section>
          <Container>
            <div>
              <Row>
                <Full>
                  <SingleItemNavBar
                    color='dark'
                    backToText={'към всички'}
                    url={`/blog`} />
                </Full>
                <Full>
                  <img src={featuredImageUrl} />
                  <H3
                    css={css`
                      ${setMargin(['top'], 'md')}
                      max-width: 800px;
                    `}>{ heading && heading[language] }
                  </H3>
                </Full>
              </Row>
            </div>
          </Container>
        </Section>
        <Section>
          <Container>
            <div>
              <Row>
                <Third>
                  <Detail 
                    label='Публикувано на: '>
                    { createdAt && moment(createdAt.toDate()).format('L')}
                  </Detail>
                </Third>
                <TwoThirds>
                { paragraphs && paragraphs.map((par, index) => {
                  const imageUrl = par.imageUrl ? getImageUrl(par.imageUrl, 'posts', id, 'md') : null;
                  return (
                    <div
                      key={`key--${index}`}
                      css={css`
                        ${setMargin(['bottom'], 'md')}
                      `}>
                      <PARAGRAPH
                        dangerouslySetInnerHTML={{ __html: par.text[language] }} />
                      { imageUrl && 
                        <img 
                          css={css`
                            ${setMargin(['top'], 'md')}
                          `}
                          src={imageUrl} />
                      }
                    </div>
                  );
                })}
                </TwoThirds>
              </Row>
            </div>
          </Container>
        </Section>
      </main>
    );
  }
}

export default withLanguage(withRouter(PostPage));