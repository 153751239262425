import React, { Component } from "react";

class AddUrbidThankYou extends Component {
  constructor(props) {
    super(props);

    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.props.updateStore({ savedToCloud: true }); // Update store here (this is just an example, in reality you will do it via redux or flux)
        // call resolve() to indicate that server validation or other aync method was a success.
        // ... only then will it move to the next step. reject() will indicate a fail
        resolve();
        // reject(); // or reject
      }, 5000);
    });
  }

  render() {
    return <h1>thank youuuu</h1>;
  }
}

export default AddUrbidThankYou;
