import React, { Component } from 'react';
import FileUploader from '../elements/FileUploader';
import _ from 'lodash';

class AddUrbidSponsor extends Component {

  // state = {
  //   sponsorName: '',
  //   sponsorLogo: '', // image url
  //   sponsorPledge: 0 // amount (optional)
  // }

  // componentDidMount() {
  //   this.onBlur = this.onBlur.bind(this);
  // }

  _onBlur() {
    const { validationCheck, index } = this.props;
    const data = {
      sponsorName: this.refs.name.value,
      sponsorPledge: this.refs.pledge.value
    }

    validationCheck(data, index);
  }
  onBlur = this._onBlur.bind(this);

  _onUpload(filenames) {
    console.log(filenames);
    const { validationCheck, index } = this.props;
    const data = {
      sponsorLogo: filenames[0]
    }

    validationCheck(data, index);
  }
  onUpload = this._onUpload.bind(this);

  render() {
    const { sponsorName, sponsorPledge, sponsorLogo, urbidId } = this.props;
    return (
      <div className="urb-add__form__fields">
        <div className="urb-add__form__field">
          <label>Sponsor Name</label>
          <input
            ref="name"
            autoComplete="off"
            type="text"
            placeholder='Име'
            className="form-control"
            required
            defaultValue={sponsorName}
            onBlur={this.onBlur} />
        </div>
        <div className="urb-add__form__field">
          <label>Pledge</label>
          <input
            ref="pledge"
            autoComplete="off"
            type="number"
            placeholder='Budget'
            className="form-control"
            required
            defaultValue={sponsorPledge}
            onBlur={this.onBlur} />
        </div>
        <div className="urb-add__form__field">
          <label>Logo</label>
          <FileUploader
            postType='urbids'
            postId={urbidId}
            onUpload={this.onUpload} />
        </div>
      </div>
    )
  }
}

class AddUrbidSponsors extends Component {

  state = {
    sponsors: [
      {
        sponsorName: '',
        sponsorLogo: '', // image url
        sponsorPledge: 0 // amount (optional)
      }
    ]
  };

  _validateOnDemand = true; // this flag enables onBlur validation as user fills forms

  isValidated() {
    console.log('validate sponsors');
    this.props.updateStore({
      ...this.state,
      savedToCloud: false
    });
    return true;
  }
  // isValidated = this.isValidated.bind(this);

  _validationCheck(data, index) {
    let _state = this.state;
    _.merge(_state.sponsors[index], data);
    this.setState(_state);
  }
  validationCheck = this._validationCheck.bind(this);

  _validateData(data) {
    return  {
      titleVal: (data.title != 0), // required: anything besides N/A
    }
  }

  _grabUserInput() {
    return {
      title_bg: this.refs.title_bg.value
    };
  }

  render() {
    console.log('this is  ', this);
    const { sponsors } = this.state;
    const { urbidId } = this.props;

    // basically for each sponsor,
    // create and populate a form field
    // and add an extra one

    return (
      <div className="urb-add__form__step add--urbid-sponsors">
        <form>
          <div className={`urb-add__form__group`}>
            {sponsors && sponsors.map((sponsor, index) => {
              return <AddUrbidSponsor {...sponsor} index={index} urbidId={urbidId} validationCheck={this.validationCheck} />
            }) }
          </div>
        </form>
      </div>
    )
  }
}

export default AddUrbidSponsors;
