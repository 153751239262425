/** @jsx jsx */
import { Component } from "react";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { signInWithGoogle, signInWithFacebook } from "../../firebase.js";
import LogoGoogle from "react-ionicons/lib/LogoGoogle";
import LogoFacebook from "react-ionicons/lib/LogoFacebook";
import { CAPTION } from "../common/typography";
import { setPadding, setMargin } from "../common/spacing";
import { colorNominations } from "../common/colors";
import includes from "lodash/includes";
import withLanguage from "../withLanguage";
import { auth as authTexts, toasts } from "../../language/content";
import { accent } from "../common/colors";
import { auth } from "../../firebase";

import { toast } from "react-toastify";

const Input = styled("input")`
  height: 50px;
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.1s all;
  transition: 0.1s all;
  font-size: 1rem;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 1em;
  font-family: "Intro Regular", sans-serif;
  width: 100%;
  color: black;

  &:hover,
  &:focus {
    outline: none !important;
  }
`;

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    error: false,
  };

  getError = (errorCode) => {
    if (includes(errorCode, "wrong-password")) return "wrongPassword";
    if (includes(errorCode, "user-not-found")) return "userNotFound";
    if (includes(errorCode, "invalid-email")) return "invalidEmail";
    return "other";
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      this.setState({ error: this.getError(errorCode) });
    });
    this.setState({ email: "", password: "" });
  };

  render() {
    const { email, password, error } = this.state;
    const { language, onSignIn } = this.props;

    return (
      <div
        css={css`
          max-width: 300px;
          ${setPadding(["top", "bottom"], "md")}
        `}
      >
        <form onSubmit={this.handleSubmit}>
          <Input
            type="email"
            name="email"
            placeholder={authTexts.placeholders.email[language]}
            value={email}
            onChange={this.handleChange}
          />
          <Input
            type="password"
            name="password"
            placeholder={authTexts.placeholders.password[language]}
            value={password}
            onChange={this.handleChange}
          />
          <input
            type="submit"
            value={authTexts.signin[language]}
            css={css`
              border: none;
              border-bottom: 2px solid ${colorNominations};
              ${setPadding(["bottom"], "xs")}
              font-size: 16px;
              margin-top: 15px;
              background: transparent;
              color: white;
              font-family: "Intro Regular Alt";

              &:active,
              &:focus {
                outline: none !important;
              }

              @media (min-width: 768px) {
                font-size: 16px;
              }
            `}
          />
        </form>
        <div
          css={css`
            ${setPadding(["top", "bottom"], "md")}
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            ${setMargin(["top"], "md")}
          `}
        >
          <button
            onClick={async () => {
              const { language } = this.props;
              await signInWithGoogle();
              onSignIn();
              toast(toasts.onLogIn[language]);
            }}
            css={css`
              ${setPadding(["top", "bottom"], "xs")}
              font-size: 16px;
              line-height: 1em;
              display: flex;
              align-items: center;
              color: ${accent}
              font-family: "Intro Regular Alt";
            `}
          >
            <LogoGoogle color={accent} fontSize="21px" />
            <span
              css={css`
                margin-left: 10px;
                color: ${accent};
              `}
            >
              {authTexts.loginGoogle[language]}
            </span>
          </button>
          <button
            onClick={async () => {
              const { language } = this.props;
              await signInWithFacebook();
              onSignIn();
              toast(toasts.onLogIn[language]);
            }}
            css={css`
              ${setPadding(["top", "bottom"], "xs")}
              font-size: 16px;
              line-height: 1em;
              display: flex;
              align-items: center;
              color: ${accent}
              font-family: "Intro Regular Alt";
              margin-top: 15px;
            `}
          >
            <LogoFacebook color={accent} fontSize="21px" />
            <span
              css={css`
                margin-left: 10px;
                color: ${accent};
              `}
            >
              {authTexts.loginFacebook[language]}
            </span>
          </button>
        </div>
        <CAPTION
          css={css`
            opacity: ${error !== false ? 1 : 0};
            color: #d03123;
          `}
        >
          {error && authTexts.errors.signIn[error][language]}
        </CAPTION>
      </div>
    );
  }
}

export default withLanguage(SignIn);
