/** @jsx jsx */
import { Component } from "react";
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { auth, createUserProfileDocument } from "../../firebase.js";
import { setPadding } from "../common/spacing";
import { colorNominations } from "../common/colors";
import { CAPTION } from "../common/typography";
import withLanguage from "../withLanguage";
import { auth as authTexts } from "../../language/content";
import includes from "lodash/includes";

import { toast } from "react-toastify";
import { toasts } from "../../language/content";

const Input = styled("input")`
  height: 50px;
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.1s all;
  transition: 0.1s all;
  font-size: 1rem;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 1em;
  font-family: "Intro Regular", sans-serif;
  width: 100%;
  color: black;

  &:hover,
  &:focus {
    outline: none !important;
  }
`;

class SignUp extends Component {
  state = {
    displayName: "",
    email: "",
    password: "",
    error: false,
  };

  getError = (errorCode) => {
    if (includes(errorCode, "weak-password")) return "weakPassword";
    if (includes(errorCode, "email-already-in-use")) return "emailAlreadyInUse";
    if (includes(errorCode, "invalid-email")) return "invalidEmail";
    return "other";
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, displayName } = this.state;
    const { language, onSignUp } = this.props;

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      createUserProfileDocument(user, { displayName });
      this.setState({ error: false });
      toast(toasts.onSignUp[language]);
      onSignUp();
    } catch (error) {
      const { code, message } = error;
      this.setState({
        error: authTexts.errors.signUp[this.getError(code)][language],
      });
    }
  };

  render() {
    const { displayName, email, password, error } = this.state;
    const { language, onSignUp } = this.props;
    return (
      <div
        css={css`
          max-width: 300px;
          ${setPadding(["top", "bottom"], "md")}
        `}
      >
        <form onSubmit={this.handleSubmit}>
          <Input
            type="text"
            name="displayName"
            placeholder={authTexts.placeholders.displayName[language]}
            value={displayName}
            onChange={this.handleChange}
          />
          <Input
            type="email"
            name="email"
            placeholder={authTexts.placeholders.email[language]}
            value={email}
            onChange={this.handleChange}
          />
          <Input
            type="password"
            name="password"
            placeholder={authTexts.placeholders.password[language]}
            value={password}
            onChange={this.handleChange}
          />
          <input
            type="submit"
            value={authTexts.signup[language]}
            css={css`
              border: none;
              border-bottom: 2px solid ${colorNominations};
              ${setPadding(["bottom"], "xs")}
              font-size: 16px;
              margin-top: 15px;
              background: transparent;
              color: white;
              font-family: "Intro Regular Alt";

              &:active,
              &:focus {
                outline: none !important;
              }

              @media (min-width: 768px) {
                font-size: 16px;
              }
            `}
          />
        </form>
        <CAPTION
          css={css`
            opacity: ${error !== false ? 1 : 0};
            color: #d03123;
            margin-top: 15px;
          `}
        >
          {error}
        </CAPTION>
      </div>
    );
  }
}

export default withLanguage(SignUp);
