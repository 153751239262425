/** @jsx jsx */

import { useState } from "react";
import { jsx, css } from "@emotion/core";

import Comment from "./Comment";
import AddComment from "./AddComment";
import { H4, PARAGRAPH } from "../common/typography";
import { setPadding, setMargin } from "../common/spacing";
import { Container } from "../common/layouts";
import withUser from "../withUser";
import withLanguage from "../withLanguage";

import { commentsPage } from "../../language/content.js";
import { SignInOrSignUp } from "../auth/SignInAndSignUp";
import InfoWindow from "./InfoWindow";

const Comments = ({ comments, docRef, user, language }) => {
  const [isOpen, toggleOverlay] = useState(false);
  return (
    <section
      css={css`
        ${setPadding(["bottom"], "lg")}
      `}
    >
      <Container>
        <div
          css={css`
            max-width: 600px;
          `}
        >
          <H4
            css={css`
              ${setMargin(["bottom"], "md")}
            `}
          >
            {commentsPage.title[language]}
          </H4>

          {user && <AddComment currentUser={user} docRef={docRef} />}
          <div
            css={css`
              ${setPadding(["top", "bottom"], "md")}
            `}
          >
            {comments &&
              comments.map((comment) => (
                <Comment {...comment} key={comment.id} />
              ))}
          </div>

          {!user && (
            <button
              onClick={() => {
                toggleOverlay(true);
              }}
            >
              <PARAGRAPH>{commentsPage.register[language]}</PARAGRAPH>
            </button>
          )}
        </div>
      </Container>

      <InfoWindow
        color="nominations"
        isOpen={isOpen}
        onCloseClick={() => {
          toggleOverlay(false);
        }}
      >
        <SignInOrSignUp language={language} />
      </InfoWindow>
    </section>
  );
};

export default withLanguage(withUser(Comments));
