/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { H5 } from '../common/typography';
import { setMargin, setPadding } from '../common/spacing';
import Card, { __Location, __Votes, __Comments } from '../elements/Card';
import { getImageUrl } from '../../utilities';

export const NominationCard = ({ title, id, slug, imageUrls, address, countVotes, countComments }) => {
  const imageUrl = getImageUrl(imageUrls[0], 'nominations', id, 'md');

  return (
    <Card 
      url={`/nominations/${slug}`}
      imageUrl={imageUrl}>
      <div
        css={css`
          @media (min-width: 768px) {
            min-height: 60px;
          }
        `}>
        <H5 css={css`
          ${setMargin(['bottom'], 'xs')}
        `}>
          { title }
        </H5>
        <__Location formattedAddress={`${address.neighborhood}, ${address.city}`} />
      </div>
      
      <div css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${setPadding(['top'], 'sm')}
      `}>
        <__Votes countVotes={countVotes} />
        <__Comments countComments={countComments} />
      </div>
    </Card>
  );
};