/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

const Logo = ({ color }) => (
  <div css={css`
    svg {
      fill: ${color || '#353535'};
      width: 60px;
    }
  `}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 258.6 155.9" style={{ enableBackground: 'new 0 0 258.6 155.9' }}>
      <g>
        <path class="st0" d="M252.2,93.8c-5,7.3-10.2,14.5-14.9,22c-2.3,3.6-4.6,4.5-8.8,3.7c-10.3-2-20.8-3.5-31.2-4.9
          c-1.2-0.2-3.1,0.7-3.9,1.7c-22.9,29.1-54.1,37.7-89.2,35.4c-20.1-1.3-39.2-7.1-56.8-17.1c-8.1-4.6-15.4-10.2-20.9-18
          c-0.8-1.2-2.8-2.1-4.3-2.2c-4.5-0.4-9.1-0.3-13.6-0.6c-1.3-0.1-3.5-0.8-3.6-1.5c-0.2-1.3,0.4-3.1,1.3-4.2
          c4.1-4.8,8.5-9.4,12.7-14.1c1.1-1.3,2.4-2.8,2.8-4.4c3.9-17.4,16-22.8,33-20.8c4,0.5,7.9,1.7,11.9,2.5c2.6,0.6,5.8,1,4.9,4.6
          c-0.8,3.6-3.8,2.3-6.4,1.9c-6.9-1-13.8-2.3-20.6-2.3c-7.1,0-12.5,4-14.6,10.9c-1.6,5.2-4.7,9.1-8.3,12.8c-2.8,2.9-5.4,5.9-8.1,8.9
          c0.1,0.4,0.2,0.9,0.3,1.3c4.1,0,8.3-0.2,12.4,0.1c1.6,0.1,3.8,0.6,4.5,1.7c5.7,8.9,13.9,15,23.1,19.6c29.9,14.9,60.9,19.9,93.4,9.8
          c17.4-5.4,31.9-15.2,42.1-30.5c2-3.1,4.5-2.6,7.2-2.2c10.3,1.6,20.5,3,30.8,4.9c3,0.6,4.5-0.4,5.9-2.7c2.7-4.4,5.5-8.6,8.8-13.8
          c-9.1,0.4-17,0.8-24.9,1.2c-12.8,0.6-25.6,1.2-38.4,1.8c-2.5,0.1-4.9,0-5.2-3c-0.3-3.1,2.1-3.4,4.6-3.5c19.6-0.9,39.2-1.9,58.8-2.9
          c2.9-0.1,5.8-0.3,8.7-0.6c2.8-0.4,5,0.3,6.6,2.8C252.2,92.5,252.2,93.2,252.2,93.8z"/>
        <path class="st0" d="M130.4,40.9c0.3-6.3,0.6-12.4,0.9-18.7c-12.4-3.3-24.4-6.4-37-9.8c-0.5,12.3-0.9,24-1.3,36.5
          c4.3-1.2,8.1-2.4,11.9-3.3c3.8-1,5.3,0,5.2,3.9c-0.4,16.9-0.9,33.7-1.7,50.6c-0.1,1.5-2.1,3.7-3.7,4.2c-4.8,1.4-9.8,2.3-14.7,3.2
          c-4.5,0.8-5.6-0.2-5.5-4.7c0.4-14,0.9-27.9,1.3-41.9c0-0.6,0-1.2,0-2c-3.3-0.7-4.1,0.6-4.2,3.5c-0.2,8.8-0.6,17.5-0.9,26.3
          c-0.1,2.6-0.1,5.2-3.6,5.1c-3.2-0.1-3.2-2.7-3.2-5c0.3-9.9,0.8-19.8,1-29.7c0.1-3.6,1.4-5.7,5.2-6.3c2-0.3,4-1.2,6-1.9
          c0.5-13.5,0.9-26.8,1.4-40.1c0.3-5.8,1.5-6.6,7-5.2c13,3.4,26,6.9,39,10.2c3.5,0.9,4.7,2.6,4.4,6.2c-0.5,5.5-0.5,11-0.8,16.5
          c-0.1,2.7,0.7,4,3.8,3.2c0.3-4.3,0.7-8.6,0.8-13c0-2.8,1.2-4.1,3.8-4.7c11.4-2.5,22.8-5.2,34.2-7.7c3.6-0.8,5.1,0.5,5,4.4
          c-0.3,12-0.8,24-1.3,36c-0.3,8.1-0.6,16.2-0.9,24.3c0,0.8,0.3,1.8-0.1,2.4c-1,1.4-2.3,2.6-3.5,3.9c-1-1.4-2.9-2.7-2.9-4.1
          c0.1-10.4,0.7-20.8,0.9-31.1c0.2-9.4,0-18.8,0-28.3c-9.1,2.1-18.9,4.3-28.9,6.6c0,4.2,0,8,0,12.4c5.5,0.6,11.1,1.4,16.7,1.8
          c4,0.3,5.3,2.1,5.1,6c-0.7,15.7-1.2,31.5-1.7,47.2c-0.2,6.2-1,6.9-7.3,5.9c-5.6-0.9-11.2-2.1-16.8-2.9c-3.9-0.5-5.2-2.4-5-6.2
          c0.7-14.9,1.2-29.8,1.7-44.9c-2.7-1.5-4.1-1.2-4.2,2.2c-0.1,4.5-0.5,9.1-0.5,13.6c0,3.1-1.3,4.4-4.4,4.3c-4.2-0.2-8.4-0.3-12.7-0.3
          c-3.3,0-4.7-1.4-4.6-4.7c0.3-6.6,0.4-13.3,0.6-20c0.1-3.5,1.6-5,5.2-4.6C123.5,40.4,126.8,40.6,130.4,40.9z M147.6,49.5
          c-0.5,15.3-1.1,29.9-1.6,44.6c5.4,1,10.3,1.8,15.6,2.8c0.6-15.4,1.1-30.5,1.7-45.8C158.1,50.6,153.1,50.1,147.6,49.5z M92.6,56.1
          c-0.5,14.8-1,29.3-1.5,43.8c10.7-0.8,10.7-0.8,11-10.4c0-0.3,0.1-0.6,0.1-1c0-11.6,0-23.2,0-35.2C99.4,54.2,96.1,55.1,92.6,56.1z
          M129.9,63.1c0-5.4,0-10.3,0-15.6c-2.6-0.1-5.2-0.3-8.1-0.4c0,5.5,0,10.5,0,15.6C124.4,62.8,126.9,62.9,129.9,63.1z"/>
        <path class="st0" d="M54.3,92.3c0.1,3.7-2.9,6.8-6.6,6.9c-3.6,0.1-6.9-3.1-6.9-6.7c0-3.5,2.9-6.4,6.5-6.5
          C51.2,85.8,54.2,88.5,54.3,92.3z"/>
        <path class="st0" d="M106.8,23.5c-0.2,3.8-0.3,7.2-0.5,10.8c-2.4,0-4.4,0-6.8,0c0-3.5,0-7,0-10.8C101.9,23.5,104.2,23.5,106.8,23.5
          z"/>
        <path class="st0" d="M110.9,23.7c2.3,0,4.2,0,6.7,0c0,3.2,0.3,6.4-0.2,9.5c-0.1,0.9-2.7,1.9-4,1.8c-1.1-0.1-2.9-1.6-3-2.6
          C110.2,29.7,110.7,27,110.9,23.7z"/>
        <path class="st0" d="M167.6,28.6c2.6,0,4.5,0,6.7,0c0,3.8,0,7.4,0,11.5c-2.5-0.2-4.5-0.3-6.7-0.4C167.6,35.8,167.6,32.3,167.6,28.6
          z"/>
        <path class="st0" d="M151.4,74.5c2.8,0,4.6,0,6.9,0c0,3.2,0.3,6.3-0.2,9.2c-0.1,0.9-2.3,2.1-3.4,2c-1.3-0.2-3.4-1.5-3.5-2.5
          C150.8,80.4,151.2,77.5,151.4,74.5z"/>
        <path class="st0" d="M158.6,57.4c0,4,0,7.3,0,10.9c-2.4,0-4.4,0-6.6,0c0-3.7,0-7.2,0-10.9C154.3,57.4,156.3,57.4,158.6,57.4z"/>
        <path class="st0" d="M93.7,80.2c2.8,0,4.6,0,6.5,0c1.5,12.2,0.1,12.6-6.5,10.4C93.7,87.2,93.7,83.7,93.7,80.2z"/>
      </g>
    </svg>
  </div>
);

export default Logo;