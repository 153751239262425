/** @jsx jsx */

import React, { Component, useContext } from "react";
import { jsx, css } from "@emotion/core";
import LocationPicker from "react-location-picker";
import NodeGeocoder from "node-geocoder";
import { FormGroup, Form, Input, Textarea } from "./Form";

const defaultPosition = {
  lat: 42.69,
  lng: 23.32,
};

var optionsBG = {
  provider: "google",
  httpAdapter: "https", // Default
  apiKey: "AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0", // for Mapquest, OpenCage, Google Premier
  language: "bg",
  region: "BG",
  formatter: null,
};

var optionsEN = {
  provider: "google",
  httpAdapter: "https", // Default
  apiKey: "AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0", // for Mapquest, OpenCage, Google Premier
  language: "en",
  region: "BG",
  formatter: null,
};

const geocoderBG = NodeGeocoder(optionsBG);
const geocoderEN = NodeGeocoder(optionsEN);

class AddUrbidInfo extends Component {
  state = {
    title: {
      bg: this.props.getStore().title.bg,
      en: this.props.getStore().title.en,
    },
    description: {
      bg: this.props.getStore().description.bg,
      en: this.props.getStore().description.en,
    },
    address: this.props.getStore().address,
  };

  _validateOnDemand = true; // this flag enables onBlur validation as user fills forms

  isValidated() {
    const userInput = this._grabUserInput(); // grab user entered vals
    console.log("user input ", userInput);
    let _state = {
      title: {
        bg: userInput.title_bg,
        en: userInput.title_en,
      },
      description: {
        bg: userInput.description_bg,
        en: userInput.description_en,
      },
    };

    this.props.updateStore({
      ..._state,
      savedToCloud: false,
    });

    // console.log(_state);
    return true;
  }

  // isValidated = this.isValidated.bind(this);

  validationCheck() {
    if (!this._validateOnDemand) return;

    const userInput = this._grabUserInput(); // grab user entered vals

    let _newState = {
      title: {
        bg: userInput.title_bg,
        en: userInput.title_en,
      },
      description: {
        bg: userInput.description_bg,
        en: userInput.description_en,
      },
    };

    this.setState(_newState);
  }
  validationCheck = this.validationCheck.bind(this);
  _grabUserInput() {
    return {
      title_bg: this.refs.title_bg.value,
      title_en: this.refs.title_en.value,
      description_bg: this.refs.description_bg.value,
      description_en: this.refs.description_en.value,
    };
  }

  render() {
    return (
      <Form>
        <FormGroup label={"add title"}>
          <Input
            ref="title_bg"
            autoComplete="off"
            type="text"
            placeholder={"add title bg"}
            required
            defaultValue={this.state.title.bg}
          />
          <Input
            ref="title_en"
            autoComplete="off"
            type="text"
            placeholder={"add title en"}
            required
            defaultValue={this.state.title.en}
          />
        </FormGroup>
        <FormGroup label={"description"}>
          <Textarea
            ref="description_bg"
            autoComplete="off"
            placeholder={"add description bg"}
            required
            defaultValue={this.state.description.bg}
            onBlur={this.validationCheck}
          />
          <Textarea
            ref="description_en"
            autoComplete="off"
            placeholder={"add description en"}
            required
            defaultValue={this.state.description.en}
            onBlur={this.validationCheck}
          />
        </FormGroup>
      </Form>
    );
  }
}

export default AddUrbidInfo;
