

/** @jsx jsx */
import React from 'react';
import { CAPTION } from '../common/typography';
import { jsx, css } from '@emotion/core';

const Tag = ({ text }) => <CAPTION css={css` color: black; `}>#{ text }</CAPTION>;

const TagList = ({ tags }) => (
  <div 
    css={css`
    
    `}>
    { tags && tags.map(tag => <Tag text = {tag} key = {tag} /> ) }
  </div>
);

export default TagList;
