/** @jsx jsx */

import React, { Component } from 'react';
import { jsx, css } from '@emotion/core';
import GoogleMapReact from 'google-map-react';
import MdPin from 'react-ionicons/lib/MdPin';
import { colorNominations } from '../common/colors';

import NominationStripe from './NominationStripe';

const NominationMarker = ({ isActive }) => (
  <MdPin
    color = {isActive ? colorNominations : '#353535'}
    fontSize = {isActive ? '42px' : '30px'}
  />
);

const defaultProps = {
  zoom: 14,
  center: {
    lat: 42.70,
    lng: 23.32
  }
};

class NominationsMap extends Component {
  state = {
    activeMarker: null,
    center: defaultProps.center
  }

  activeMarker = 0;

  componentDidMount() {
    console.log(this.props.nominations, this.activeMarker);
    let center = this.props.nominations[this.activeMarker].address.position;
    this.setState({ center });
  }

  createMapOptions = (maps) => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      clickableIcons: false,
      styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
  }

  _setMapCenter = (index) => {
    const { nominations } = this.props;
    const { lat, lng } = nominations[index].address.position;

    this.activeMarker = Number(index);
    this.setState({
      center: { lat, lng }
    });
  }
  setMapCenter = this._setMapCenter.bind(this);

  render() {
    const { nominations } = this.props;
    const { center } = this.state;

    return (
      <div 
        css={css`
          height: calc(100vh - 90px);
          width: 100%;
          position: relative;
        `}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDLJR14hApMiSCahjE4iiDZkhrHbLZGhm4' }}
          defaultCenter={defaultProps.center}
          center={center}
          defaultZoom={defaultProps.zoom}
          options = {this.createMapOptions}
          onChildClick = {this.setMapCenter}
        >
          { nominations.map((nomination, index) => {
            const { lat, lng } = nomination.address.position;
            const isActive = (this.activeMarker === Number(index));

            return(
              <NominationMarker
                key={index}
                lat={lat}
                lng={lng}
                isActive={isActive}
              />
            )
          }) }
        </GoogleMapReact>

        <NominationStripe
          nominations={nominations}
          onStripeInteraction={this.setMapCenter}
          activeMarker={this.activeMarker}
         />
      </div>
    );
  }
}

export default NominationsMap;
