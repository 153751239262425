/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import { addNomination } from "../../language/content";
import { FormGroup, Form, Input, Textarea } from "./Form";

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.getStore().title,
      description: this.props.getStore().description,
    };
    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    const userInput = this._grabUserInput();
    const validateNewInput = this._validateData(userInput);
    let isDataValid = false;

    // if full validation passes then save to store and pass as valid
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      this.props.updateStore({
        ...userInput,
      });
      isDataValid = true;
    } else {
      this.setState(
        Object.assign(
          userInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }

    return isDataValid;
  }

  _validateData(data) {
    return {
      titleVal: data.title.length > 0,
      descriptionVal: data.description.length > 0,
    };
  }

  _validationErrors(val) {
    const { language } = this.props;
    const errMsgs = {
      titleValMsg: val.titleVal
        ? ""
        : addNomination.step1.title.error[language],
      descriptionValMsg: val.descriptionVal
        ? ""
        : addNomination.step1.description.error[language],
    };
    return errMsgs;
  }

  _grabUserInput() {
    return {
      title: this.refs.title.value,
      description: this.refs.description.value,
    };
  }

  render() {
    const { language } = this.props;
    const { titleVal, descriptionVal } = this.state;
    const titleHasError = !(typeof titleVal == "undefined" || titleVal);
    const descriptionHasError = !(
      typeof descriptionVal == "undefined" || descriptionVal
    );

    return (
      <Form>
        <FormGroup
          label={addNomination.step1.title.label[language]}
          hasError={titleHasError}
          errorMessage={addNomination.step1.title.error[language]}
        >
          <Input
            ref="title"
            autoComplete="off"
            type="text"
            placeholder={addNomination.step1.title.placeholder[language]}
            required
            defaultValue={this.state.title}
          />
        </FormGroup>
        <FormGroup
          label={addNomination.step1.description.label[language]}
          hasError={descriptionHasError}
          errorMessage={addNomination.step1.description.error[language]}
        >
          <Textarea
            ref="description"
            autoComplete="off"
            placeholder={addNomination.step1.description.placeholder[language]}
            required
            defaultValue={this.state.description}
            onBlur={this.validationCheck}
          />
        </FormGroup>
      </Form>
    );
  }
}

export default Step1;
