import { firestore, storageUrl } from "./firebase.js";
import {
  colorNominations,
  colorUrbids,
  colorRealizations,
} from "./components/common/colors";

export const collectIdsAndDocs = (doc) => {
  let _u = doc ? (doc.data() ? doc.data() : {}) : {};
  _u.id = doc ? doc.id : "";
  return { ..._u };
};

export const getDocRef = (collectionId, docId) => {
  return firestore.doc(`${collectionId}/${docId}`);
};

export const getColor = (color) => {
  let _color;
  switch (color) {
    case "white":
      _color = "white";
      break;
    case "nominations":
      _color = colorNominations;
      break;
    case "urbids":
      _color = colorUrbids;
      break;
    case "realizations":
      _color = colorRealizations;
      break;
    default:
      break;
  }

  return _color;
};

export const getImageSize = () => {
  let size = "sm";
  if (window.innerWidth > 1600) {
    size = "xl";
  } else if (window.innerWidth > 1200) {
    size = "lg";
  } else if (window.innerWidth > 768) {
    size = "md";
  }

  return size;
};

export const getFeaturedImageUrl = (imageName, postType, postId, size) => {
  if (!imageName) {
    console.error("no imageUrls passed to utilities");
    return;
  }
  return (
    storageUrl +
    postType +
    "%2F" +
    postId +
    "%2Fresized%2F" +
    getResizedImageName(imageName, size) +
    "?alt=media"
  );
};

export const getFullImageUrl = (imageName, postType, postId) => {
  return (
    storageUrl + postType + "%2F" + postId + "%2F" + imageName + "?alt=media"
  );
};

export const getImageUrl = (imageName, postType, postId, size) => {
  if (!imageName) {
    console.error("no imageUrls passed to utilities");
    return;
  }
  return (
    storageUrl +
    postType +
    "%2F" +
    postId +
    "%2Fresized%2F" +
    getResizedImageName(imageName, size) +
    "?alt=media"
  );
};

export const getResizedImageName = (imageName, size) => {
  let extension = "";

  switch (size) {
    case "sm":
      extension = "400x400";
      break;
    case "md":
      extension = "600x600";
      break;
    case "lg":
      extension = "1200x1200";
      break;
    case "xl":
      extension = "1600x1600";
      break;
    default:
      extension = "400x400";
      break;
  }

  return imageName
    .replace(".JPG", "_" + extension + ".JPG")
    .replace(".jpg", "_" + extension + ".jpg")
    .replace(".png", "_" + extension + ".png")
    .replace(".jpeg", "_" + extension + ".jpeg");
};

export const queryBySlug = (collection, slug, callback) => {
  firestore
    .collection(collection)
    .where("slug", "==", slug)
    .get()
    .then((qs) => {
      qs.forEach(callback);
    });
};

export const subscribeBySlug = (collection, slug, callback) => {
  console.log(collection, slug, callback);
  firestore
    .collection(`${collection}`)
    .where("slug", "==", slug)
    .onSnapshot((qs) => {
      qs.forEach(callback);
    });
};

export const queryComments = (collection, slug, callback) => {};
