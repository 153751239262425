/** @jsx jsx */
import { Component } from "react";
import { firestore } from "../../firebase.js";
import { jsx, css } from "@emotion/core";

import { getImageUrl } from "../../utilities.js";
import { setPadding } from "../common/spacing";
import { colorRealizations } from "../common/colors";
import { H5, H4, CAPTION, H6 } from "../common/typography";
import UserThumbnail from "../elements/UserThumbnail";
import Card, { __Location, __Votes, __Comments } from "../elements/Card";
import withLanguage from "../withLanguage";
class ProposalCard extends Component {
  constructor(props) {
    super(props);
    this.state = { urbid: null };
  }

  componentDidMount() {
    const { urbidId } = this.props;
    firestore
      .collection("urbids")
      .doc(urbidId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({ urbid: { ...doc.data() } });
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  render() {
    const {
      title,
      id,
      urbidSlug,
      urbidId,
      user,
      slug,
      imageUrls,
      countComments,
      countVotes,
      status,
      projectInfo,
      sponsors,
      language,
    } = this.props;
    const { urbid } = this.state;
    const imageUrl = imageUrls
      ? getImageUrl(imageUrls[0], "proposals", id, "md")
      : null;
    const urbidImageUrl = urbid
      ? getImageUrl(urbid.imageUrls[0], "urbids", urbidId, "sm")
      : null;
    const isCompleted = status === "completed";
    const myUrl =
      status === "completed"
        ? `/completed/${slug}`
        : `/active/${urbid ? urbid.slug : ""}/proposals/${slug}`;

    return (
      <Card url={myUrl} imageUrl={imageUrl}>
        {isCompleted && (
          <div
            css={css`
              position: absolute;
              height: 40px;
              display: flex;
              align-items: center;
              z-index: 2;
              top: -40px;
              left: 0;
              background: ${colorRealizations};
              color: white;
              padding: 0px 15px;
            `}
          >
            <H5>изпълнен проект</H5>
          </div>
        )}
        <H4>{title[language]}</H4>
        <div>
          <UserThumbnail {...user} />
        </div>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            ${setPadding(["top"], "sm")}
          `}
        >
          <__Votes countVotes={countVotes} />
          <__Comments countComments={countComments} />
        </div>
      </Card>
    );
  }
}

export default withLanguage(ProposalCard);
