/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import { firestore, storage } from "../../firebase.js";
import withUser from "../withUser";
import StepZilla from "react-stepzilla";
import { setMargin } from "../common/spacing";
import { transliterate as tr, slugify } from "transliteration";
import {
  Container,
  Section,
  Row,
  Half,
  Full,
  Third,
  TwoThirds,
} from "../common/layouts";

import _AddUrbidInfo from "../forms/_AddUrbidInfo";
import _AddUrbidImages from "../forms/_AddUrbidImages";
import _AddUrbidDetails from "../forms/_AddUrbidDetails";
import _AddUrbidSponsors from "../forms/_AddUrbidSponsors";
import _AddUrbidThankYou from "../forms/_AddUrbidThankYou";

const defVals = {
  title: {
    bg: "title",
    en: "title",
  },
  description: {
    bg: "description",
    en: "decription",
  },
  address: {
    city: {
      bg: "София",
      en: "Sofia",
    },
    neighborhood: {
      bg: "Иван Вазов",
      en: "Ivan Vazov",
    },
    formattedAddress: {
      bg: "бул. България, София",
      en: "bul. Bulgaria, Sofia",
    },
    position: {
      lat: 42.6719191,
      lng: 23.292304,
    },
  },
  status: "pending",
  countComments: 0,
  countProposals: 0,
  createdAt: new Date(),
  imageUrls: [],
  slug: null,
};

class AddUrbidPage extends Component {
  constructor(props) {
    super(props);
    this.state = { currentStep: 0, urbidId: null };
    this.sampleStore = defVals;
    this.uploadToFB = this.uploadToFB.bind(this);
  }

  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update,
    };

    // create urbid on step 1
    if (this.state.currentStep === 0) {
      firestore
        .collection("urbids")
        .add({})
        .then((docRef) => {
          console.log("made urbid with id ", docRef.id);
          this.setState({ urbidId: docRef.id });
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
    //console.log("update store ", this.state.currentStep, this.sampleStore);
    if (this.state.currentStep === 2) {
      this.uploadToFB();
    }
  }

  uploadToFB() {
    const { urbidId } = this.state;
    const { uid, displayName, photoUrl, email } = this.props.user || {};

    let urbid = {
      title: this.sampleStore.title,
      description: this.sampleStore.description, // not sure
      address: this.sampleStore.address,
      imageUrls: this.sampleStore.imageUrls,
      countVotes: 0,
      countComments: 0,
      user: { uid, displayName, photoUrl, email },
      createdAt: new Date(),
      status: "pending",
      slug: null,
    };

    urbid.slug = slugify(urbid.title.bg);
    console.log("upload to fb", urbidId, urbid);
    firestore
      .collection("urbids")
      .where("slug", "==", urbid.slug)
      .get()
      .then((qs) => {
        if (!qs.empty) {
          urbid.slug += "-2";
        }
        firestore.doc(`urbids/${urbidId}`).update(urbid);
        this.sampleStore = defVals;
      });
  }

  render() {
    const { language } = this.props;
    const { currentStep } = this.state;

    const steps = [
      {
        name: "add title",
        component: (
          <_AddUrbidInfo
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "add details",
        component: (
          <_AddUrbidDetails
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "add pics",
        component: (
          <_AddUrbidImages
            urbidId={this.state.urbidId}
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
      {
        name: "danke!!!",
        component: (
          <_AddUrbidThankYou
            getStore={() => this.getStore()}
            updateStore={(u) => {
              this.updateStore(u);
            }}
          />
        ),
      },
    ];
    return (
      <div
        css={css`
          ol.progtrckr {
            display: none;
          }

          input[type="file"] {
            height: 200px;
            width: 100%;

            &:hover,
            &:focus {
              outline: none !important;
            }
          }
        `}
      >
        <Section>
          <Container
            css={css`
              ${setMargin(["top", "bottom"], "lg")}
            `}
          >
            <StepZilla
              steps={steps}
              preventEnterSubmission={true}
              nextTextOnFinalActionStep="final!"
              prevBtnOnLastStep={false}
              stepsNavigation={false}
              onStepChange={(step) => {
                this.setState({ currentStep: step });
                window.sessionStorage.setItem("step", step);
              }}
            />
          </Container>
        </Section>
      </div>
    );
  }
}

export default withUser(AddUrbidPage);
