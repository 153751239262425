/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import { withRouter, Link } from "react-router-dom";
import Urbid from "./Urbid";
import { CardSectionAll } from "../elements/Card";
import withLanguage from "../withLanguage";

// import ProposalPopup from '../proposals/ProposalPopup';
import orderBy from "lodash/orderBy";
import { firestore } from "../../firebase.js";
import {
  collectIdsAndDocs,
  getImageUrl,
  queryBySlug,
  subscribeBySlug,
} from "../../utilities.js";

import { Section, SectionHeader } from "../common/layouts";
import { H4 } from "../common/typography";
import { common } from "../../language/content";

import ProposalCard from "../proposals/ProposalCard";

class UrbidPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  unsubscribeFromComments = null;
  unsubscribeFromUrbid = null;

  get slug() {
    const { match } = this.props;
    return match ? match.params.slug : null;
  }

  get commentsRef() {
    return firestore.collection(`urbids/${this.slug}/comments`);
  }

  replaceUrl(one, title, url) {
    //window.history.pushState(one, title, url);
  }

  componentDidMount() {
    // get urbid info
    this.unsubscribeFromUrbid = firestore
      .collection(`urbids`)
      .where("slug", "==", this.slug)
      .onSnapshot((qs) => {
        this.setState({ id: qs.docs[0].id, ...qs.docs[0].data() });
      });

    queryBySlug("urbids", this.slug, (doc) => {
      this.unsubscribeFromComments = firestore
        .collection(`urbids/${doc.id}/comments`)
        .onSnapshot((qs) => {
          const comments = orderBy(
            qs.docs.map(collectIdsAndDocs),
            "createdAt",
            "desc"
          );
          this.setState({
            comments,
          });
        });

      // get proposals
      firestore
        .collection("proposals")
        .where("urbidId", "==", doc.id)
        .get()
        .then((snapshot) => {
          const proposals = snapshot.docs.map(collectIdsAndDocs);
          this.setState({ proposals });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromUrbid();
    this.unsubscribeFromComments();
  }

  render() {
    const { id, title, address, imageUrls, proposals } = this.state;
    const { language } = this.props;
    return (
      <section>
        {title && <Urbid {...this.state} id={this.state.id} />}
        {proposals && proposals.length > 0 && (
          <SectionHeader>
            <H4
              css={css`
                margin-bottom: 0px !important;
              `}
            >
              {common.proposals[language]}
            </H4>
          </SectionHeader>
        )}
        <CardSectionAll>
          {proposals &&
            proposals.map((proposal) => (
              <ProposalCard
                {...proposal}
                urbidId={id}
                urbidSlug={this.slug}
                key={proposal.id}
              />
            ))}
        </CardSectionAll>
      </section>
    );
  }
}

export default withLanguage(withRouter(UrbidPage));
