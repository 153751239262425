/** @jsx jsx */
import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import { Switch, Route, withRouter } from "react-router-dom";
import UserProfile from "./UserProfile";
import Urbids from "./urbids/Urbids";
import UrbidPage from "./urbids/UrbidPage";
import Nominations from "./nominations/Nominations";
import NominationPage from "./nominations/NominationPage";
import AddNominationPage from "./nominations/AddNominationPage";
import StaticPage from "./pages/StaticPage";
import PostsPage from "./pages/PostsPage";
import PostPage from "./pages/PostPage";
import AddProposalPage from "./proposals/AddProposalPage";
import ProposalPage from "./proposals/ProposalPage";
import HomePage from "./HomePage";
import Admin from "./admin/Admin";
import PostManager from "./admin/PostManager";
import AddPhotosToPost from "./admin/AddPhotosToPost";
import AddUrbidPage from "./urbids/AddUrbidPage";
import Realizations from "./realizations/Realizations";
import RealizationPage from "./realizations/RealizationPage";
import ScrollToTop from "./ScrollToTop";
import PickLocation from "./pages/PickLocation";
import NotFoundPage from "./pages/NotFoundPage.jsx";
import EditorPage from "./forms/EditorPage.jsx";
import DownloadAppPage from "./pages/DownloadAppPage.jsx";
import DonatePage from "./pages/DonatePage.jsx";
import Layout from "./layouts/Layout";
import { ToastContainer } from "react-toastify";
//<Route exact path="/admin" component={Admin} />
class Application extends Component {
  render() {
    return (
      <main className="Application">
        <ScrollToTop>
          <Layout>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/en" render={() => <HomePage lang="en" />} />
              <Route exact path="/blog" component={PostsPage} />
              <Route exact path="/blog/:slug" component={PostPage} />
              <Route exact path="/nominations" component={Nominations} />
              <Route exact path="/profile" component={UserProfile} />

              <Route
                exact
                path="/add-photos-to-post"
                component={AddPhotosToPost}
              />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/editor" component={EditorPage} />
              <Route exact path="/post-manager" component={PostManager} />
              <Route exact path="/admin/add-urbid" component={AddUrbidPage} />
              <Route
                exact
                path="/nominations/add"
                component={AddNominationPage}
              />
              <Route
                exact
                path="/nominations/:slug"
                component={NominationPage}
              />
              <Route exact path="/completed" component={Realizations} />
              <Route
                exact
                path="/completed/:slug"
                component={RealizationPage}
              />
              <Route exact path="/active" component={Urbids} />
              <Route exact path="/active/:slug" component={UrbidPage} />
              <Route
                path="/active/:slug/proposals/:proposal_slug"
                component={() => <ProposalPage />}
              />
              <Route
                exact
                path="/active/:slug/add-proposal"
                component={AddProposalPage}
              />
              <Route exact path="/pick-location" component={PickLocation} />
              <Route exact path="/download-app" component={DownloadAppPage} />
              <Route exact path="/support-us" component={DonatePage} />
              <Route exact path="/pages/:slug" component={StaticPage} />
              <Route path="*" component={NotFoundPage} />
            </Switch>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              css={css`
                .Toastify__toast--default {
                  background-color: #00cc66;
                  color: #353535;
                  font-family: "Intro Regular";
                  font-size: 16px;
                }

                .Toastify__progress-bar--default {
                  background: #353535;
                }
              `}
            />
          </Layout>
        </ScrollToTop>
      </main>
    );
  }
}

export default withRouter(Application);
