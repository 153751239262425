/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { H1, H2, H3, H4, PARAGRAPH } from "../common/typography";
import { Container } from "../common/layouts";
import { setPadding, setMargin } from "../common/spacing";
import {
  colorNominationsSecondary,
  colorUrbidsSecondary,
  colorRealizationsSecondary,
} from "../common/colors";
import Logo from "../elements/Logo";
import AddNominationPrompt from "../modules/AddNominationPrompt";
import { BoxLink } from "../elements/Links";
import { banners } from "../../language/content";
import withLanguage from "../withLanguage";

const ProcessStepCTA = ({ text, color, url }) => {
  return (
    <div
      css={css`
        position: absolute;
        right: 0;
        bottom: -60px;
        width: 150px;
        height: 150px;
      `}
    >
      <BoxLink color={color} textColor={"#353535"} url={url}>
        {text}
      </BoxLink>
    </div>
  );
};

const ProcessStep = ({ color, heading, paragraph, cta, stepNum, url }) => (
  <div
    css={css`
      flex-basis: 0;
      flex-grow: 1;
      padding: 0px 15px;

      @media (max-width: 767px) {
        margin-bottom: 120px;
      }
    `}
  >
    <div
      css={css`
        background-color: #212121;
        padding: 30px 15px 120px;
        position: relative;
      `}
    >
      <div
        css={css`
          color: white;
        `}
      >
        <H1
          css={css`
            opacity: 0.35;
          `}
        >
          {stepNum}
        </H1>
        <H4
          css={css`
            ${setMargin(["bottom"], "md")}
          `}
        >
          {heading}
        </H4>
        <PARAGRAPH
          css={css`
            @media (min-width: 768px) {
              min-height: 180px;
            }
          `}
        >
          {paragraph}
        </PARAGRAPH>
      </div>
      <ProcessStepCTA color={color} text={cta} url={url} />
    </div>
  </div>
);

const _BannerSlogan = (props) => {
  console.log("props", props);
  const { language } = props;
  return (
    <section
      css={css`
        background-image: url("https://firebasestorage.googleapis.com/v0/b/urbid-app.appspot.com/o/proposals%2FmY4KPyFq6JiANyZnd3wf%2Fresized%2Ff0298f6f-547d-4c3e-bfe3-4b106ffad57d_1200x1200.jpg?alt=media");
        background-size: cover;
        background-position: center center;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: -90px;
        width: 100%;

        @media (max-width: 767px) {
          height: 95vh;
        }

        &:before {
          content: "";
          position: absolute;
          background: rgba(255, 255, 255, 0.65);
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
        `}
      >
        <Container>
          <H2
            css={css`
              max-width: 840px;
              ${setMargin(["bottom"], "md")}
            `}
            dangerouslySetInnerHTML={{
              __html: banners.slogan[language],
            }}
          />
          <AddNominationPrompt />
        </Container>
      </div>
    </section>
  );
};

export const BannerSlogan = withLanguage(_BannerSlogan);

const BannerProcess = (props) => {
  const { language } = props;
  const title = {
    bg: `Промяна в три стъпки`,
    en: `Change in three steps`,
  };
  return (
    <section
      css={css`
        margin-top: -90px;
        z-index: 15;
        position: relative;
      `}
    >
      <Container>
        <div
          css={css`
            background-color: #353535;
            display: flex;

            @media (max-width: 767px) {
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              flex-basis: 0;
              flex-grow: 1;
              color: white;
              ${setPadding("all", "md")}
              svg {
                width: 100% !important;
                max-width: 120px;
                opacity: 0.35;

                @media (max-width: 767px) {
                  max-width: 90px;
                }

                path {
                  fill: white;
                }
              }

              @media (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
              }
            `}
          >
            <Logo />
            <H4
              css={css`
                opacity: 0.35;
                margin-top: 15px;
                margin-bottom: 0px !important;
              `}
            >
              {title[language]}
            </H4>
          </div>

          <ProcessStep
            stepNum="1"
            color={colorNominationsSecondary}
            heading={banners.process.nominations.heading[language]}
            cta={banners.process.nominations.cta[language]}
            paragraph={banners.process.nominations.paragraph[language]}
            url={"/nominations"}
          />
          <ProcessStep
            stepNum="2"
            color={colorUrbidsSecondary}
            heading={banners.process.urbids.heading[language]}
            cta={banners.process.urbids.cta[language]}
            paragraph={banners.process.urbids.paragraph[language]}
            url={"/active"}
          />
          <ProcessStep
            stepNum="3"
            color={colorRealizationsSecondary}
            heading={banners.process.realizations.heading[language]}
            cta={banners.process.realizations.cta[language]}
            paragraph={banners.process.realizations.paragraph[language]}
            url={"/completed"}
          />
        </div>
      </Container>
    </section>
  );
};

export default withLanguage(BannerProcess);
