import React, { Component, createContext } from "react";

export const LanguageContext = createContext({ language: "bg" });

class LanguageProvider extends Component {
  state = { language: "bg" };

  _toggleLanguage = () => {
    let language = this.state.language === "bg" ? "en" : "bg";
    this.setState({ language });
  };
  toggleLanguage = this._toggleLanguage.bind(this);

  _setLanguage = (lang) => {
    this.setState({ language: lang });
  };
  setLanguage = this._setLanguage.bind(this);

  render() {
    const { language } = this.state;
    const { children } = this.props;
    const childrenProps = {
      language: language,
      toggleLanguage: this.toggleLanguage,
      setLanguage: this.setLanguage,
    };

    return (
      <LanguageContext.Provider value={childrenProps}>
        {children}
      </LanguageContext.Provider>
    );
  }
}

export default LanguageProvider;
