import React, { Component, createContext } from "react";
import { firestore } from "../firebase.js";
import { collectIdsAndDocs } from "../utilities.js";
import orderBy from "lodash/orderBy";

export const UrbidsContext = createContext();

class UrbidsProvider extends Component {
  state = {
    urbids: [],
  };

  unsubscribeFromFirestore = null;

  componentDidMount = () => {
    this.unsubscribeFromFirestore = firestore
      .collection("urbids")
      .onSnapshot((snapshot) => {
        let urbids = snapshot.docs.map(collectIdsAndDocs);
        urbids = orderBy(urbids, "createdAt", "desc");
        this.setState({ urbids });
      });
  };

  componentWillUnmount = () => {
    this.unsubscribeFromFirestore();
  };

  render() {
    const { urbids } = this.state;
    const { children } = this.props;

    return (
      <UrbidsContext.Provider value={urbids}>{children}</UrbidsContext.Provider>
    );
  }
}

export default UrbidsProvider;
