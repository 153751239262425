/** @jsx jsx */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";
import { ColorLink } from "../elements/Links";

import { setPadding, setMargin } from "../common/spacing";
import {
  Container,
  Half,
  Row,
  Third,
  TwoThirds,
  Full,
  Section,
} from "../common/layouts";
import { H3, PARAGRAPH, H4, H5 } from "../common/typography";
import ImageSlider from "../elements/Slider";
import { colorRealizations } from "../common/colors";
import withLanguage from "../withLanguage";

const proposalFor = {
  bg: "предложение към ",
  en: "proposal for ",
};

class LayoutSingle extends Component {
  static Header = withLanguage(
    ({
      backToUrl,
      backToText,
      title,
      topLeft,
      bottomLeft,
      right,
      urbidImageUrl,
      urbidTitle,
      language,
      isRealization,
    }) => {
      return (
        <Section
          css={css`
            ${setPadding(["top", "bottom"], "lg")}
            background-color: #cdcdcd;
          `}
        >
          <Container>
            <div
              css={css`
              background-color: #353535;
              ${setPadding(["left", "right"], "sm")}
              ${setPadding(["top", "bottom"], "lg")}
              ${setMargin(["top", "bottom"], "lg")}
              color: white;
              position: relative;

              @media (min-width: 768px) {
                margin: 60px -30px;
                ${setPadding(["left", "right"], "md")}
                ${setMargin(["top"], "sm")}
              }
            `}
            >
              <Link to={backToUrl}>
                <H5
                  css={css`
                    margin-bottom: 0px !important;
                    @media (min-width: 768px) {
                      max-width: 450px;
                    }
                  `}
                  dangerouslySetInnerHTML={{
                    __html: `${proposalFor[language]}<br>“${urbidTitle}”`,
                  }}
                />
              </Link>
              <div
                css={css`
                  position: absolute;
                  margin-top: -22px;
                  top: 100%;
                  left: 30px;

                  @media (max-width: 767px) {
                    left: 0px;
                  }
                `}
              >
                {!isRealization && (
                  <ColorLink
                    color={isRealization ? "realizations" : "urbids"}
                    url={backToUrl}
                  >
                    {backToText}
                  </ColorLink>
                )}
                {isRealization && (
                  <div
                    css={css`
                      background-color: ${colorRealizations};
                      color: white;
                      height: 40px;
                      padding: 0px 15px;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <H5>{isRealization}</H5>
                  </div>
                )}
              </div>
              <div
                css={css`
                  width: 300px;
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  background-image: url(${urbidImageUrl});
                  background-size: cover;
                  background-position: center center;

                  @media (max-width: 767px) {
                    width: 120px;
                    top: -45px;
                    height: 90px;
                    left: 15px;
                  }
                `}
              ></div>
            </div>
            <Row>
              <Half>
                <div
                  css={css`
                    height: 100%;
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media (max-width: 767px) {
                      ${setMargin(["bottom"], "md")};
                      min-height: 0px;
                    }
                  `}
                >
                  <div>
                    <H3
                      css={css`
                        ${setMargin(["bottom"], "md")}
                      `}
                    >
                      {title}
                    </H3>
                    <div>{topLeft}</div>
                  </div>
                  <div>{bottomLeft}</div>
                </div>
              </Half>
              <Half
                css={css`
                  position: relative;
                `}
              >
                {right}
              </Half>
            </Row>
          </Container>
        </Section>
      );
    }
  );

  static Content = ({ description }) => (
    <Section>
      <Container>
        <Row>
          <TwoThirds>
            <PARAGRAPH dangerouslySetInnerHTML={{ __html: description }} />
          </TwoThirds>
        </Row>
      </Container>
    </Section>
  );

  static ImageSlider = ({ children }) => <Section>{children}</Section>;

  static Footer = ({ imageUrls, postType, postId }) => (
    <div>
      {imageUrls && imageUrls.length > 1 && (
        <Section>
          <ImageSlider
            imageUrls={imageUrls}
            postType={postType}
            postId={postId}
          />
        </Section>
      )}
    </div>
  );

  render() {
    return (
      <article>
        {React.Children.map(this.props.children, (child) => {
          return React.cloneElement(child, { ...child.props });
        })}
      </article>
    );
  }
}

export default LayoutSingle;
