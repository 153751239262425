/** @jsx jsx */

import { useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { jsx, css } from "@emotion/core";

/* data */
import { NominationsContext } from "../../providers/NominationsProvider";

/* compound */
import NominationsList from "./NominationsList";
import NominationsMap from "./NominationsMap";

/* styles */
import { Container, SectionHeader } from "../common/layouts";
import { H2, PARAGRAPH } from "../common/typography";
import { setMargin } from "../common/spacing";
import withLanguage from "../withLanguage";
import { nominationsPage, common, descriptions } from "../../language/content";

import AddNominationPrompt from "../modules/AddNominationPrompt";

const Nominations = ({ language }) => {
  const nominations = useContext(NominationsContext);

  return (
    <section
      css={css`
        /* tabs styles */
        /* can't do inline because they're generative */
        .react-tabs__tab-list {
          border-bottom: none;
          ${setMargin(["bottom"], "sm")}
        }

        li.react-tabs__tab {
          font-size: 16px;
          color: #7c7c7c;

          &.react-tabs__tab--selected {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid black;
            color: black;
          }
        }
      `}
    >
      <SectionHeader>
        <H2>{common.nominations[language]}</H2>
        <PARAGRAPH
          css={css`
            max-width: 800px;
            ${setMargin(["top", "bottom"], "md")}
          `}
          dangerouslySetInnerHTML={{
            __html: descriptions.nominations[language],
          }}
        />
        <AddNominationPrompt />
      </SectionHeader>

      <Tabs>
        <TabList>
          <Container>
            <Tab>{nominationsPage.tabs.list[language]}</Tab>
            <Tab>{nominationsPage.tabs.map[language]}</Tab>
          </Container>
        </TabList>

        <TabPanel>
          {nominations.length > 0 && (
            <NominationsList nominations={nominations} />
          )}
        </TabPanel>
        <TabPanel>
          {nominations.length > 0 && (
            <NominationsMap nominations={nominations} />
          )}
        </TabPanel>
      </Tabs>
    </section>
  );
};

export default withLanguage(Nominations);
