import React, { createContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastContext = createContext();

const ToastProvider = ({ children }) => (
  <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>
);

export default ToastProvider;
