/** @jsx jsx */
import { Component, useContext, useEffect } from "react";
import { firestore } from "../firebase.js";
import { collectIdsAndDocs } from "../utilities.js";
import _ from "lodash";
import { css, jsx } from "@emotion/core";
import { LanguageContext } from "../providers/LanguageProvider";

import RealizationCard from "./realizations/RealizationCard";
import { CardSection } from "./elements/Card";
import { NominationCard } from "./nominations/NominationCard";
import ActiveCard from "./urbids/ActiveCard";
import { H3, PARAGRAPH } from "./common/typography";
import { setMargin } from "./common/spacing";
import { Container, Section } from "./common/layouts";
import withLanguage from "./withLanguage";
import withUser from "./withUser";
import { common, descriptions } from "../language/content";
import BannerNominations from "./banners/BannerNominations.jsx";
import BannerDIY from "./banners/BannerDIY.jsx";
import BannerProcess, { BannerSlogan } from "./banners/BannerProcess.jsx";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

const LanguageReader = ({ lang }) => {
  const { setLanguage } = useContext(LanguageContext);
  useEffect(() => {
    if (lang === "en") {
      setLanguage("en");
    } else {
      setLanguage("bg");
    }
  }, [lang]);
  return <span></span>;
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nominations: [],
      urbids: [],
      proposals: [],
      realizations: [],
    };

    this.toggle = this.toggle.bind(this);
  }

  unsubscribeFromNominations = null;
  unsubscribeFromUrbids = null;
  unsubscribeFromProposals = null;

  componentDidMount = () => {
    this.unsubscribeFromNominations = firestore
      .collection("nominations")
      .where("status", "==", "active")
      .where("showOnHome", "==", true)
      .onSnapshot((snapshot) => {
        const nominations = snapshot.docs.map(collectIdsAndDocs);
        this.setState({ nominations });
      });

    this.unsubscribeFromUrbids = firestore
      .collection("urbids")
      .where("showOnHome", "==", true)
      .onSnapshot((snapshot) => {
        const urbids = snapshot.docs.map(collectIdsAndDocs);
        this.setState({
          urbids: urbids,
        });
      });

    this.unsubscribeFromProposals = firestore
      .collection("proposals")
      .where("status", "==", "completed")
      .onSnapshot((snapshot) => {
        const proposals = snapshot.docs.map(collectIdsAndDocs);
        let realizations = filter(proposals, { status: "completed" });
        realizations = orderBy(realizations, "createdAt", "desc");

        this.setState({
          realizations: realizations,
        });
      });
  };

  componentWillUnmount = () => {
    this.unsubscribeFromNominations();
    this.unsubscribeFromUrbids();
    this.unsubscribeFromProposals();
  };

  toggle = (trueOrFalse) => {
    this.setState({ isOpen: trueOrFalse });
  };

  render() {
    const { nominations, urbids, realizations } = this.state;
    const { language, lang } = this.props;

    return (
      <div>
        <BannerSlogan />
        <BannerProcess />
        <LanguageReader lang={lang} />
        <Section
          css={css`
            ${setMargin(["top"], "lg")}
            ${setMargin(["bottom"], "md")}
          `}
        >
          <Container>
            <H3
              css={css`
                ${setMargin(["top"], "lg")}
                ${setMargin(["bottom"], "md")}
              `}
            >
              {common.realizations[language]}
            </H3>
            <PARAGRAPH
              css={css`
                ${setMargin(["bottom"], "lg")}
              `}
              dangerouslySetInnerHTML={{
                __html: descriptions.realizations[language],
              }}
            />
          </Container>
          <Container>
            {realizations && realizations.length > 0 && (
              <RealizationCard key={realizations[0].id} {...realizations[0]} />
            )}
          </Container>
        </Section>

        <BannerNominations />

        <Section>
          <Container>
            <H3
              css={css`
                ${setMargin(["bottom"], "sm")}
              `}
            >
              {common.nominations[language]}
            </H3>
            <PARAGRAPH
              css={css`
                ${setMargin(["bottom"], "lg")}
              `}
              dangerouslySetInnerHTML={{
                __html: descriptions.nominations[language],
              }}
            />
          </Container>
          <CardSection viewMoreUrl="/nominations" color="nominations">
            {nominations &&
              nominations.map((nomination) => (
                <NominationCard key={nomination.id} {...nomination} />
              ))}
          </CardSection>
        </Section>

        <Section>
          <Container>
            <H3
              css={css`
                ${setMargin(["top"], "lg")}
                ${setMargin(["bottom"], "md")}
              `}
            >
              {common.urbids[language]}
            </H3>
            <PARAGRAPH
              css={css`
                ${setMargin(["bottom"], "lg")}
              `}
              dangerouslySetInnerHTML={{
                __html: descriptions.urbids[language],
              }}
            />
          </Container>
          <Container>
            {urbids &&
              urbids.map((urbid) => <ActiveCard key={urbid.id} {...urbid} />)}
          </Container>
        </Section>
        <BannerDIY />
      </div>
    );
  }
}

export default withUser(withLanguage(HomePage));
// za boris => dokumentaciq za endpoints, taka predstaweni qsni li sa
// da se dogovori s henrik za srokowe
// moje li da se orientira po source za neshtata, kakto sa napraweni i
// dokumentirani li sa prawilno
// dokumentaciqta ne e prioritet i shte otneme wreme
/*

<Section>
          <Container>
            <H4
              css={css`
                ${setMargin(['bottom'], 'xs')}
              `}>
              {startCase(common.proposals[language])}
            </H4>
          </Container>
          <CardSection  
            viewMoreUrl='/nominations'
            color='nominations'>
            { proposals && proposals.map(proposal => (
              <ProposalCard
                key={proposal.id}
                {...proposal}
              />
            ))}
          </CardSection>
        </Section>
*/
