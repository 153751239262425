/** @jsx jsx */
import React from "react";
import { jsx, css } from "@emotion/core";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import Button from "../elements/Button";
import LogoEuro from "react-ionicons/lib/LogoEuro";
import { firestore } from "../../firebase.js"; // record transaction
import { TITLE_SMALL, TITLE_MEDIUM, CAPTION } from "../common/typography";
import { colorUrbids } from "../common/colors";
import SupportModal from "./_SupportModal";
import { forEach } from "lodash";
import withLanguage from "../withLanguage";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const CLIENT = {
  sandbox:
    "AbH_pzqgauF4rSPZl2TpLrPuxA-B0IWLIjrnf56x3CTaDqBsA930hco8vvNBaJ4rl1Mj50BjF8TJaNYA",
  production:
    "AR0CU1dhiQrliq_J4_7XLUspPEHLhpRfsjE6oPDyI9GemzK6yjXBxUVVbL45CT5yuFcWxTn97KKYEM4h",
};

const CLIENT_ID =
  process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;
//create button here
let PayPalButton = null;

// next create the class and Bind React and ReactDom to window
//as we will be needing them later

class SupportProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paying: false,
      paid: false,
      paymentValue: 10,
      paymentNote: null,
      paymentName: null,
      supportSum: null,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed, postId } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false });
    }

    firestore
      .doc(`urbids/${postId}`)
      .get()
      .then((doc) => {
        let support = doc.data().support;
        let sum = 0;
        forEach(support, (transaction) => {
          if (transaction.value && Number(transaction.value) > 0) {
            sum += Number(transaction.value);
            console.log(transaction.value);
          }
        });
        this.setState({ supportSum: sum });
      });
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false });
      }
    }
  }

  recordTransaction = ({ payerID }) => {
    // record transaction
    const { postType, postId } = this.props;
    const { paymentValue, paymentNote, paymentName } = this.state;
    firestore
      .doc(`${postType}/${postId}`)
      .get()
      .then((doc) => {
        let _data = doc.data();
        if (_data.support === undefined) {
          _data.support = [];
        }
        firestore.doc(`${postType}/${postId}`).update({
          support: [
            ..._data.support,
            {
              type: "paypal",
              payerId: payerID,
              payerName: paymentName,
              value: paymentValue,
              note: paymentNote,
            },
          ],
        });
      });
  };

  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: +"support urbid",
          amount: {
            currency_code: "EUR",
            value: this.state.paymentValue,
          },
        },
      ],
    });
  };

  onApprove = (data, actions) => {
    console.log("order was approved");
    actions.order.capture().then((details) => {
      console.log("captured");
      this.setState({ paid: true });
      this.recordTransaction({ payerID: data.payerID });
    });
  };

  handlePaymentValueChange = (event) => {
    this.setState({ paymentValue: event.target.value });
  };

  handlePaymentNoteChange = (event) => {
    this.setState({ paymentNote: event.target.value });
  };

  handlePaymentNameChange = (event) => {
    this.setState({ paymentName: event.target.value });
  };

  render() {
    const { showButtons, loading, paid, paying, supportSum } = this.state;
    const { color, language } = this.props;

    const supportCTA = {
      en: "support project",
      bg: "подкрепи проекта",
    };

    const supportSumLabel = {
      en: "total",
      bg: "събрани",
    };

    const viewSupportersLabel = {
      en: "view all supporters",
      bg: "виж всички дарители",
    };

    return (
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 767px) {
              flex-direction: column;
              align-items: flex-start;
              margin-top: 60px;
            }
          `}
        >
          <Button
            style="accent"
            color="urbids"
            onClick={() => {
              this.setState({ showButtons: true });
            }}
          >
            {supportCTA[language]}
          </Button>
          <div
            css={css`
              margin-left: 45px;

              @media (max-width: 767px) {
                margin-left: 0px;
                margin-top: 24px;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <TITLE_MEDIUM css={css``}>
                {supportSumLabel[language]}: {supportSum}
              </TITLE_MEDIUM>
              <LogoEuro fontSize="24px" color="white" />
            </div>
            <button
              css={css`
                color: ${colorUrbids};
              `}
              onClick={() => {
                console.log("view");
                gsap.to(window, 1, {
                  scrollTo: "#supporters",
                });
              }}
            >
              <CAPTION>{viewSupportersLabel[language]}</CAPTION>
            </button>
          </div>
        </div>

        {showButtons && (
          <SupportModal
            color={color}
            paid={paid}
            paying={paying}
            paymentValue={this.state.paymentValue}
            handlePaymentNoteChange={this.handlePaymentNoteChange}
            handlePaymentValueChange={this.handlePaymentValueChange}
            handlePaymentNameChange={this.handlePaymentNameChange}
            onCloseClick={() => {
              this.setState({ showButtons: false });
            }}
          >
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </SupportModal>
        )}
      </div>
    );
  }
}
export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=AR0CU1dhiQrliq_J4_7XLUspPEHLhpRfsjE6oPDyI9GemzK6yjXBxUVVbL45CT5yuFcWxTn97KKYEM4h&currency=EUR`
)(withLanguage(SupportProject));
