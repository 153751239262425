/** @jsx jsx */

import { useContext } from "react";
import { jsx, css } from "@emotion/core";
import { LanguageContext } from "../../providers/LanguageProvider";

import Location from "../elements/Location";

/* utilities */
import { getImageUrl } from "../../utilities.js";
import LayoutSingle from "../modules/LayoutSingle";
import SupportProject from "../modules/SupportProject";

import moment from "moment";
import "moment/locale/bg";

/* <SendProposal deadline={urbidInfo ? urbidInfo.deadline : null} /> */

const Urbid = ({
  id,
  title,
  address,
  position,
  description,
  tags,
  imageUrls,
  countVotes,
  status,
  countComments,
  urbidInfo,
  nominationId,
  comments,
  support,
}) => {
  const imageUrl = getImageUrl(
    imageUrls ? imageUrls[0] : "",
    "urbids",
    id,
    "lg"
  );
  const { language } = useContext(LanguageContext);
  const supportersLabel = {
    bg: "Дарители:",
    en: "Supporters:",
  };

  return (
    <LayoutSingle>
      <LayoutSingle.Header
        backToUrl={"/active"}
        title={title[language]}
        topLeft={
          <Location
            color="#d9d9d9"
            address={address.formattedAddress[language]}
            position={address.position}
          />
        }
        bottomLeft={
          <div>
            <SupportProject postType="urbids" postId={id} color="urbids" />
          </div>
        }
        right={
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={css`
                height: 100%;
                background-image: url(${imageUrl});
                background-size: cover;
                background-position: center center;
                min-height: 400px;
              `}
            />
          </div>
        }
      ></LayoutSingle.Header>
      <LayoutSingle.Content
        description={description[language]}
        support={support}
        supportersLabel={supportersLabel[language]}
      ></LayoutSingle.Content>
      <LayoutSingle.Footer
        imageUrls={imageUrls}
        postType={"urbids"}
        postId={id}
      />
    </LayoutSingle>
  );
};
export default Urbid;

/* <LayoutSingle.Footer
        imageUrls={imageUrls}
        postType={"urbids"}
        postId={id}
      /> */
/* details={
          urbidInfo &&
          urbidInfo.docs && (
            <Detail label={labels.docs[language]}>
              {urbidInfo.docs &&
                urbidInfo.docs.map((doc) => (
                  <a
                    href="#"
                    key={`${doc}`}
                    download
                    css={css`
                      display: flex;
                      align-items: center;

                      svg {
                        margin-right: 5px;
                      }
                    `}
                  >
                    <ICON icon="doc" size="18" />
                    {doc.docName}
                  </a>
                ))}
            </Detail>
          )
        } */
