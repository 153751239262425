/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { setPadding } from '../common/spacing';
import { ICON } from '../common/typography';
import Button from './Button';

export const TextArea = ({ name, placeholder, value, onChange }) => {
  return (
    <textarea 
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange} 
      css={css`
        background-color: #f7f7f7;
        min-height: 150px;
        width: 100%;
        border: none;
        box-shadow: none;
        outline: none!important;
        padding: 30px 15px;
        font-family: 'Intro Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 1.35em;
        color: #5c5c5c;
        margin-bottom: 15px;
      `} />
  )
};

export const Submit = ({ value }) => (
  <div css={css`
      position: relative;
    `}>
    <input
      type = "submit"
      value = {'<ICON icon="heart" /> submit'}
      css={css`
        font-family: 'Intro Regular Alt';
        background: transparent;
        border-bottom: 2px solid black;
        box-shadow: none;
        font-size: 16px;
        min-width: 100px;
        outline: none!important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        padding: 0px;
      `}/>
    <div 
      css={css`
        pointer-events: none;
      `}>
      <Button 
        onClick={() => {}}
        type='regular'
        icon='forward'
        color='nominations'>
        {value}
      </Button>
    </div>
    
  </div>
)