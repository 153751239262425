/** @jsx jsx */

import React, { useContext } from "react";
import { jsx, css } from "@emotion/core";
import { signOut } from "../../firebase.js";
import { Link } from "react-router-dom";
import { CAPTION } from "../common/typography";
import { LanguageContext } from "../../providers/LanguageProvider";
import { toast } from "react-toastify";
import { toasts } from "../../language/content";
import { profile } from "../../language/content";

const CurrentUser = ({ onLogOut }) => {
  const { language } = useContext(LanguageContext);
  return (
    <section
      css={css`
        display: flex;
      `}
    >
      <Link
        to="/profile"
        css={css`
          display: flex;
          align-items: center;
          margin-left: 15px;
        `}
      >
        <CAPTION
          css={css`
            color: inherit;
            opacity: 1;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid grey;
          `}
        >
          {profile.title[language]}
        </CAPTION>
      </Link>
      <button
        onClick={async () => {
          await signOut();
          onLogOut && onLogOut();
          toast(toasts.onSignOut[language]);
        }}
        css={css`
          font-family: "Intro Regular Alt";
          font-weight: 400;
          color: inherit;
        `}
      >
        {profile.exit[language]}
      </button>
    </section>
  );
};

export default CurrentUser;
