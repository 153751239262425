/** @jsx jsx */

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import firebase from "../../firebase.js";

import Button from "../elements/Button";
import withUser from "../withUser";
import withLanguage from "../withLanguage";
import { CAPTION, ICON } from "../common/typography";
import { prompts } from "../../language/content";

import InfoWindow from "../elements/InfoWindow";
import { SignInOrSignUp } from "../auth/SignInAndSignUp";

import { toast } from "react-toastify";
import { toasts } from "../../language/content";

class VoteButton extends Component {
  constructor(props) {
    super(props);
    this.state = { canUserVote: null, isOpen: false };
    this.vc = 0;
    this.recordVote = this.recordVote.bind(this);
  }

  componentDidMount() {
    if (this.vc === 0) {
      this.vc = this.props.voteCount;
    }
  }

  checkIfUserCanVote() {
    const { docRef, user } = this.props;

    if (user) {
      let voteRef = docRef.collection("votes").doc(user.uid);
      voteRef
        .get()
        .then((doc) => {
          this.setState({ canUserVote: !doc.exists });
        })
        .catch((error) => {
          console.log("Error getting vote doc.", error);
        });
    }
  }

  recordVote() {
    this.setState({ canUserVote: false });
    this.vc += 1;

    const { docRef, user, language } = this.props;
    docRef.collection("votes").doc(user.uid).set({});

    const increment = firebase.firestore.FieldValue.increment(1);
    docRef.update({ countVotes: increment });

    toast(toasts.onVote[language]);
  }

  render() {
    const { postType, voteCount, user, language } = this.props;
    const { canUserVote, isOpen } = this.state;

    // do it only once
    const vc = voteCount;
    user && this.state.canUserVote === null && this.checkIfUserCanVote();
    const buttonProps = user
      ? {
          onClick: canUserVote ? this.recordVote : () => {},
        }
      : {
          onClick: () => {
            this.setState({ isOpen: true });
          },
        };

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Button
          style="accent"
          color={postType || "nominations"}
          {...buttonProps}
        >
          {prompts.voteNomination[language]}
        </Button>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: 10px;
          `}
        >
          <ICON size="24" icon={canUserVote ? "heart-outline" : "heart"} />
          <CAPTION
            css={css`
              margin-left: 5px;
            `}
          >
            {this.vc}
          </CAPTION>
        </div>
        <InfoWindow
          isOpen={isOpen}
          color="nominations"
          onCloseClick={() => {
            this.setState({ isOpen: false });
          }}
        >
          <SignInOrSignUp />
        </InfoWindow>
      </div>
    );
  }
}

export default withUser(withLanguage(VoteButton));
