/** @jsx jsx */

import { useContext } from "react";
import { jsx, css } from "@emotion/core";

import moment from "moment";
import "moment/locale/bg";

/* utilities */
import { getImageUrl } from "../../utilities.js";
import { LanguageContext } from "../../providers/LanguageProvider";
import Detail from "../elements/Detail";

/* styles */
import LayoutSingle from "../modules/LayoutSingleProposal";
import { labels } from "../../language/content";

const Realization = ({
  id,
  title,
  description,
  urbid,
  urbidId,
  urbidSlug,
  imageUrls,
  projectInfo,
  user,
  countComments,
  countVotes,
  comments,
}) => {
  const imageUrl = imageUrls
    ? getImageUrl(imageUrls[0], "proposals", id, "lg")
    : "";
  const urbidImageUrl = urbid
    ? getImageUrl(urbid.imageUrls[0], "urbids", urbidId, "sm")
    : "";
  const { language } = useContext(LanguageContext);
  moment.locale(language);
  const deadline = moment(projectInfo.completedAt.toDate()).format("MMMM YYYY");

  const proposalFor = {
    bg: "предложение към ",
    en: "proposal for ",
  };

  const completedAt = {
    bg: "завършен през ",
    en: "completed in ",
  };

  return (
    <div>
      {title && (
        <LayoutSingle>
          <LayoutSingle.Header
            backToText={`към всички предложения и детайли`}
            backToUrl={`/active/${urbid && urbid.slug}`}
            isRealization={completedAt[language] + deadline}
            urbidImageUrl={urbidImageUrl}
            title={title[language]}
            urbidTitle={urbid.title[language]}
            right={
              <div
                css={css`
                  height: 100%;
                  background-image: url(${imageUrl});
                  background-size: cover;
                  background-position: center center;
                  min-height: 400px;
                `}
              ></div>
            }
            bottomLeft={
              <div>
                <Detail label={labels.designer[language]}>
                  {user && user.displayName}
                </Detail>
                {projectInfo && projectInfo.team && (
                  <Detail label={labels.team[language]}>
                    {projectInfo.team.map((teamMember) => `${teamMember}, `)}
                  </Detail>
                )}
              </div>
            }
          ></LayoutSingle.Header>
          <LayoutSingle.Content
            description={description[language]}
          ></LayoutSingle.Content>
          <LayoutSingle.Footer
            imageUrls={imageUrls}
            postType={"proposals"}
            postId={id}
          />
        </LayoutSingle>
      )}
    </div>
  );
};

export default Realization;
