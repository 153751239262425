/** @jsx jsx */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";
import Button from "../elements/Button";
import { LanguageContext } from "../../providers/LanguageProvider";
import { UserContext } from "../../providers/UserProvider";
import { prompts } from "../../language/content";
import InfoWindow from "../elements/InfoWindow";
import { SignInOrSignUp } from "../auth/SignInAndSignUp";

const AddNominationPrompt = () => {
  const user = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const [isOpen, toggle] = useState(false);
  const buttonProps = user
    ? {
        to: "/nominations/add",
      }
    : {
        onClick: () => {
          toggle(true);
        },
      };
  return (
    <div>
      <Button style="accent" color="nominations" {...buttonProps}>
        {prompts.addNomination[language]}
      </Button>
      <InfoWindow
        isOpen={isOpen}
        color="nominations"
        onCloseClick={() => {
          toggle(false);
        }}
      >
        <SignInOrSignUp />
      </InfoWindow>
    </div>
  );
};

export default AddNominationPrompt;

/* <HowItWorks what="nominations" />
      <InfoWindow
        isOpen={isOpen}
        color="nominations"
        onCloseClick={() => {
          toggle(false);
        }}
      >
        <SignInOrSignUp />
      </InfoWindow> */
