import React, { Component } from 'react';
import { firestore } from '../../firebase.js';
import AddProposal from './AddProposal';
import {Container} from '../common/layouts';
import { getFeaturedImageUrl } from '../../utilities.js';
import { withRouter, Link } from 'react-router-dom';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';

class AddProposalHeader extends Component {
  state = {
    urbid: null
  }

  get docId() {
    return this.props.urbidId;
  }

  get docRef() {
    return this.docId ? firestore.doc(`urbids/${this.docId}`) : null; //ds
  }

  componentDidMount() {
    if (this.docId) {
      this.docRef.get().then((doc) => {
        if (doc.exists) {
          let urbid = doc.data();
          urbid.id = this.docId;

          this.setState({ urbid });
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

      })
    }
  }

  render() {
    const { urbid } = this.state;

    const imageName = urbid ? urbid.imageUrls[0] : null;
    const imageUrl = imageName ? getFeaturedImageUrl(imageName, 'urbids', this.docId, 'sm') : null;
    const divStyle = { backgroundImage: 'url(' + imageUrl + ')' };

    return (
      <div className="urb-add-proposal__header">
        { urbid && <Container>
          <div className="urb-add-proposal__header--inner">
            <Link
              className="urb-add-proposal--back"
              to={`/urbids/${urbid.id}`}>
                <MdArrowBack className="urb-icon urb-icon--sm" />
                <div className="urb-add-proposal__header__urbid">
                  <div className="urb-add-proposal__header__image">
                    <img src={imageUrl} />
                  </div>
                  <div className="urb-add-proposal__header__info">
                    <h5>{ urbid.title.bg }</h5>
                  </div>
                </div>
            </Link>
            <div className="urb-add-proposal__header__pagetitle">
              <h2>Добави предложение за градска намеса</h2>
            </div>
          </div>
        </Container> }
      </div>
    )
  }
}

const AddProposalPage = ({ match }) => {


  const urbidId = match ? match.params.id : null;
  console.log('this props ', urbidId);

  return (
    <div className="urb-add-proposal">
      { urbidId &&
        <AddProposalHeader urbidId={urbidId} /> }
      { urbidId &&
        <AddProposal urbidId={urbidId} />
      }
    </div>
  )
}

export default withRouter(AddProposalPage);
