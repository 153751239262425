/** @jsx jsx */

import React, { Component, useState } from "react";
import { jsx, css } from "@emotion/core";
import withLanguage from "../withLanguage";
import MapPicker from "react-google-map-picker";
import NodeGeocoder from "node-geocoder";
import { addNomination } from "../../language/content";
import { FormGroup, FormStep, Form } from "../forms/Form";
import { PARAGRAPH, CAPTION } from "../common/typography";

// init defaults
const DefaultLocation = { lat: 42.69, lng: 23.32 };
const DefaultZoom = 14;

// init geocoder
var options = {
  provider: "google",
  httpAdapter: "https", // Default
  apiKey: "AIzaSyDymEQyulbBI8LiqGfJ9YKzLUo4jNIjcW0", // for Mapquest, OpenCage, Google Premier
  language: "bg",
  region: "BG",
  formatter: null,
};
var geocoder = NodeGeocoder(options);

class PickLocation extends Component {
  // const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  // const [location, setLocation] = useState(defaultLocation);
  // const[zoom, setZoom] = useState(DefaultZoom);
  state = {
    latitude: this.props.getStore().latitude,
    longitude: this.props.getStore().longitude,
    address: this.props.getStore().address,
    zoom: 14,
    canSubmit: "remains to be seen ;)",
  };

  _validateOnDemand = true;

  // function handleChangeLocation(lat, lng) {
  //   setLocation({ lat: lat, lng: lng });
  // }

  // function handleChangeZoom(newZoom) {
  //   setZoom(newZoom);
  // }

  // function handleResetLocation() {
  //   setDefaultLocation({ ...DefaultLocation });
  //   setZoom(DefaultZoom);
  // }
  render() {
    return <div></div>;
  }
}

export default withLanguage(PickLocation);
