/** @jsx jsx */
import { useContext, useState } from "react";
import { jsx, css } from "@emotion/core";

/* data */
import { RealizationsContext } from "../../providers/RealizationsProvider";
import withLanguage from "../withLanguage.jsx";

/* styles */
import { Container, SectionHeader, Row } from "../common/layouts";
import { H2, H4, PARAGRAPH } from "../common/typography";
import { setMargin } from "../common/spacing";

import RealizationCard from "./RealizationCard";

/* language */
import { common, descriptions } from "../../language/content.js";

/* elements */
import InfoWindow from "../elements/InfoWindow";

import BannerRealizations from "../banners/BannerRealizations.jsx";

const Realizations = ({ language }) => {
  const [isOpen, toggleOverlay] = useState(false);
  const realizations = useContext(RealizationsContext);

  return (
    <section>
      <SectionHeader>
        <H2>{common.realizations[language]}</H2>
        <PARAGRAPH
          css={css`
            max-width: 800px;
            ${setMargin(["top", "bottom"], "md")}
          `}
          dangerouslySetInnerHTML={{
            __html: descriptions.realizations[language],
          }}
        />
      </SectionHeader>
      <Container>
        {realizations &&
          realizations.map((realization, index) => (
            <RealizationCard
              key={`${realization.id}`}
              postType="realizations"
              postId={realization.id}
              {...realization}
            />
          ))}
      </Container>
      <BannerRealizations />
      <InfoWindow
        color="realizations"
        isOpen={isOpen}
        onCloseClick={() => {
          toggleOverlay(false);
        }}
      >
        <H4>
          Има място около теб, което искаш да се промени? Качи номинация,
          сподели с приятелите си и гласувайте мястото да стане Урбид.<br></br>
          Всеки месец ще избираме от най-подкрепяните Номинации една, за която
          ще отворим конкурс.
        </H4>
      </InfoWindow>
    </section>
  );
};

export default withLanguage(Realizations);
